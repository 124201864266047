import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import {
  updateProject,
  setActiveFileTab,
  fetchArtifactData,
} from "../project.js";

export const handleDiffChanges = createAsyncThunk(
  "projects/handleDiffChanges",
  async ({ projectId, action, fileIds = [] }, { getState, dispatch }) => {
    const token = getState().user.access_token;
    const currentProject = getState().project.currentProject;
    const activeFileId = getState().project.activeFileId;
    const openFiles = getState().project.openFiles;

    // Save the current s3_url of active file for comparison later
    const currentS3Url = currentProject.files[activeFileId]?.s3_url;

    // Save the chat-related fields and artifact before making the API call
    const chatFields = {
      agent_chat_files: currentProject.agent_chat_files,
      agent_chat_next_token: currentProject.agent_chat_next_token,
      chat_chat_files: currentProject.chat_chat_files,
      chat_chat_next_token: currentProject.chat_chat_next_token,
      popup_chat_files: currentProject.popup_chat_files,
    };

    // Get list of open file IDs
    const openFileIds = Object.keys(openFiles);

    // Make the API call
    const response = await handleDiffChangesAPI(
      projectId,
      action,
      fileIds,
      token,
      openFileIds
    );

    // Merge the saved chat fields with the response
    const updatedResponse = {
      ...response,
      ...chatFields,
    };

    // Update project with the response
    dispatch(updateProject(updatedResponse));

    // Handle active file changes
    if (activeFileId) {
      // Check if active file was removed from project
      if (!response.files[activeFileId]) {
        // Find next file to activate using FileBar.js logic
        const sortedFiles = Object.entries(openFiles)
          .sort(([, a], [, b]) => a.order - b.order)
          .map(([id]) => id);

        const currentIndex = sortedFiles.indexOf(activeFileId);
        // Try to get the file to the left, if not available get the first file
        const nextFileId =
          currentIndex > 0
            ? sortedFiles[currentIndex - 1]
            : sortedFiles.length > 1
            ? sortedFiles[currentIndex + 1]
            : null;

        if (nextFileId && response.files[nextFileId]) {
          dispatch(setActiveFileTab(nextFileId));
          console.log("fetching artifact data for fileId in handleDiffChanges nextFileId:", nextFileId);
          dispatch(fetchArtifactData({ fileId: nextFileId }));
        } else {
          dispatch(setActiveFileTab(null));
        }
      } else {
        // Check if s3_url changed for active file
        const newS3Url = response.files[activeFileId]?.s3_url;
        if (currentS3Url !== newS3Url) {
          console.log("fetching artifact data for fileId in handleDiffChanges s3_url changed:", activeFileId);
          dispatch(fetchArtifactData({ fileId: activeFileId }));
        }
      }
    }

    return response;
  }
);

export const handleDiffChangesReducer = {
  [handleDiffChanges.fulfilled]: (state, action) => {
    state.editorNeedsRemount = true;
  },
};

export const handleDiffChangesAPI = async (
  projectId,
  action,
  fileIds,
  token,
  openFileIds
) => {
  try {
    console.log("handleDiffChanges called with:", {
      projectId,
      action,
      fileIds,
      openFileIds,
    });
    const response = await fetchWithToken(
      `${API_URL}/api/diff-file-changes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          project_id: projectId,
          action: action, // 'accept_all' or 'reject_all' or 'accept' or 'reject'
          file_ids: fileIds,
          open_file_ids: openFileIds,
        }),
      },
      token
    );

    if (!response.ok) {
      console.error("Failed to handle diff changes. Status:", response.status);
      throw new Error("Failed to handle diff changes");
    }

    const data = await response.json();
    console.log("Updated Project:", data);
    return data.status === "success" ? data.data : Promise.reject(data.message);
  } catch (error) {
    console.error("Error in handleDiffChanges:", error.message);
    throw error;
  }
};
