import React, { useState, useRef, useEffect } from "react";
import { FILE_TYPE_ICONS } from "../store/project.js";

function AddFileTooltip({
  isVisible,
  onClose,
  addedFiles = [],
  allFiles = [],
  anchorRef, // ref to the "+" button or another anchor
  onRemoveFile, // callback to remove from "Added Files"
  onFileClick, // callback to add to "Added Files"
}) {
  const tooltipRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");

  // Filter files, excluding Output .md and .pickle files
  const filteredFiles = allFiles.filter(
    (file) =>
      !(
        file.file_type === "Output" &&
        (file.file_name.endsWith(".md") || file.file_name.endsWith(".csv"))
      ) && !file.file_name.endsWith(".pickle")
  );

  // Filter top 10 available files
  const available = filteredFiles
    .filter((f) => f.file_name.toLowerCase().includes(searchTerm.toLowerCase()))
    .slice(0, 10);

  // Position tooltip above the anchor
  useEffect(() => {
    if (isVisible && anchorRef.current && tooltipRef.current) {
      const anchorRect = anchorRef.current.getBoundingClientRect();
      const tooltipEl = tooltipRef.current;
      const tooltipHeight = tooltipEl.offsetHeight;
      const viewportHeight = window.innerHeight;
      const spaceAbove = anchorRect.top;
      const spaceBelow = viewportHeight - anchorRect.bottom;

      // Position fixed relative to viewport
      tooltipEl.style.position = "fixed";
      tooltipEl.style.left = `${anchorRect.left}px`;

      // If there's more space below than above, or if there's not enough space above
      if (spaceBelow > spaceAbove || spaceAbove < tooltipHeight + 8) {
        // Position below
        tooltipEl.style.top = `${anchorRect.bottom + 8}px`;
      } else {
        // Position above
        tooltipEl.style.top = `${anchorRect.top - tooltipHeight - 8}px`;
      }
    }
  }, [isVisible]);

  // Add click outside handler
  useEffect(() => {
    function handleClickOutside(event) {
      // Only close if clicking outside both the tooltip and the anchor button
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) &&
        !anchorRef.current.contains(event.target)
      ) {
        onClose();
      }
    }

    // Only add the handler if the tooltip is visible
    if (isVisible) {
      // Use mousedown instead of click to handle the event before other click handlers
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  const handleFileClick = (file) => {
    onFileClick(file);
    // Don't close the tooltip
  };

  return (
    <div className="addfile-tooltip" ref={tooltipRef}>
      <div className="addfile-header">
        <input
          type="text"
          placeholder="Search files..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="addfile-search"
          autoFocus
        />
        <button className="addfile-close-btn" onClick={onClose}>
          ×
        </button>
      </div>

      <div className="addfile-section">
        <div className="addfile-section-title">Added Files</div>
        {addedFiles.length === 0 && (
          <div className="addfile-empty">No files added yet.</div>
        )}
        {addedFiles.map((file) => (
          <div key={file.id} className="addfile-row">
            <div className="addfile-icon-wrapper">
              {FILE_TYPE_ICONS[file.file_type] || FILE_TYPE_ICONS.default}
            </div>
            <span className="addfile-name">
              {file.file_name || file.label}
              <span className="addfile-name-tooltip">
                {file.file_name || file.label}
              </span>
            </span>
            <button
              className="addfile-remove"
              onClick={() => onRemoveFile(file.id)}
            >
              Remove
            </button>
          </div>
        ))}
      </div>

      <div className="addfile-section">
        <div className="addfile-section-title">Available</div>
        {available.map((file) => (
          <div
            key={file.id}
            className="addfile-row"
            onClick={() => handleFileClick(file)}
          >
            <div className="addfile-icon-wrapper">
              {FILE_TYPE_ICONS[file.file_type] || FILE_TYPE_ICONS.default}
            </div>
            <span className="addfile-name">
              {file.file_name}
              <span className="addfile-name-tooltip">{file.file_name}</span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AddFileTooltip;
