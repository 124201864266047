import React, { useState, useCallback, useEffect, useRef } from "react";
import "../style/ArtifactPanel.css";
import { useSelector, useDispatch } from "react-redux";
import {
  generateProjectInstructions,
  clearGenerateInstructionsError,
  updateArtifact,
  navigateDesign,
  setArtifactType,
  updateModal,
  clearPopUpMessages,
  addPopUpUserMessage,
  finishPopUpMessages,
  setPopupChatOpen,
} from "../store/project.js";
import { populateSampleData } from "../store/user.js";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Design from "./Design.js";
import Sequence from "./Sequence.js";
import EditableNotebook from "./EditableNotebook.js";
import PopUpChat from "./PopUpChat.js";
import PaperViewer from "./PaperViewer.js";
import { pdfjs } from "react-pdf";
import { withEditStateCheck } from "../utils/editStateWrapper.js";
import SingleFileChangePopUp from "./SingleFileChangePopUp.js";
import PythonEditor from "./PythonEditor.js";
import CSVViewer from "./CSVViewer.js";
import { useAuth0 } from "@auth0/auth0-react";
// Initialize PDF.js worker at the application level
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function ArtifactPanel() {
  const dispatch = useDispatch();
  const { loginWithRedirect, isAuthenticated: isAuth0Authenticated } =
    useAuth0();
  const artifact = useSelector((state) => state.project.artifact);
  const projectId = useSelector((state) => state.project.currentProjectId);
  const isSidebarVisible = useSelector(
    (state) => state.project.isSidebarVisible
  );
  const isArtifactLoading = useSelector(
    (state) => state.project.isArtifactLoading
  );
  const projectList = useSelector((state) => state.project.projectList);
  const userId = useSelector((state) => state.user.user_id);
  const activeFileId = useSelector((state) => state.project.activeFileId);
  const openFiles = useSelector((state) => state.project.openFiles);
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userFiles = useSelector((state) => state.user.files);

  const [messagesWidth, setMessagesWidth] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [popupChatOpen, setPopupChatOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const panelRef = useRef(null);

  const currentProject = projectList?.find((p) => p.project_id === projectId);

  const isDesignStatus = currentProject?.status === "design";

  useEffect(() => {
    let timeoutId;
    if (isArtifactLoading) {
      timeoutId = setTimeout(() => {
        setShowLoading(true);
      }, 300);
    } else {
      setShowLoading(false);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isArtifactLoading]);

  useEffect(() => {
    const chatContainer = document.querySelector(".chat-messages-container");
    if (chatContainer) {
      setMessagesWidth(chatContainer.offsetWidth);
    }
  }, []);

  const handleConfirmGenerateInstructions = () => {
    setIsPopupOpen(false);
    dispatch(clearGenerateInstructionsError());
    dispatch(generateProjectInstructions({ projectId, override: "false" }));
  };

  const handleCancelGenerateInstructions = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (artifact) {
      const artifactType = artifact.type;
      if (artifactType === "html") {
        dispatch(setArtifactType("html"));
      } else if (artifactType === "design" || artifact.isDesign) {
        dispatch(setArtifactType("design"));
      } else if (artifactType === "markdown" || artifactType === "csv") {
        dispatch(setArtifactType("notebook"));
      } else if (artifactType === "sequence") {
        dispatch(setArtifactType("sequence"));
      } else {
        dispatch(setArtifactType("sequence"));
      }
    }
  }, [artifact, dispatch]);

  let content = null;
  const activeFile = activeFileId ? openFiles[activeFileId] : null;
  const activeArtifact = activeFile?.content || artifact;

  if (activeArtifact && activeFile) {
    const artifactType = activeArtifact.type;

    if (artifactType === "html") {
      content = (
        <div
          className="html-content"
          dangerouslySetInnerHTML={{ __html: activeArtifact.content }}
        />
      );
    } else if (artifactType === "design" || activeArtifact.isDesign) {
      content = <Design />;
    } else if (artifactType === "notebook") {
      content = <EditableNotebook fileId={activeArtifact.file_id} />;
    } else if (artifactType === "markdown" || artifactType === "csv") {
      if (activeArtifact.protocol_id === "summary") {
        content = <EditableNotebook fileId={activeArtifact.file_id} />;
      } else if (artifactType === "data") {
        content = <CSVViewer />;
      }
    } else if (artifactType === "sequence") {
      content = <Sequence />;
    } else if (artifactType === "python") {
      content = <PythonEditor />;
    } else if (artifactType === "data") {
      content = <CSVViewer />;
    } else if (artifactType === "pdf") {
      content = <PaperViewer />;
    } else {
      content = <Design />;
    }
  } else {
    content = (
      <div
        className="artifact-empty-state"
        role="region"
        aria-label="Welcome section"
      >
        <div className="artifact-empty-content">
          <img
            src="/images/K-mark.svg"
            width={135}
            height={135}
            alt="LabKick Logo"
          />
          <h1>LabKick</h1>
          <h3>
            {isAuthenticated || isAuth0Authenticated
              ? projectList && projectList.length > 0
                ? currentProject?.files &&
                  Object.keys(currentProject.files).length > 0
                  ? "Welcome! Select a file from the sidebar to view its contents, or send a chat to get started."
                  : "Welcome! Add a file to your project or send a chat to get started."
                : "Create your first project to get started."
              : "Welcome! Sign up to get started with LabKick."}
          </h3>
          <div className="artifact-buttons-row">
            {isAuthenticated || isAuth0Authenticated ? (
              projectList && projectList.length > 0 ? (
                <>
                  <button
                    className="large-button"
                    onClick={() =>
                      dispatch(
                        updateModal({
                          name: "dna_archive_project",
                          data: { projectId: currentProjectId },
                        })
                      )
                    }
                    aria-label="Open File"
                  >
                    <img
                      src="/images/dna-archive-fix.svg"
                      alt=""
                      className="button-icon"
                      aria-hidden="true"
                    />
                    Add File To Project
                  </button>
                </>
              ) : (
                <button
                  className="large-button"
                  onClick={() =>
                    dispatch(
                      updateModal({ name: "create_project", data: null })
                    )
                  }
                  aria-label="Create new project"
                >
                  <img
                    src="/images/new_project.svg"
                    alt=""
                    className="button-icon"
                    aria-hidden="true"
                  />
                  New Project
                </button>
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  // Only show loading overlay if we have an active file
  const showLoadingOverlay = isArtifactLoading && showLoading && activeFileId;

  // Track mouse position
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (panelRef.current) {
        const rect = panelRef.current.getBoundingClientRect();
        setMousePosition({
          x: e.clientX - rect.left,
          y: e.clientY - rect.top,
        });
      }
    };

    const panel = panelRef.current;
    if (panel) {
      panel.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (panel) {
        panel.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);

  // Handle Command+K / Ctrl+K
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check for Command+K (Mac) or Ctrl+K (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === "k") {
        e.preventDefault(); // Prevent default browser behavior
        e.stopPropagation(); // Stop event from reaching MDXEditor

        // Wrap the popup opening with edit state check
        dispatch(
          withEditStateCheck(() => {
            setPopupChatOpen(true);
            // Position the popup below and to the right of cursor
            if (panelRef.current) {
              const popupWidth = 400; // Width from PopUpChat.css
              const popupHeight = 100; // Initial height before messages
              const offset = 20; // Offset from cursor

              // Calculate position with offset, ensuring popup stays within viewport
              const x = Math.min(
                mousePosition.x + offset,
                window.innerWidth - popupWidth - offset
              );
              const y = Math.min(
                mousePosition.y + offset,
                window.innerHeight - popupHeight - offset
              );

              setPopupPosition({ x, y });
            }
          }, projectId)
        );
      }
    };

    // Change to capture phase to ensure we handle the event before MDXEditor
    window.addEventListener("keydown", handleKeyDown, true);
    return () => window.removeEventListener("keydown", handleKeyDown, true);
  }, [mousePosition, dispatch]);

  // Add new useEffect to handle popup chat files
  useEffect(() => {
    if (
      currentProject?.popup_chat_files &&
      Object.keys(currentProject.popup_chat_files).length > 0
    ) {
      console.log(
        "currentProject.popup_chat_files",
        currentProject.popup_chat_files
      );
      // Get the most recent popup chat file
      const popupChats = Object.values(currentProject.popup_chat_files).sort(
        (a, b) => new Date(b.last_opened_date) - new Date(a.last_opened_date)
      );

      const latestChat = popupChats[0];

      if (latestChat?.chat_contents?.length) {
        // Open the popup
        setPopupChatOpen(true);

        // Position popup in center of panel
        if (panelRef.current) {
          const rect = panelRef.current.getBoundingClientRect();
          const x = rect.width / 2 - 200; // 200 is half popup width
          const y = rect.height / 2 - 250; // 250 is half popup height
          setPopupPosition({ x, y });
        }

        // Clear existing messages and load chat contents
        dispatch(clearPopUpMessages());
        latestChat.chat_contents.forEach((msg) => {
          if (msg.role === "user") {
            dispatch(addPopUpUserMessage(msg.content));
          } else if (msg.role === "assistant") {
            dispatch(finishPopUpMessages(msg.content));
          }
        });
      }
    } else {
      setPopupChatOpen(false);
      console.log("No popup chat files found");
    }
  }, [projectId, currentProject]);

  return (
    <div
      ref={panelRef}
      className="artifact-panel"
      role="complementary"
      aria-label="Artifact viewer panel"
    >
      {showLoadingOverlay && (
        <div
          className={`artifact-loading-overlay ${showLoading ? "visible" : ""}`}
          aria-label="Loading artifact"
        >
          <div className="artifact-loading-spinner" role="progressbar" />
        </div>
      )}

      <PopUpChat
        isOpen={popupChatOpen}
        onClose={() => setPopupChatOpen(false)}
        position={popupPosition}
      />

      <div
        className="artifact-content"
        role="region"
        aria-label="Artifact content"
      >
        {activeFileId ? (
          content
        ) : (
          <div
            className="artifact-empty-state"
            role="region"
            aria-label="Welcome section"
          >
            <div className="artifact-empty-content">
              <img
                src="/images/K-mark.svg"
                width={135}
                height={135}
                alt="LabKick Logo"
              />
              <h1>LabKick</h1>
              <h3>
                {isAuthenticated || isAuth0Authenticated
                  ? projectList && projectList.length > 0
                    ? currentProject?.files &&
                      Object.keys(currentProject.files).length > 0
                      ? "Welcome! Select a file from the sidebar to view its contents, or send a chat to get started."
                      : "Welcome! Add a file to your project or send a chat to get started."
                    : "Create your first project to get started."
                  : "Welcome! Sign up or log in to get started with LabKick."}
              </h3>
              <div className="artifact-buttons-row">
                {isAuthenticated || isAuth0Authenticated ? (
                  projectList && projectList.length > 0 ? (
                    <>
                      <button
                        className="large-button"
                        onClick={() =>
                          dispatch(
                            updateModal({
                              name: "dna_archive_project",
                              data: { projectId: currentProjectId },
                            })
                          )
                        }
                        aria-label="Open File"
                      >
                        <img
                          src="/images/dna-archive-fix.svg"
                          alt=""
                          className="button-icon"
                          aria-hidden="true"
                        />
                        Add File To Project
                      </button>
                    </>
                  ) : (
                    <button
                      className="large-button"
                      onClick={() =>
                        dispatch(
                          updateModal({ name: "create_project", data: null })
                        )
                      }
                      aria-label="Create new project"
                    >
                      <img
                        src="/images/new_project.svg"
                        alt=""
                        className="button-icon"
                        aria-hidden="true"
                      />
                      New Project
                    </button>
                  )
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>

      <SingleFileChangePopUp />
    </div>
  );
}

export default ArtifactPanel;
