import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  uploadFile,
  updateModal,
  setIsLoadingFiles,
  setLoadingMessage,
} from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import "../style/FileUploadModal.css";
import { setPopupMessage } from "../store/user.js";
import { checkFilenameUnique } from "../api/files_api.js";

const SEQUENCE_TYPES = {
  DNA: "dna",
  RNA: "rna",
  PROTEIN: "protein",
};

function FileUploadModal() {
  const [sequenceFiles, setSequenceFiles] = useState([]);
  const [docFiles, setDocFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const { modal, currentProjectId } = useSelector((state) => state.project);
  const user = useSelector((state) => state.user.data);
  const chat = useSelector((state) => state.project.chat)[currentProjectId];
  const chatId = chat?.chat_id;
  const [annotateOption, setAnnotateOption] = useState("annotate");
  const [uploadType, setUploadType] = useState("file");
  const [sequenceName, setSequenceName] = useState("");
  const [sequence, setSequence] = useState("");
  const [fileSpecs, setFileSpecs] = useState({});
  const [sequenceError, setSequenceError] = useState("");
  const [sequenceNameError, setSequenceNameError] = useState("");
  const [sequenceTopology, setSequenceTopology] = useState("linear");
  const token = useSelector((state) => state.user.access_token);
  const [structureOption, setStructureOption] = useState("structure");
  const [dataFiles, setDataFiles] = useState([]);
  const [sequenceType, setSequenceType] = useState(SEQUENCE_TYPES.DNA);

  const maxNameLength = 50;
  const validNameRegex = /^[a-zA-Z0-9_:\-\.#$]+$/;
  const maxSize = 100 * 1024 * 1024; // 20MB in bytes
  const maxSequenceLength = 20e6; // 20 million bases

  const validateFileName = (fileName) => {
    if (fileName.length > maxNameLength) {
      return "File name must be 30 characters or less.";
    }
    if (fileName.split(".").length > 2) {
      return "File name must contain one or less period (.)";
    }
    if (!validNameRegex.test(fileName)) {
      return "File name contains invalid characters. Only letters, numbers, and _:-#$ are allowed. Spaces are not permitted.";
    }
    return null;
  };

  const onDropSequences = useCallback(async (acceptedFiles) => {
    const MAX_FILES_PER_UPLOAD = 20;

    if (acceptedFiles.length > MAX_FILES_PER_UPLOAD) {
      setErrorMessage(
        `You can only upload up to ${MAX_FILES_PER_UPLOAD} files at a time.`
      );
      return;
    }

    const processedFiles = await Promise.all(
      acceptedFiles.map(async (file) => {
        const extension = "." + file.name.split(".").pop().toLowerCase();
        const isGenBankOrEmbl = [".gb", ".gbk", ".genbank", ".embl"].includes(
          extension
        );

        if (isGenBankOrEmbl) {
          const topology = await getTopologyFromFile(file);
          setFileSpecs((prev) => ({
            ...prev,
            [file.name]: {
              ...prev[file.name],
              topology,
            },
          }));
        }
        return file;
      })
    );

    setSequenceFiles((prevFiles) => [...prevFiles, ...processedFiles]);
  }, []);

  const onDropDocs = useCallback((acceptedFiles) => {
    const MAX_FILES_PER_UPLOAD = 20;

    if (acceptedFiles.length > MAX_FILES_PER_UPLOAD) {
      setErrorMessage(
        `You can only upload up to ${MAX_FILES_PER_UPLOAD} files at a time.`
      );
      return;
    }

    setDocFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const onDropData = useCallback((acceptedFiles) => {
    const MAX_FILES_PER_UPLOAD = 20;

    if (acceptedFiles.length > MAX_FILES_PER_UPLOAD) {
      setErrorMessage(
        `You can only upload up to ${MAX_FILES_PER_UPLOAD} files at a time.`
      );
      return;
    }

    setDataFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const {
    getRootProps: getSequenceRootProps,
    getInputProps: getSequenceInputProps,
    isDragActive: isSequenceDragActive,
  } = useDropzone({ onDrop: onDropSequences });
  const {
    getRootProps: getDocRootProps,
    getInputProps: getDocInputProps,
    isDragActive: isDocDragActive,
  } = useDropzone({ onDrop: onDropDocs });
  const {
    getRootProps: getDataRootProps,
    getInputProps: getDataInputProps,
    isDragActive: isDataDragActive,
  } = useDropzone({
    onDrop: onDropData,
    accept: {
      "text/csv": [".csv"],
    },
  });

  const handleUploadTypeChange = (type) => {
    setUploadType(type);
    setErrorMessage("");
  };

  const formatGenbankContent = (sequenceName, cleanedSequence, type) => {
    const today = new Date()
      .toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .toUpperCase();

    // Get molecule type based on sequence type
    const moleculeType =
      type === SEQUENCE_TYPES.RNA
        ? "RNA"
        : type === SEQUENCE_TYPES.PROTEIN
        ? "PROTEIN"
        : "DNA";

    // Format sequence with numbers
    let formattedSeq = "";
    for (let i = 0; i < cleanedSequence.length; i += 60) {
      const line = cleanedSequence.slice(i, i + 60);
      const lineNum = (i + 1).toString().padStart(9);

      // Split line into groups of 10
      let formattedLine = "";
      for (let j = 0; j < line.length; j += 10) {
        formattedLine += " " + line.slice(j, j + 10);
      }

      formattedSeq += `${lineNum}${formattedLine}\n`;
    }

    return `LOCUS       ${sequenceName.split(".")[0].padEnd(23)} ${
      cleanedSequence.length
    } bp    ${moleculeType}     ${
      type === SEQUENCE_TYPES.DNA ? sequenceTopology : "linear"
    } SYN ${today}
DEFINITION  ${sequenceName.split(".")[0]}.
ACCESSION   .
VERSION     .
KEYWORDS    .
SOURCE      .
  ORGANISM  .
FEATURES             Location/Qualifiers
ORIGIN
${formattedSeq}//`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let filesToUpload = [];
    let isValid = true;
    let errorMessages = [];
    const MAX_FILES_PER_UPLOAD = 20;

    // Validate based on upload type
    if (uploadType === "file" || uploadType === "docs") {
      const files = uploadType === "file" ? sequenceFiles : docFiles;

      // Check file count
      if (files.length > MAX_FILES_PER_UPLOAD) {
        isValid = false;
        errorMessages.push(
          `You can only upload up to ${MAX_FILES_PER_UPLOAD} files at a time.`
        );
      }

      // Check for duplicate filenames
      const duplicateFiles = [];
      for (const file of files) {
        const isUnique = await checkFilenameUnique(
          user.user_id,
          file.name,
          token
        );
        if (!isUnique) {
          duplicateFiles.push(file);
        }
      }

      if (duplicateFiles.length > 0) {
        isValid = false;
        errorMessages.push(
          `Duplicate file names detected: ${duplicateFiles
            .map((f) => f.name)
            .join(", ")}. Please rename these files.`
        );
      }

      // Validate file names
      const invalidNames = files
        .map((file) => {
          const error = validateFileName(file.name);
          return error ? `${file.name}: ${error}` : null;
        })
        .filter(Boolean);

      if (invalidNames.length > 0) {
        isValid = false;
        errorMessages.push(
          `Invalid file names detected:\n${invalidNames.join("\n")}`
        );
      }

      // Check file extensions
      const validExtensions =
        uploadType === "file" ? DNA_FILE_EXTENSIONS : DOCS_FILE_EXTENSIONS;
      const invalidFiles = files.filter((file) => {
        const extension = "." + file.name.split(".").pop().toLowerCase();
        return !validExtensions.includes(extension);
      });

      if (invalidFiles.length > 0) {
        isValid = false;
        errorMessages.push(
          `Invalid file types detected: ${invalidFiles
            .map((f) => f.name)
            .join(", ")}. 
          Please upload files with the following extensions: ${validExtensions.join(
            ", "
          )}`
        );
      }

      // Check file sizes
      const totalSize = files.reduce((acc, file) => acc + file.size, 0);
      if (totalSize > maxSize) {
        isValid = false;
        errorMessages.push("Total file size exceeds 20MB limit");
      }

      filesToUpload = files.map((file) => ({
        file,
        topology: fileSpecs[file.name]?.topology || "linear",
        parentFileId: null,
      }));
    } else if (uploadType === "sequence") {
      // Validate sequence input
      if (!sequenceName || !sequence) {
        isValid = false;
        errorMessages.push("Please provide both a sequence name and sequence.");
      }

      const fileNameError = validateFileName(sequenceName);
      if (fileNameError) {
        isValid = false;
        errorMessages.push(fileNameError);
      }

      if (isValid) {
        // Clean sequence based on type
        let cleanedSequence;
        if (sequenceType === SEQUENCE_TYPES.DNA) {
          cleanedSequence = sequence.replace(/[^atcgATCG]/g, "").toUpperCase();
        } else if (sequenceType === SEQUENCE_TYPES.RNA) {
          cleanedSequence = sequence.replace(/[^aucgAUCG]/g, "").toUpperCase();
        } else {
          cleanedSequence = sequence
            .replace(/[^ACDEFGHIKLMNPQRSTVWY*]/gi, "")
            .toUpperCase();
        }

        if (cleanedSequence.length === 0) {
          isValid = false;
          errorMessages.push(
            `The sequence must contain valid ${sequenceType} bases/residues.`
          );
        } else if (cleanedSequence.length > maxSequenceLength) {
          isValid = false;
          errorMessages.push(
            "Sequence is too long. Max allowed is 5 million bases."
          );
        } else {
          const fileName = `${sequenceName.split(".")[0]}.gb`;
          const isUnique = await checkFilenameUnique(
            user.user_id,
            fileName,
            token
          );

          if (!isUnique) {
            isValid = false;
            errorMessages.push(
              `A file with the name "${fileName}" already exists. Please choose a different name.`
            );
          }

          if (isValid) {
            const genbankContent = formatGenbankContent(
              sequenceName,
              cleanedSequence,
              sequenceType
            );

            const genbankFile = new File([genbankContent], fileName, {
              type: "text/plain",
            });

            filesToUpload = [
              {
                file: genbankFile,
                topology:
                  sequenceType === SEQUENCE_TYPES.DNA
                    ? sequenceTopology
                    : "linear",
                sequenceType: sequenceType,
                parentFileId: null,
              },
            ];
          }
        }
      }
    } else if (uploadType === "data") {
      // Check for duplicate filenames for data
      const duplicateFiles = [];
      for (const fileData of dataFiles) {
        const isUnique = await checkFilenameUnique(
          user.user_id,
          fileData.name,
          token
        );
        if (!isUnique) {
          duplicateFiles.push(fileData);
        }
      }

      if (duplicateFiles.length > 0) {
        setErrorMessage(
          `Duplicate file names detected: ${duplicateFiles
            .map((f) => f.name)
            .join(", ")}. Please rename these files.`
        );
        return;
      }

      const totalSize = dataFiles.reduce(
        (acc, fileData) => acc + fileData.size,
        0
      );

      if (totalSize > maxSize) {
        setErrorMessage(
          "File upload size too big. Maximum total allowed size is 5MB."
        );
        return;
      }

      const invalidFiles = dataFiles.filter((fileData) => {
        const extension = "." + fileData.name.split(".").pop().toLowerCase();
        return !DATA_FILE_EXTENSIONS.includes(extension);
      });

      if (invalidFiles.length > 0) {
        setErrorMessage(`Invalid file types detected: ${invalidFiles
          .map((f) => f.name)
          .join(", ")}. 
                    Please upload files with the following extensions: ${DATA_FILE_EXTENSIONS.join(
                      ", "
                    )}`);
        return;
      }

      filesToUpload = dataFiles.map((fileData) => ({
        file: fileData,
        topology: "linear",
        parentFileId: null,
      }));
    }

    if (!isValid) {
      setErrorMessage(errorMessages.join("\n"));
      return;
    }

    setErrorMessage(""); // Add this line to clear error message if no issues

    try {
      const projectIdToUse =
        modal.name === "upload_general" ? "no_project" : currentProjectId;
      dispatch(setIsLoadingFiles(true));

      // Add loading message for each file
      for (const fileData of filesToUpload) {
        dispatch(setLoadingMessage(`Uploading ${fileData.file.name}`));

        const response = await dispatch(
          uploadFile({
            projectId: projectIdToUse,
            files: [fileData], // Upload one at a time to show progress
            chatId,
            userId: user.user_id,
            annotate:
              uploadType === "docs"
                ? structureOption === "structure"
                  ? "annotate"
                  : "no_annotate"
                : annotateOption,
            uploadType,
          })
        ).unwrap();

        if (response.error && response.error.length > 0) {
          dispatch(setPopupMessage(response.error));
        }
      }

      dispatch(
        updateModal({ name: modal.name.replace("upload", "dna_archive") })
      );
    } catch (error) {
      console.error("Failed to upload files:", error);
      setErrorMessage("Failed to upload files. Please try again.");
    } finally {
      dispatch(setIsLoadingFiles(false));
      dispatch(setLoadingMessage("")); // Clear loading message
    }
  };

  const DNA_FILE_EXTENSIONS = [
    ".fa",
    ".fasta",
    ".fna",
    ".gb",
    ".gbk",
    ".genbank",
    ".embl",
    ".fastq",
    ".fq",
    ".ig",
    ".txt",
    ".pep",
    ".aa",
    ".rna",
  ];

  const DOCS_FILE_EXTENSIONS = [".pdf"];

  const DATA_FILE_EXTENSIONS = [".csv"];

  const removeSequenceFile = (fileToRemove) => {
    const newFiles = sequenceFiles.filter((file) => file !== fileToRemove);
    setSequenceFiles(newFiles);
    if (newFiles.length === 0) {
      setErrorMessage("");
    }
  };

  const removeDocFile = (fileToRemove) => {
    const newFiles = docFiles.filter((file) => file !== fileToRemove);
    setDocFiles(newFiles);
    if (newFiles.length === 0) {
      setErrorMessage("");
    }
  };

  const removeDataFile = (fileToRemove) => {
    const newFiles = dataFiles.filter((file) => file !== fileToRemove);
    setDataFiles(newFiles);
    if (newFiles.length === 0) {
      setErrorMessage("");
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return `${bytes} bytes`;
    } else if (bytes < 1024 * 1024) {
      return `${Math.floor(bytes / 1024)} KB`;
    } else {
      return `${Math.floor(bytes / (1024 * 1024))} MB`;
    }
  };

  const handleFileSpecChange = (file, spec, value) => {
    setFileSpecs((prev) => ({
      ...prev,
      [file.name]: {
        ...prev[file.name],
        [spec]: value,
      },
    }));
  };

  const renderFileSpecs = (file) => {
    const extension = "." + file.name.split(".").pop().toLowerCase();
    const isGenBankOrEmbl = [".gb", ".gbk", ".genbank", ".embl"].includes(
      extension
    );
    const selectValue = fileSpecs[file.name]?.topology || "linear";

    return {
      topologyDropdown: (
        <select
          className="topology-select"
          value={selectValue}
          onChange={(e) =>
            handleFileSpecChange(file, "topology", e.target.value)
          }
          disabled={isGenBankOrEmbl}
        >
          <option value="linear">Linear</option>
          <option value="circular">Circular</option>
        </select>
      ),
    };
  };

  const handleSequenceNameChange = (e) => {
    // Allow spaces during typing, validation will catch them later
    const value = e.target.value;
    setSequenceName(value);

    // Validate the name as the user types
    if (value && !validNameRegex.test(value)) {
      setSequenceNameError(
        "Invalid characters detected. Only letters, numbers, and _:-#$ are allowed. Spaces are not permitted."
      );
    } else {
      setSequenceNameError("");
    }
  };

  const getTopologyFromFile = async (file) => {
    try {
      const text = await file.text();
      console.log("File content first line:", text.split("\n")[0]); // Debug log

      // More precise LOCUS line parsing
      const locusMatch = text.match(
        /LOCUS\s+\S+\s+\d+\s+bp\s+DNA\s+(linear|circular)/i
      );
      console.log("Locus match:", locusMatch); // Debug log

      if (locusMatch) {
        console.log("Found topology:", locusMatch[1]); // Debug log
        return locusMatch[1].toLowerCase();
      }

      // Fallback patterns
      const topologyMatch =
        text.match(/\btopology="?(circular|linear)"?/i) ||
        text.match(/\bTOPOLOGY:\s*(circular|linear)/i);

      if (topologyMatch) {
        return topologyMatch[1].toLowerCase();
      }

      return "auto";
    } catch (error) {
      console.error("Error reading file:", error);
      return "auto";
    }
  };

  const handleSequenceTypeChange = (e) => {
    const newType = e.target.value;
    setSequenceType(newType);
    // Revalidate existing sequence with new type
    if (sequence) {
      setSequenceError(validateSequence(sequence, newType)); // Pass the new type directly
    }
  };

  const validateSequence = (seq, type = sequenceType) => {
    const dnaPattern = /^[atcgATCG]+$/;
    const rnaPattern = /^[aucgAUCG]+$/;
    const proteinPattern = /^[ACDEFGHIKLMNPQRSTVWY*]+$/i;

    const cleanedSeq = seq.replace(/\s+/g, "");

    switch (type) {
      case SEQUENCE_TYPES.DNA:
        return dnaPattern.test(cleanedSeq)
          ? ""
          : "Invalid DNA sequence. Please use only A, T, C, G bases.";
      case SEQUENCE_TYPES.RNA:
        return rnaPattern.test(cleanedSeq)
          ? ""
          : "Invalid RNA sequence. Please use only A, U, C, G bases.";
      case SEQUENCE_TYPES.PROTEIN:
        return proteinPattern.test(cleanedSeq)
          ? ""
          : "Invalid protein sequence. Please use standard amino acid letters.";
      default:
        return "Invalid sequence type";
    }
  };

  const handleSequenceChange = (e) => {
    const newSequence = e.target.value;

    // Check if the sequence starts with ">" pattern (FASTA format)
    const fastaMatch = newSequence.match(/^>(.+)\n([^>]+)/);
    if (fastaMatch) {
      const [_, header, sequenceContent] = fastaMatch;

      if (!sequenceName) {
        let cleanedHeader = header
          .trim()
          .replace(/[^a-zA-Z0-9_:\-\.#$\s]/g, "")
          .trim();

        // Don't remove spaces here, let validation handle it
        // cleanedHeader = cleanedHeader.replace(/\s+/g, "");

        if (cleanedHeader.length > maxNameLength) {
          cleanedHeader = cleanedHeader.substring(0, maxNameLength);
        }

        setSequenceName(cleanedHeader);
      }

      // Clean the sequence content but preserve case for proteins
      const cleanedSequence = sequenceContent.replace(/[^a-zA-Z*]/g, "");
      setSequence(cleanedSequence);
      setSequenceError(validateSequence(cleanedSequence));
    } else {
      // Remove whitespace and validate
      const strippedSequence = newSequence.replace(/\s+/g, "");
      setSequence(strippedSequence);
      setSequenceError(validateSequence(strippedSequence));
    }
  };

  const getDropzoneText = () => {
    if (isSequenceDragActive) {
      return <p>Drop the files here ...</p>;
    }
    return (
      <p>
        Drag and drop DNA, RNA, or protein sequence files here, or click to
        select files
      </p>
    );
  };

  const getAnnotationInfoText = () => (
    <div className="annotation-info" role="note">
      We support fastas, genbanks, and embl file formats for DNA sequences, as
      well as standard formats for RNA and protein sequences (.fa, .fasta, .pep,
      .aa, .rna). All files will be converted to appropriate formats in LabKick.
      <br />
      <br />
      We have a proprietary annotation pipeline for DNA sequences. RNA and
      protein sequences will be stored as-is. DNA sequence annotations typically
      take less than 1 minute per file. If you don't see them after upload,
      please wait a few minutes and refresh the page.
    </div>
  );

  const renderSequenceInputPanel = () => (
    <div
      id="sequence-upload-panel"
      className="sequence-input"
      role="tabpanel"
      aria-labelledby="sequence-upload-tab"
    >
      <div className="file-upload-radio-group sequence-type-group">
        <label>
          <input
            type="radio"
            value={SEQUENCE_TYPES.DNA}
            checked={sequenceType === SEQUENCE_TYPES.DNA}
            onChange={handleSequenceTypeChange}
            name="sequence-type"
          />
          DNA
        </label>
        <label>
          <input
            type="radio"
            value={SEQUENCE_TYPES.RNA}
            checked={sequenceType === SEQUENCE_TYPES.RNA}
            onChange={handleSequenceTypeChange}
            name="sequence-type"
          />
          RNA
        </label>
        <label>
          <input
            type="radio"
            value={SEQUENCE_TYPES.PROTEIN}
            checked={sequenceType === SEQUENCE_TYPES.PROTEIN}
            onChange={handleSequenceTypeChange}
            name="sequence-type"
          />
          Protein
        </label>
      </div>

      <input
        id="sequence-name"
        type="text"
        placeholder="Sequence Name"
        value={sequenceName}
        onChange={handleSequenceNameChange}
        aria-invalid={errorMessage && errorMessage.includes("name")}
        aria-required="true"
      />
      {sequenceNameError && (
        <div className="sequence-name-error" role="alert">
          {sequenceNameError}
        </div>
      )}
      <textarea
        id="sequence-text"
        placeholder={`Enter your ${sequenceType.toUpperCase()} sequence`}
        value={sequence}
        onChange={handleSequenceChange}
        aria-invalid={!!sequenceError}
        aria-required="true"
      />
      {sequenceError && (
        <div className="sequence-error" role="alert">
          {sequenceError}
        </div>
      )}

      {/* Only show topology and annotation options for DNA */}
      {sequenceType === SEQUENCE_TYPES.DNA && (
        <>
          <div
            className="file-upload-radio-group"
            role="radiogroup"
            aria-label="Topology options"
          >
            <label>
              <input
                type="radio"
                value="linear"
                checked={sequenceTopology === "linear"}
                onChange={(e) => setSequenceTopology(e.target.value)}
                name="sequence-topology"
              />
              Linear
            </label>
            <label>
              <input
                type="radio"
                value="circular"
                checked={sequenceTopology === "circular"}
                onChange={(e) => setSequenceTopology(e.target.value)}
                name="sequence-topology"
              />
              Circular
            </label>
          </div>

          <div
            className="file-upload-radio-group"
            role="radiogroup"
            aria-label="Annotation options"
          >
            <label>
              <input
                type="radio"
                value="annotate"
                checked={annotateOption === "annotate"}
                onChange={() => setAnnotateOption("annotate")}
                name="annotation-option"
              />
              Annotate sequence
            </label>
            <label>
              <input
                type="radio"
                value="no_annotate"
                checked={annotateOption === "no_annotate"}
                onChange={() => setAnnotateOption("no_annotate")}
                name="annotation-option"
              />
              Don't annotate
            </label>
          </div>
        </>
      )}

      <div className="annotation-info" role="note">
        {sequenceType === SEQUENCE_TYPES.DNA ? (
          <>
            Just paste in a DNA sequence here and we'll upload and convert to
            genbank.
            <br />
            <br />
            We have a proprietary annotation pipeline (similar to Plannotate),
            so your sequences never leave our servers. In general, annotations
            will take less than 1 minute per file, but if you don't see them
            after upload, please wait a few minutes and refresh the page.
          </>
        ) : (
          // For both RNA and Protein, just show a simple paste message
          `Paste your ${sequenceType.toLowerCase()} sequence here.`
        )}
      </div>
    </div>
  );

  if (modal.name !== "upload_general" && modal.name !== "upload_project") {
    return null;
  }

  return (
    <div
      className="file-upload-modal-overlay"
      role="dialog"
      aria-modal="true"
      aria-labelledby="upload-modal-title"
    >
      <div className="file-upload-modal-dialog">
        <h2 id="upload-modal-title" className="visually-hidden">
          Upload Files
        </h2>
        <div className="upload-type-toggle" role="tablist">
          <button
            className={`toggle-button ${uploadType === "file" ? "active" : ""}`}
            onClick={() => handleUploadTypeChange("file")}
            role="tab"
            aria-selected={uploadType === "file"}
            aria-controls="file-upload-panel"
          >
            Import Sequence(s)
          </button>
          <button
            className={`toggle-button ${
              uploadType === "sequence" ? "active" : ""
            }`}
            onClick={() => handleUploadTypeChange("sequence")}
            role="tab"
            aria-selected={uploadType === "sequence"}
            aria-controls="sequence-upload-panel"
          >
            Create Sequence
          </button>
          <button
            className={`toggle-button ${uploadType === "docs" ? "active" : ""}`}
            onClick={() => handleUploadTypeChange("docs")}
            role="tab"
            aria-selected={uploadType === "docs"}
            aria-controls="docs-upload-panel"
          >
            Upload Docs
          </button>
          <button
            className={`toggle-button ${uploadType === "data" ? "active" : ""}`}
            onClick={() => handleUploadTypeChange("data")}
            role="tab"
            aria-selected={uploadType === "data"}
            aria-controls="data-upload-panel"
          >
            Upload Data
          </button>
        </div>
        {errorMessage && (
          <div className="error-message" role="alert" aria-live="polite">
            <p>{errorMessage}</p>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          {uploadType === "file" ? (
            <>
              <div {...getSequenceRootProps()} className="dropzone">
                <input {...getSequenceInputProps()} />
                {getDropzoneText()}
              </div>
              {sequenceFiles.length > 0 && (
                <div className="file-list">
                  <h3>Selected Files:</h3>
                  <ul>
                    <li className="file-list-header">
                      <div>File Name</div>
                      <div>Topology</div>
                    </li>
                    {sequenceFiles.map((file, index) => (
                      <li key={index}>
                        <div>
                          {file.name} - {formatFileSize(file.size)}
                          <button
                            type="button"
                            onClick={() => removeSequenceFile(file)}
                          >
                            Remove
                          </button>
                        </div>
                        <div>{renderFileSpecs(file).topologyDropdown}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div
                className="file-upload-radio-group"
                role="radiogroup"
                aria-label="Annotation options"
              >
                <label>
                  <input
                    type="radio"
                    value="annotate"
                    checked={annotateOption === "annotate"}
                    onChange={() => setAnnotateOption("annotate")}
                    name="annotation-option"
                  />
                  Annotate files
                </label>
                <label>
                  <input
                    type="radio"
                    value="no_annotate"
                    checked={annotateOption === "no_annotate"}
                    onChange={() => setAnnotateOption("no_annotate")}
                    name="annotation-option"
                  />
                  Don't annotate
                </label>
              </div>
              {getAnnotationInfoText()}
            </>
          ) : uploadType === "sequence" ? (
            renderSequenceInputPanel()
          ) : uploadType === "docs" ? (
            <>
              <div {...getDocRootProps()} className="dropzone">
                <input {...getDocInputProps()} />
                {isDocDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>Drag and drop documents here, or click to select files</p>
                )}
              </div>
              {docFiles.length > 0 && (
                <div className="file-list">
                  <h3>Selected Files:</h3>
                  <ul>
                    {docFiles.map((file, index) => (
                      <li key={index}>
                        <div>
                          {file.name} - {formatFileSize(file.size)}
                          <button
                            type="button"
                            onClick={() => removeDocFile(file)}
                          >
                            Remove
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {/* <div
                className="file-upload-radio-group"
                role="radiogroup"
                aria-label="Structure options"
              >
                <label>
                  <input
                    type="radio"
                    value="structure"
                    checked={structureOption === "structure"}
                    onChange={() => setStructureOption("structure")}
                    name="structure-option"
                  />
                  Structure Docs
                </label>
                <label>
                  <input
                    type="radio"
                    value="no_structure"
                    checked={structureOption === "no_structure"}
                    onChange={() => setStructureOption("no_structure")}
                    name="structure-option"
                  />
                  Don't Structure
                </label>
              </div> */}
              <div className="annotation-info" role="note">
                Uploading papers requires one request per paper due to extensive
                pre-processing we do to ensure our agent can understand your
                paper well. This process takes ~1 minute, so if you start to
                chat with your paper before that, the agent may tell you it
                doesn't have access to your paper yet.
              </div>
            </>
          ) : (
            <>
              <div {...getDataRootProps()} className="dropzone">
                <input {...getDataInputProps()} />
                {isDataDragActive ? (
                  <p>Drop the CSV files here ...</p>
                ) : (
                  <p>Drag and drop CSV files here, or click to select files</p>
                )}
              </div>
              {dataFiles.length > 0 && (
                <div className="file-list">
                  <h3>Selected Files:</h3>
                  <ul>
                    {dataFiles.map((file, index) => (
                      <li key={index}>
                        <div>
                          {file.name} - {formatFileSize(file.size)}
                          <button
                            type="button"
                            onClick={() => removeDataFile(file)}
                          >
                            Remove
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="annotation-info" role="note">
                Data can only be uploaded in .csv file format.
              </div>
            </>
          )}

          <div className="file-upload-modal-buttons">
            <button
              type="button"
              onClick={() =>
                dispatch(
                  updateModal({
                    name: modal.name.replace("upload", "dna_archive"),
                  })
                )
              }
              className="file-upload-cancel-button"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="file-upload-confirm-button"
              disabled={
                uploadType === "sequence" && (!!sequenceError || !sequence)
              }
            >
              Upload
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FileUploadModal;
