import React, { useState, useMemo, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SeqViz } from "seqviz";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { createPortal } from "react-dom";
import "../style/Sequence.css";
import { downloadFiles } from "../api/files_api.js";
import {
  fetchArtifactData,
  setCurrentSequence,
  setSequenceViewerSelection,
  setSequenceViewerType,
} from "../store/project.js";
import seedrandom from "seedrandom";

// Add this near the top of the file after imports
const originalError = console.error;
console.error = (...args) => {
  if (
    args[0]?.includes("Warning: Encountered two children with the same key") ||
    args[0]?.includes(
      'Warning: Each child in a list should have a unique "key" prop'
    )
  ) {
    return;
  }
  originalError.apply(console, args);
};

/* 
  Popup for filtering enzymes and annotations 
*/
function FilterPopup({
  type,
  selectedCutGroups,
  setSelectedCutGroups,
  enzymeGroups,
  annotations,
  selectedAnnotationTypes,
  setSelectedAnnotationTypes,
  searchQuery,
  setSearchQuery,
  sequenceQuery,
  setSequenceQuery,
  onClose,
  buttonRef,
  enzymeSearchQuery,
  setEnzymeSearchQuery,
}) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const popupRef = useRef(null);
  const headerRef = useRef(null);

  const handleMouseDown = (e) => {
    // Only allow dragging from the header
    if (!headerRef.current?.contains(e.target)) return;

    setIsDragging(true);
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setPosition({
      x: e.clientX - dragStart.x,
      y: e.clientY - dragStart.y,
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [isDragging]);

  // Collect all unique annotation types
  const annotationTypes = useMemo(() => {
    const types = new Set();
    annotations?.forEach((ann) => {
      if (ann.type) types.add(ann.type);
    });
    return Array.from(types);
  }, [annotations]);

  // Set initial position based on filter button location
  useEffect(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPosition({
        x: rect.right - (popupRef.current?.offsetWidth || 0),
        y: rect.bottom + 12,
      });
    }
  }, [buttonRef]);

  return createPortal(
    <div
      className="filter-popup"
      ref={popupRef}
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        position: "fixed",
      }}
    >
      <div
        className="filter-popup-header"
        ref={headerRef}
        onMouseDown={handleMouseDown}
      >
        <h3>{type === "enzymes" ? "Enzyme Controls" : "Feature Controls"}</h3>
        <button className="filter-popup-close" onClick={onClose}>
          ×
        </button>
      </div>

      <div className="filter-popup-content">
        {type === "enzymes" ? (
          <div className="filter-section">
            <h3>Search Enzymes</h3>
            <input
              type="text"
              value={enzymeSearchQuery}
              onChange={(e) => setEnzymeSearchQuery(e.target.value)}
              placeholder="Enter enzyme names (e.g. EcoRI, BamHI)..."
              className="enzyme-search"
            />

            <h3>Cut Site Groups</h3>
            <div className="enzyme-checkbox-group">
              <label>
                <input
                  type="checkbox"
                  checked={selectedCutGroups.includes("single")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedCutGroups([...selectedCutGroups, "single"]);
                    } else {
                      setSelectedCutGroups(
                        selectedCutGroups.filter((g) => g !== "single")
                      );
                    }
                  }}
                />
                Single ({enzymeGroups.single?.length || 0})
              </label>

              <label>
                <input
                  type="checkbox"
                  checked={selectedCutGroups.includes("double")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedCutGroups([...selectedCutGroups, "double"]);
                    } else {
                      setSelectedCutGroups(
                        selectedCutGroups.filter((g) => g !== "double")
                      );
                    }
                  }}
                />
                Double ({enzymeGroups.double?.length || 0})
              </label>

              <label>
                <input
                  type="checkbox"
                  checked={selectedCutGroups.includes("multiple")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedCutGroups([...selectedCutGroups, "multiple"]);
                    } else {
                      setSelectedCutGroups(
                        selectedCutGroups.filter((g) => g !== "multiple")
                      );
                    }
                  }}
                />
                Multiple ({enzymeGroups.multiple?.length || 0})
              </label>
            </div>
          </div>
        ) : (
          <>
            <div className="filter-section">
              <h3>Search Sequence</h3>
              <input
                type="text"
                value={sequenceQuery}
                onChange={(e) => setSequenceQuery(e.target.value.toUpperCase())}
                placeholder="Enter DNA sequence..."
                className="sequence-search"
              />
            </div>

            <div className="filter-section">
              <h3>Annotation Types</h3>
              <div className="annotation-checkbox-group">
                {annotationTypes.map((type) => (
                  <label key={type}>
                    <input
                      type="checkbox"
                      checked={selectedAnnotationTypes.includes(type)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedAnnotationTypes([
                            ...selectedAnnotationTypes,
                            type,
                          ]);
                        } else {
                          setSelectedAnnotationTypes(
                            selectedAnnotationTypes.filter((t) => t !== type)
                          );
                        }
                      }}
                    />
                    {type}
                  </label>
                ))}
              </div>
            </div>

            <div className="filter-section">
              <h3>Search Annotations</h3>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search annotation names..."
                className="annotation-search"
              />
            </div>
          </>
        )}
      </div>
    </div>,
    document.body
  );
}

/* 
  Controls (top bar) for the sequence viewer 
*/
function SequenceControls({
  viewerType,
  setViewerType,
  currentSequenceName,
  sequenceNames,
  handleSequenceChange,
  zoom,
  setZoom,
  selectedCutGroups,
  setSelectedCutGroups,
  enzymeGroups,
  annotations,
  setFilteredAnnotations,
  handleNavigation,
  showTooltip,
  tooltipMessage,
  topology,
  seqType,
  sequenceQuery,
  setSequenceQuery,
  showFeatureFilter,
  setShowFeatureFilter,
  enzymeSearchQuery,
  setEnzymeSearchQuery,
}) {
  const [showEnzymeFilter, setShowEnzymeFilter] = useState(false);
  const [selectedAnnotationTypes, setSelectedAnnotationTypes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const enzymeButtonRef = useRef(null);
  const featureButtonRef = useRef(null);

  const sequenceViewerSelection = useSelector(
    (state) => state.project.sequenceViewerSelection
  );

  // Collect all unique annotation types from raw annotations
  const annotationTypes = useMemo(() => {
    const types = new Set();
    annotations?.forEach((ann) => {
      if (ann.type) types.add(ann.type);
    });
    return Array.from(types);
  }, [annotations]);

  // Initialize annotation types on first load
  useEffect(() => {
    if (annotationTypes.length > 0 && selectedAnnotationTypes.length === 0) {
      setSelectedAnnotationTypes(annotationTypes);
    }
  }, [annotationTypes, selectedAnnotationTypes.length]);

  // Filter annotations by annotation type + search
  useEffect(() => {
    if (!annotations) return;

    let filtered = [...annotations];

    // Filter by type
    if (selectedAnnotationTypes.length > 0) {
      filtered = filtered.filter((ann) =>
        selectedAnnotationTypes.includes(ann.type)
      );
    }
    // Filter by search
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter((ann) =>
        ann.name?.toLowerCase().includes(query)
      );
    }

    setFilteredAnnotations(filtered);
  }, [
    annotations,
    selectedAnnotationTypes,
    searchQuery,
    setFilteredAnnotations,
  ]);

  return (
    <div className="viewer-controls">
      <div className="dropdowns-container">
        {/* Only show viewer type dropdown for DNA sequences */}
        {(!seqType || seqType === "dna") && (
          <div className="viewer-type-container">
            <label className="viewer-type-label" htmlFor="viewer-type-dropdown">
              Viewer
            </label>
            <select
              id="viewer-type-dropdown"
              className="viewer-type-dropdown"
              value={viewerType}
              onChange={(e) => setViewerType(e.target.value)}
            >
              <option value="linear">Linear</option>
              <option value="circular" disabled={topology === "linear"}>
                Circular {topology === "linear" ? "(not applicable)" : ""}
              </option>
              <option value="both" disabled={topology === "linear"}>
                Both (Circular Left){" "}
                {topology === "linear" ? "(not applicable)" : ""}
              </option>
              <option value="both_flip" disabled={topology === "linear"}>
                Both (Circular Right){" "}
                {topology === "linear" ? "(not applicable)" : ""}
              </option>
            </select>
          </div>
        )}

        {/* Sequence Name Selector (if multiple sequences) */}
        {sequenceNames.length > 1 && (
          <div className="sequence-select-container">
            <label
              className="sequence-select-label"
              htmlFor="sequence-select-dropdown"
            >
              Sequence
            </label>
            <select
              id="sequence-select-dropdown"
              className="sequence-select-dropdown"
              value={currentSequenceName}
              onChange={handleSequenceChange}
            >
              {sequenceNames.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Selection metadata */}
        <div className="selection-meta">
          <div className="meta-datum">
            <p className="field">Length</p>
            <p className="value">
              {sequenceViewerSelection?.length
                ? `${sequenceViewerSelection.length}bp`
                : "-"}
            </p>
          </div>
          <div className="meta-datum">
            <p className="field">Range</p>
            <p className="value">
              {sequenceViewerSelection?.start !== undefined &&
              sequenceViewerSelection?.end !== undefined
                ? sequenceViewerSelection.start === sequenceViewerSelection.end
                  ? `${sequenceViewerSelection.start + 1}`
                  : `${sequenceViewerSelection.start + 1} - ${
                      sequenceViewerSelection.end
                    }`
                : "Select range"}
            </p>
          </div>
        </div>

        {/* Filter popup button */}
        <div className="filter-controls">
          <div className="filter-nav-group">
            <button
              className="filter-button"
              onClick={() => setShowFeatureFilter(!showFeatureFilter)}
              ref={featureButtonRef}
            >
              Features
            </button>
            <button
              className="filter-button"
              onClick={() => setShowEnzymeFilter(!showEnzymeFilter)}
              ref={enzymeButtonRef}
            >
              Enzymes
            </button>
          </div>
          {showEnzymeFilter && (
            <FilterPopup
              type="enzymes"
              selectedCutGroups={selectedCutGroups}
              setSelectedCutGroups={setSelectedCutGroups}
              enzymeGroups={enzymeGroups}
              onClose={() => setShowEnzymeFilter(false)}
              buttonRef={enzymeButtonRef}
              enzymeSearchQuery={enzymeSearchQuery}
              setEnzymeSearchQuery={setEnzymeSearchQuery}
            />
          )}
          {showFeatureFilter && (
            <FilterPopup
              type="features"
              annotations={annotations}
              selectedAnnotationTypes={selectedAnnotationTypes}
              setSelectedAnnotationTypes={setSelectedAnnotationTypes}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              sequenceQuery={sequenceQuery}
              setSequenceQuery={setSequenceQuery}
              onClose={() => setShowFeatureFilter(false)}
              buttonRef={featureButtonRef}
            />
          )}
        </div>
      </div>

      <div className="sequence-controls-right">
        {/* Zoom (only visible for linear/both) */}
        {viewerType !== "circular" && (
          <div className="zoom-container">
            <label className="zoom-label" htmlFor="zoom-slider">
              Zoom
            </label>
            <input
              type="range"
              id="zoom-slider"
              className="zoom-slider"
              min="1"
              max="100"
              value={zoom}
              onChange={(e) => setZoom(parseInt(e.target.value))}
            />
          </div>
        )}

        <div className="nav-buttons">
          <button
            className="nav-button"
            onClick={() => handleNavigation("back")}
          >
            <FaArrowLeft />
          </button>
          <button
            className="nav-button"
            onClick={() => handleNavigation("forward")}
          >
            <FaArrowRight />
          </button>

          {/* Navigation tooltip (for "no earlier" or "no later" errors) */}
          {showTooltip && <div className="nav-tooltip">{tooltipMessage}</div>}
        </div>
      </div>
    </div>
  );
}

/* 
  Main Sequence component 
*/
function Sequence() {
  const dispatch = useDispatch();
  const artifact = useSelector((state) => state.project.artifact);
  const storedViewerType = useSelector(
    (state) => state.project.sequenceViewerType
  );
  const sequenceViewerSelection = useSelector(
    (state) => state.project.sequenceViewerSelection
  );
  const token = useSelector((state) => state.user.access_token);

  // Viewer state
  const [viewerType, setViewerType] = useState(storedViewerType || "circular");
  const [selectedSequenceName, setSelectedSequenceName] = useState(null);
  const [zoom, setZoom] = useState(50);

  // Restriction enzyme filtering
  const [selectedCutGroups, setSelectedCutGroups] = useState([]);
  const [filteredAnnotations, setFilteredAnnotations] = useState([]);

  // Tooltip for "no earlier/no later version"
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const tooltipTimeout = useRef(null);

  // Add state for sequence search
  const [sequenceQuery, setSequenceQuery] = useState("");

  // Add state for feature filter visibility
  const [showFeatureFilter, setShowFeatureFilter] = useState(false);

  // Add enzyme search state
  const [enzymeSearchQuery, setEnzymeSearchQuery] = useState("");

  // Track previous sequence ID and topology to detect changes
  const [previousSequenceId, setPreviousSequenceId] = useState(null);
  const [previousTopology, setPreviousTopology] = useState(null);

  // --------------------------------------------------
  // Utility: collect valid sequences from artifact.content
  // --------------------------------------------------
  const validSequences = useMemo(() => {
    if (!artifact?.content) return [];
    const sequences = Object.keys(artifact.content).filter(
      (k) =>
        !["type", "file_id", "protocol_id", "digest_vendor"].includes(k) &&
        !k.startsWith("table_input_")
    );
    return sequences;
  }, [artifact]);

  // Auto-select the first available sequence if none chosen
  useEffect(() => {
    if (artifact?.content && validSequences.length > 0) {
      if (!selectedSequenceName || !artifact.content[selectedSequenceName]) {
        setSelectedSequenceName(validSequences[0]);
      }
    } else {
      setSelectedSequenceName(null);
    }
  }, [artifact, validSequences, selectedSequenceName]);

  // The actual sequence data object from artifact.content
  const sequenceData = useMemo(() => {
    if (!artifact?.content || !selectedSequenceName) return null;
    const data = artifact.content[selectedSequenceName];
    return data;
  }, [artifact, selectedSequenceName]);

  // Add logging for annotations
  useEffect(() => {
    if (sequenceData?.annotations) {
      setFilteredAnnotations(sequenceData.annotations);
    }
  }, [sequenceData?.annotations]);

  // Keep Redux updated with the current active sequence name
  useEffect(() => {
    if (selectedSequenceName) {
      dispatch(setCurrentSequence(selectedSequenceName));
    }
  }, [selectedSequenceName, dispatch]);

  // Clean sequence to only A/T/G/C (avoid N or non-bases)
  const cleanedSequence = useMemo(() => {
    if (!sequenceData?.seq) return "";
    // Get the sequence type from content
    const seqType = sequenceData.type || "dna"; // Default to DNA if not specified

    // Different cleaning rules based on sequence type
    switch (seqType.toLowerCase()) {
      case "aa":
        // Allow amino acid characters
        return sequenceData.seq.replace(/[^ACDEFGHIKLMNPQRSTVWY]/gi, "");
      case "rna":
        // Allow RNA bases (U instead of T)
        return sequenceData.seq.replace(/[^AUGC]/gi, "");
      case "dna":
      default:
        // Original DNA cleaning (A,T,G,C only)
        return sequenceData.seq.replace(/[^ATGC]/gi, "");
    }
  }, [sequenceData]);

  // Add effect to detect artifact changes and reset previous sequence ID
  useEffect(() => {
    // When artifact changes, reset the previous sequence ID and topology
    // to force reevaluation of the viewer type
    setPreviousSequenceId(null);
    setPreviousTopology(null);
  }, [artifact?.file_id]);

  // Update viewer type for new sequences or on topology changes
  useEffect(() => {
    if (!sequenceData) return;

    // Get a unique identifier for this sequence
    const currentSequenceId =
      sequenceData.id || `${selectedSequenceName}-${sequenceData.name}`;

    // Get the current topology
    const currentTopology = sequenceData.topology || "linear";

    // Check for different scenarios:
    // 1. Same sequence ID but different topology
    // 2. Same sequence ID and same topology (just a refresh)
    // 3. Different sequence ID (new sequence)

    const isTopologyChanged =
      previousSequenceId === currentSequenceId &&
      previousTopology !== currentTopology;

    const isSequenceRefresh =
      previousSequenceId === currentSequenceId &&
      previousTopology === currentTopology;

    // Always update the previous values for future comparisons
    if (
      previousSequenceId !== currentSequenceId ||
      previousTopology !== currentTopology
    ) {
      setPreviousSequenceId(currentSequenceId);
      setPreviousTopology(currentTopology);
    }

    // If this is just a refresh of the same sequence with the same topology, keep the current view type
    if (isSequenceRefresh) {
      return; // Exit early - no need to change the viewer type
    }

    // For new sequences OR topology changes, set viewer type based on sequence properties
    const isProteinOrRNA =
      sequenceData.type?.toLowerCase() === "aa" ||
      sequenceData.type?.toLowerCase() === "rna";

    if (!isProteinOrRNA) {
      // Set viewer type based on topology for new sequences or topology changes
      const newViewerType =
        currentTopology === "circular" ? "circular" : "linear";
      setViewerType(newViewerType);
    } else {
      // Force linear view for RNA and protein sequences
      setViewerType("linear");
    }
  }, [
    sequenceData,
    selectedSequenceName,
    previousSequenceId,
    previousTopology,
  ]);

  // Prepare enzyme grouping from "enzyme_counts" or "cutting_enzymes"
  const enzymeGroups = useMemo(() => {
    if (!sequenceData?.enzyme_counts) return {};
    const allGroups = { single: [], double: [], multiple: [] };

    // If we have enzyme_counts for each cut count, build the groups
    for (const [cutCountStr, enzymesArr] of Object.entries(
      sequenceData.enzyme_counts
    )) {
      const cutCount = parseInt(cutCountStr, 10);
      if (!Array.isArray(enzymesArr)) continue;
      enzymesArr.forEach((enzyme) => {
        // Convert cut values to integers
        const processedEnzyme = {
          ...enzyme,
          fcut: parseInt(enzyme.fcut, 10),
          rcut: parseInt(enzyme.rcut, 10),
        };

        if (processedEnzyme.fcut === 0 && processedEnzyme.rcut === 0) return; // skip nonsense cuts
        if (cutCount === 1) allGroups.single.push(processedEnzyme);
        else if (cutCount === 2) allGroups.double.push(processedEnzyme);
        else if (cutCount > 2) allGroups.multiple.push(processedEnzyme);
      });
    }
    return allGroups;
  }, [sequenceData]);

  // Update selectedEnzymes to handle enzyme search
  const selectedEnzymes = useMemo(() => {
    if (!sequenceData) return [];

    // Get all possible enzymes from enzyme_counts
    const allEnzymes = Object.values(enzymeGroups).flat();

    // Filter enzymes based on search query
    const searchTerms = enzymeSearchQuery
      .toLowerCase()
      .split(",")
      .map((term) => term.trim())
      .filter((term) => term.length > 0);

    let filteredEnzymes = [];

    if (selectedCutGroups.length > 0) {
      // If groups are selected, filter from those groups first
      filteredEnzymes = selectedCutGroups.flatMap(
        (group) => enzymeGroups[group] || []
      );

      // Then apply search filter if there's a search query
      if (searchTerms.length > 0) {
        filteredEnzymes = filteredEnzymes.filter((enzyme) =>
          searchTerms.some((term) => enzyme.name.toLowerCase().includes(term))
        );
      }
    } else if (searchTerms.length > 0) {
      // If no groups selected but we have search terms, search all enzymes
      filteredEnzymes = allEnzymes.filter((enzyme) =>
        searchTerms.some((term) => enzyme.name.toLowerCase().includes(term))
      );
    }

    // If the sequence has default cutting_enzymes array, include them
    if (sequenceData.cutting_enzymes) {
      const cutArr = Array.isArray(sequenceData.cutting_enzymes)
        ? sequenceData.cutting_enzymes
        : [];
      return [...filteredEnzymes, ...cutArr];
    }

    return filteredEnzymes;
  }, [sequenceData, enzymeGroups, selectedCutGroups, enzymeSearchQuery]);

  // Navigation error tooltip (back/forward)
  const showNavigationErrorTooltip = (msg) => {
    setTooltipMessage(msg);
    setShowTooltip(true);
    if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
    tooltipTimeout.current = setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
    };
  }, []);

  // SequenceControls -> next/prev version
  const handleNavigation = (direction) => {
    if (!artifact?.file_id) return;
    console.log("fetching artifact data for fileId in Sequence.js handleNavigation:", artifact.file_id);
    dispatch(
      fetchArtifactData({
        fileId: artifact.file_id,
        direction,
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message.includes("No previous")) {
          showNavigationErrorTooltip("No earlier version");
        } else if (err.message.includes("No later")) {
          showNavigationErrorTooltip("No later version");
        } else {
          showNavigationErrorTooltip("Unable to load version");
        }
      });
  };

  // Handler for sequence dropdown
  const handleSequenceChange = (e) => {
    setSelectedSequenceName(e.target.value);
  };

  // Split annotations into primers and translations
  const {
    translations,
    primers,
    annotations: filteredBaseAnnotations,
  } = useMemo(() => {
    if (!sequenceData?.annotations)
      return { translations: [], primers: [], annotations: [] };

    const translations = [];
    const primers = [];
    const otherAnnotations = [];

    sequenceData.annotations.forEach((ann, index) => {
      // Generate a unique ID for each annotation if not present
      const annotationWithId = {
        ...ann,
        id: ann.id || `ann-${ann.start}-${ann.end}-${ann.name}`,
        // Use index as seed for color generation
        color: ann.color || chooseRandomColor(index),
      };

      if (ann.type === "CDS") {
        translations.push({
          ...annotationWithId,
          direction: ann.direction || 1,
        });
        otherAnnotations.push(annotationWithId);
      } else if (ann.type === "primer_bind") {
        primers.push({
          ...annotationWithId,
          direction: ann.direction || 1,
          name: ann.name || `Primer ${ann.start}-${ann.end}`,
        });
      } else {
        otherAnnotations.push(annotationWithId);
      }
    });

    return {
      translations,
      primers,
      annotations: otherAnnotations,
    };
  }, [sequenceData?.annotations]);

  // Update filtered annotations to exclude translations (they'll be passed separately)
  useEffect(() => {
    setFilteredAnnotations(filteredBaseAnnotations);
  }, [filteredBaseAnnotations]);

  // Generate highlights from sequence search
  const sequenceHighlights = useMemo(() => {
    if (!sequenceQuery || !cleanedSequence) return [];

    const highlights = [];
    let index = cleanedSequence.indexOf(sequenceQuery);

    while (index !== -1) {
      highlights.push({
        start: index,
        end: index + sequenceQuery.length,
        color: "#FFE4B5", // Moccasin color for sequence highlights
      });
      index = cleanedSequence.indexOf(sequenceQuery, index + 1);
    }

    return highlights;
  }, [sequenceQuery, cleanedSequence]);

  // Combine sequence highlights with any existing highlights
  const allHighlights = useMemo(() => {
    const existingHighlights = sequenceData?.highlights || [];
    return [...existingHighlights, ...sequenceHighlights];
  }, [sequenceData?.highlights, sequenceHighlights]);

  // Add keyboard shortcut handler
  useEffect(() => {
    const handleKeyDown = async (e) => {
      // Check for cmd/ctrl + f
      if ((e.metaKey || e.ctrlKey) && e.key === "f") {
        e.preventDefault(); // Prevent default browser find

        // Try to get clipboard content
        try {
          const clipText = await navigator.clipboard.readText();
          // Check if clipboard contains only DNA bases
          const isDNA = /^[ATGC]+$/i.test(clipText);

          // Set sequence query if clipboard contains DNA
          if (isDNA) {
            setSequenceQuery(clipText.toUpperCase());
          }

          // Open feature popup
          setShowFeatureFilter(true);
        } catch (err) {
          // If can't access clipboard, just open popup
          setShowFeatureFilter(true);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  // Update stored viewer type in Redux when it changes
  useEffect(() => {
    dispatch(setSequenceViewerType(viewerType));
  }, [viewerType, dispatch]);

  // If there's no sequence to display, show nothing
  if (!sequenceData) {
    return null;
  }

  return (
    <div className="sequence-content">
      <SequenceControls
        viewerType={viewerType}
        setViewerType={setViewerType}
        currentSequenceName={selectedSequenceName}
        sequenceNames={validSequences}
        handleSequenceChange={handleSequenceChange}
        zoom={zoom}
        setZoom={setZoom}
        selectedCutGroups={selectedCutGroups}
        setSelectedCutGroups={setSelectedCutGroups}
        enzymeGroups={enzymeGroups}
        annotations={filteredBaseAnnotations}
        setFilteredAnnotations={setFilteredAnnotations}
        handleNavigation={handleNavigation}
        showTooltip={showTooltip}
        tooltipMessage={tooltipMessage}
        topology={sequenceData?.topology}
        seqType={sequenceData.type?.toLowerCase()}
        sequenceQuery={sequenceQuery}
        setSequenceQuery={setSequenceQuery}
        showFeatureFilter={showFeatureFilter}
        setShowFeatureFilter={setShowFeatureFilter}
        enzymeSearchQuery={enzymeSearchQuery}
        setEnzymeSearchQuery={setEnzymeSearchQuery}
      />

      <SeqViz
        name={sequenceData.name || selectedSequenceName}
        seq={cleanedSequence}
        annotations={filteredAnnotations}
        translations={translations}
        primers={primers}
        enzymes={selectedEnzymes}
        highlights={allHighlights}
        viewer={viewerType}
        onSelection={(newSelection) =>
          dispatch(setSequenceViewerSelection(newSelection))
        }
        selection={sequenceViewerSelection}
        zoom={{ linear: zoom }}
        seqType={sequenceData.type?.toLowerCase() || "dna"}
      />
    </div>
  );
}

// Update the chooseRandomColor function to accept an index parameter
function chooseRandomColor(index) {
  // Create a new seeded random number generator using the index
  const rng = seedrandom(index.toString());

  const colors = [
    "#FFA07A", // Light salmon
    "#AFEEEE", // Pale turquoise
    "#D8BFD8", // Thistle
    "#FFE4E1", // Misty rose
    "#B0E0E6", // Powder blue
    "#FFB6C1", // Light pink
    "#98FF98", // Mint green
    "#DEB887", // Burlywood
    "#9DEAED", // Light blue
    "#8FDE8C", // Light green
    "#CFF283", // Lime green
    "#8CDEBD", // Seafoam
    "#F0A3FF", // Light purple
    "#F7C0BE", // Light pink
    "#FAB0E4", // Pink
    "#FFB480", // Light orange
    "#F8D774", // Light yellow
    "#B4A8E1", // Lavender
    "#FFE4B5", // Moccasin
    "#98FB98", // Pale green
    "#DDA0DD", // Plum
    "#87CEEB", // Sky blue
    "#F0E68C", // Khaki
    "#F5DEB3", // Wheat
    "#FFDAB9", // Peach puff
    "#E0FFFF", // Light cyan
    "#FFE4C4", // Bisque
    "#FFF0F5", // Lavender blush
    "#F0FFF0", // Honeydew
    "#F5F5DC", // Beige
    "#FDF5E6", // Old lace
    "#F0F8FF", // Alice blue
    "#F8F8FF", // Ghost white
    "#FFF5EE", // Seashell
    "#FAEBD7", // Antique white
    "#E6E6FA", // Lavender
    "#FFE4B5", // Moccasin
    "#FFFACD", // Lemon chiffon
    "#F5FFFA", // Mint cream
    "#E6E6FA", // Lavender mist
  ];
  return colors[Math.floor(rng() * colors.length)];
}

export default Sequence;
