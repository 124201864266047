import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({ token, user_auth0 }, { dispatch, rejectWithValue }) => {
    console.log("fetchUser called with:", { token, user_auth0 });
    // if (user_auth0) {
    //   user_auth0.sub = "x";
    // }
    const response = await handleLogin({ token, user_auth0 });

    return response;
  }
);

export const fetchUserReducer = {
  [fetchUser.pending]: (state) => {
    state.status = "loading";
  },
  [fetchUser.fulfilled]: (state, action) => {
    state.isCheckingAuth = false;
    state.status = "succeeded";
    console.log("fetchUser.fulfilled", action.payload);
    state.data = action.payload.data.data;
    if (action.payload.data?.data?.user_id) {
      state.user_id = action.payload.data.data.user_id;
    }

    if (action.payload.access_token) {
      state.access_token = action.payload.access_token;
    }
    if (action.payload.data?.data?.files) {
      state.files = action.payload.data.data.files || {
        0: { file_id: "0", file_name: "test" },
      };
      console.log("state.files", state.files);
    }
    if (action.payload.data?.data?.files_next_token) {
      state.files_next_token = action.payload.data.data.files_next_token;
    }
  },
  [fetchUser.rejected]: (state, action) => {
    state.isCheckingAuth = false;
    state.status = "failed";
    state.error = action.payload || action.error.message;
    state.data = null;
    state.user_id = null;
    state.access_token = null;
  },
};

export const handleLogin = async ({ token, user_auth0 }) => {
  try {
    console.log("handleLogin called with:", { user_auth0 });
    const response = await fetchWithToken(
      `${API_URL}/api/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_auth0: user_auth0 || null,
        }),
        credentials: "include",
      },
      token
    );
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to handle Google login");
    }
    return response.json();
  } catch (error) {
    console.error("Error in handleLogin:", error);
    throw error;
  }
};
