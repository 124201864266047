import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const fetchArtifactData = createAsyncThunk(
  "projects/fetchArtifactData",
  async (
    { fileId, direction = "normal", skipApiCall = false, artifactData = null },
    { getState }
  ) => {
    if (!fileId) {
      return null;
    }

    // If skipApiCall is true and artifactData is provided, return it directly
    if (skipApiCall && artifactData) {
      console.log(
        "Skipping API call and using provided artifact data",
        artifactData
      );

      // If we're receiving data from a file_changed event, it will have this structure
      if (artifactData.status === "success" && artifactData.data) {
        // Keep the expected structure for the rest of the application
        return {
          file_id: artifactData.file_id,
          file_name: artifactData.file_name,
          type: artifactData.data.type,
          content: artifactData.data.content,
          status: artifactData.status,
        };
      }

      // If it's already in the expected format, return as is
      return artifactData;
    }

    const token = getState().user.access_token;
    try {
      // Check if PDF is already in session storage
      const cachedArtifact = sessionStorage.getItem(fileId);
      if (cachedArtifact) {
        console.log("Returning cached artifact");
        return JSON.parse(cachedArtifact);
      }
      const response = await fetchArtifact(fileId, token, direction);
      return response;
    } catch (error) {
      console.error("Error in fetchArtifactData:", error);
      throw error;
    }
  }
);

export const fetchArtifactDataReducer = {
  [fetchArtifactData.fulfilled]: (state, action) => {
    state.isArtifactLoading = false;
    if (action.payload === null) {
      return;
    }

    // Ensure we're getting a completely fresh artifact state
    const newArtifact = JSON.parse(JSON.stringify(action.payload));

    if (newArtifact.type === "pdf") {
      if (!sessionStorage.getItem(newArtifact.file_id)) {
        console.log("Caching paper in session storage");
        sessionStorage.setItem(
          newArtifact.file_id,
          JSON.stringify(newArtifact)
        );
      }
    }

    // Get valid sequence names from new artifact's content
    const sequenceNames = Object.keys(newArtifact.content || {}).filter(
      (key) =>
        key !== "type" &&
        key !== "file_id" &&
        key !== "protocol_id" &&
        key !== "digest_vendor" &&
        !key.startsWith("table_input_")
    );

    // Update artifact first
    state.artifact = newArtifact;

    // Handle file tab and active state
    if (newArtifact.file_id && newArtifact.file_name) {
      const fileId = newArtifact.file_id;

      // Update content for existing file tab
      if (state.openFiles[fileId]) {
        state.openFiles[fileId].content = newArtifact;
      }
    }

    // Handle sequence selection
    if (state.currentSequence && newArtifact.content?.[state.currentSequence]) {
      // Keep current sequence if it exists in new artifact
    } else if (sequenceNames.length > 0 && newArtifact.type !== "data") {
      // Select first available sequence if current one doesn't exist
      // Only do this for non-data types
      state.currentSequence = sequenceNames[0];
    } else {
      // Reset current sequence if no sequences available
      state.currentSequence = null;
    }

    // Handle design case
    if (newArtifact.type === "design") {
      state.design = newArtifact.content;
    }
    state.editorNeedsRemount = true;
  },
  [fetchArtifactData.pending]: (state, action) => {
    // Only show loading state if this is not a navigation action
    if (!action.meta.arg.direction) {
      state.isArtifactLoading = true;
    }
    state.error = null;
  },
  [fetchArtifactData.rejected]: (state, action) => {
    state.error = action.error.message;
    // Only update loading state if this is not a navigation action
    if (!action.meta.arg.direction) {
      state.isArtifactLoading = false;
    }

    // Don't throw any errors - just log them
    console.error("Error in fetchArtifactData:", action.error.message);
  },
};

export const fetchArtifact = async (file_id, token, direction = "normal") => {
  console.log("fetchArtifact called with:", { file_id, direction });
  const response = await fetchWithToken(
    `${API_URL}/api/fetch-artifact`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_id: file_id,
        direction: direction,
      }),
    },
    token
  );

  if (!response.ok) {
    // Handle both forward and backward navigation errors
    if (direction === "forward" && response.status === 404) {
      throw new Error("No later versions available");
    } else if (direction === "back" && response.status === 404) {
      throw new Error("No previous versions available");
    }
    throw new Error("Failed to fetch artifact");
  }
  const result = await response.json();
  console.log(
    "fetchArtifact response:",
    result.data,
    "result.status",
    result["status"]
  );

  if (result.status === "success") {
    // For each sequence in the result, add topology if not present
    const data = result.data;
    Object.keys(data).forEach((key) => {
      if (data[key] && typeof data[key] === "object" && data[key].seq) {
        data[key].topology = data[key].topology || "linear"; // Default to linear if not specified
      }
    });

    return data;
  } else {
    console.error("Error fetching artifact:", result.message);
    throw new Error(result.message || "Failed to fetch artifact");
  }
};
