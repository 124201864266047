import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { handleFileClick, updateProject } from "../project.js";

export const applyToolCall = createAsyncThunk(
  "projects/applyToolCall",
  async (
    { filename, projectId, request, functionName },
    { getState, dispatch }
  ) => {
    const token = getState().user.access_token;
    const project = getState().project.currentProject;

    // Save chat-related fields before making the API call
    const chatFields = {
      agent_chat_files: project.agent_chat_files,
      agent_chat_next_token: project.agent_chat_next_token,
      chat_chat_files: project.chat_chat_files,
      chat_chat_next_token: project.chat_chat_next_token,
      popup_chat_files: project.popup_chat_files,
    };

    console.log("ApplyToolCall - Chat fields to preserve:", chatFields);

    console.log("ApplyToolCall - Input params:", {
      filename,
      projectId,
      request,
      functionName,
    });

    const fileId = Object.keys(project.files).find(
      (id) => project.files[id].file_name === filename
    );

    console.log("ApplyToolCall - Found fileId:", fileId);

    try {
      const requestBody = {
        filename,
        project_id: projectId,
        request,
        function_name: functionName,
      };

      const response = await fetchWithToken(
        `${API_URL}/api/apply-tool-call`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        },
        token
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("ApplyToolCall - Server error response:", {
          status: response.status,
          statusText: response.statusText,
          errorText,
        });
        throw new Error(`Failed to apply tool call edits: ${errorText}`);
      }

      const result = await response.json();
      console.log("ApplyToolCall - Server response:", result);

      if (result.status === "success") {
        // Parse the project data if it's a string
        const projectData =
          typeof result.data === "string"
            ? JSON.parse(JSON.parse(result.data.project))
            : JSON.parse(result.data.project);

        // Merge the saved chat fields with the response
        const updatedProjectData = {
          ...projectData,
          ...chatFields,
        };

        console.log("ApplyToolCall - Merged project data:", updatedProjectData);
        dispatch(updateProject(updatedProjectData));

        let editedFileId = null;
        if (updatedProjectData && updatedProjectData.files) {
          editedFileId = Object.keys(updatedProjectData.files).find((id) =>
            updatedProjectData.files[id].s3_url?.includes("_edit")
          );
        }

        if (editedFileId) {
          console.log(
            "ApplyToolCall - Fetching updated artifact data for edited fileId:",
            editedFileId
          );

          await dispatch(
            handleFileClick({
              fileData: {
                uuid: editedFileId,
                name: updatedProjectData.files[editedFileId].file_name,
                file_type: updatedProjectData.files[editedFileId].file_type,
              },
            })
          );
        }
        return updatedProjectData;
      } else {
        throw new Error(result.message || "Failed to apply tool call edits");
      }
    } catch (error) {
      console.error("ApplyToolCall - Error:", {
        name: error.name,
        message: error.message,
        stack: error.stack,
      });
      throw error;
    }
  }
);

export const applyToolCallReducer = {
  [applyToolCall.fulfilled]: (state, action) => {
    console.log("ApplyToolCall reducer - Fulfilled:", action.payload);
    // No need to modify the state here since updateProject action
    // has already updated the project data
    state.applyingToolCall = null;
  },
  [applyToolCall.pending]: (state, action) => {
    console.log("ApplyToolCall reducer - Pending:", action.meta.arg);
    state.error = null;
    state.applyingToolCall = {
      filename: action.meta.arg.filename,
      request: action.meta.arg.request,
      projectId: action.meta.arg.projectId,
      functionName: action.meta.arg.functionName,
    };
  },
  [applyToolCall.rejected]: (state, action) => {
    console.error("ApplyToolCall reducer - Rejected:", {
      error: action.error.message,
      meta: action.meta,
    });
    state.error = action.error.message;
    state.applyingToolCall = null;
  },
};
