import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { updateUserFile } from "../user.js";
import {
  convertFilesOpenToOpenFiles,
  fetchArtifactData,
  addFileTab,
} from "../project.js";

export const fetchProject = createAsyncThunk(
  "projects/fetchProject",
  async (projectId, { getState, dispatch }) => {
    const token = getState().user.access_token;
    const userId = getState().user.user_id;

    try {
      const url = `${API_URL}/api/fetch-single-project`;
      const payload = {
        projectId,
        userId,
      };

      const response = await fetchWithToken(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        token
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      console.log("FETCH SINGLE PROJECT data", data);

      if (data.status === "success") {
        const project = data.data;

        // Compute inEditState by checking if any file's s3_url contains "_edit."
        project.inEditState = Object.values(project.files || {}).some(
          (file) => file.s3_url && file.s3_url.includes("_edit.")
        );

        // Get current user files
        const currentUserFiles = getState().user.files || {};

        // Collect all non-Output files from project
        const newFiles = {};
        if (project.files) {
          Object.entries(project.files).forEach(([fileId, fileData]) => {
            if (fileData.file_type !== "Output" && !currentUserFiles[fileId]) {
              newFiles[fileId] = fileData;
            }
          });
        }

        // Combine current files with new files
        const combinedFiles = {
          ...currentUserFiles,
          ...newFiles,
        };

        // Update user's files only if there are new files
        if (Object.keys(newFiles).length > 0) {
          dispatch(
            updateUserFile({
              replaceAll: true,
              updatedFile: combinedFiles,
            })
          );
        }

        // Only fetch first open file's data if files_open exists and has items
        if (project.files_open && project.files_open.length > 0) {
          const firstOpenFileId = project.files_open[0];
          const firstOpenFile = project.files[firstOpenFileId];
          if (firstOpenFile) {
            // Dispatch addFileTab first to set up the UI
            dispatch(
              addFileTab({
                fileId: firstOpenFileId,
                fileName: firstOpenFile.file_name,
                fileType: firstOpenFile.file_type,
                content: null,
                setActive: true,
              })
            );
            // Then fetch the file data
            console.log("fetching artifact data for fileId in fetchProject:", firstOpenFileId);
            dispatch(fetchArtifactData({ fileId: firstOpenFileId }));
          }
        }

        return project;
      } else {
        throw new Error(data.message || "Server returned an error");
      }
    } catch (error) {
      console.error("Error in fetchProject:", error.message);
      throw error;
    }
  }
);

export const fetchProjectReducer = {
  [fetchProject.pending]: (state) => {
    state.error = null;
    state.status = "loading";
    state.isProjectLoading = true;
  },
  [fetchProject.fulfilled]: (state, action) => {
    state.status = "succeeded";
    state.isProjectLoading = false;

    // Get the project from the action payload
    const project = action.payload;

    // Note: inEditState is now computed in the thunk action above
    // so we don't need to compute it again here

    // Update the project in the project list
    const projectIndex = state.projectList.findIndex(
      (p) => p.project_id === project.project_id
    );

    if (projectIndex !== -1) {
      state.projectList[projectIndex] = project;
    }

    // Set as current project
    state.currentProject = project;
    state.currentProjectId = project.project_id;

    // Update open files
    state.openFiles = convertFilesOpenToOpenFiles(
      project.files_open,
      project.files
    );

    // Set active file
    const openFileIds = Object.keys(state.openFiles);
    state.activeFileId = openFileIds.length > 0 ? openFileIds[0] : null;

    // Find and set the first chat chat as active
    if (project.agent_chat_files) {
      console.log("project.agent_chat_files", project.agent_chat_files);
      const chatChats = Object.values(project.agent_chat_files).sort(
        (a, b) => new Date(b.last_opened_date) - new Date(a.last_opened_date)
      );

      if (chatChats.length > 0) {
        const firstChat = chatChats[0];
        state.chat[firstChat.chat_id] = {
          chat_contents: firstChat.chat_contents,
          chat_id: firstChat.chat_id,
          project_id: firstChat.project_id,
        };
        state.currentChatId = firstChat.chat_id;
        state.activeChat = "agent";
      }
    }
  },
  [fetchProject.rejected]: (state, action) => {
    state.error = action.error.message;
    state.status = "failed";
    state.isProjectLoading = false;
  },
};
