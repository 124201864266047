import React, { useState, useEffect } from "react";
import "../style/PaymentModal.css";
import { useSelector, useDispatch } from "react-redux";
import { updateModal } from "../store/project.js";
import { useAuth0 } from "@auth0/auth0-react";
import {
  processSubscription,
  manageSubscription,
  updateSpendLimit,
} from "../api/payments_api.js";
import { updateUser } from "../store/user.js";

const PaymentModal = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.access_token);
  const userId = useSelector((state) => state.user.user_id);
  const [message, setMessage] = useState("");
  const currentTier = useSelector((state) => state.user.tier) || 0;
  const subscriptionId = useSelector(
    (state) => state.user.data.subscription_id
  );
  const { getAccessTokenSilently, user: userData } = useAuth0();
  const [isProcessing, setIsProcessing] = useState(false);
  const [usageBasedPricing, setUsageBasedPricing] = useState(false);
  const [monthlySpendLimit, setMonthlySpendLimit] = useState("100");
  const monthlySpendLimitValue =
    useSelector((state) => state.user.data.monthly_spend_limit) || 100;
  const allowMonthlySpendLimit =
    useSelector((state) => state.user.data.allow_monthly_spend_limit) || false;

  // Get monthly credits consumed from user data
  const monthlyCreditsConsumed = useSelector(
    (state) => state.user.data?.monthly_credits_consumed || 0
  );

  // Calculate maxUsage and currentUsage based on subscription status
  const currentUsage = parseInt(monthlyCreditsConsumed);
  const userCreatedAt = useSelector((state) => state.user.data?.created_at);
  const isInTrialPeriod = () => {
    if (!userCreatedAt) return false;
    const createdDate = new Date(userCreatedAt);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - createdDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 14;
  };

  const maxUsage = subscriptionId
    ? Math.max(currentUsage, 500)
    : isInTrialPeriod()
    ? 500
    : Math.max(currentUsage, 50);

  // Calculate current spend for subscribed users
  const currentSpend = subscriptionId
    ? (Math.max(0, currentUsage - 500) * 0.04).toFixed(2).padStart(4, "0")
    : 0;

  const currentPeriodStart = useSelector(
    (state) => state.user.data.current_period_start
  );
  const currentPeriodEnd = useSelector(
    (state) => state.user.data.current_period_end
  );

  const subscriptionCancelAtPeriodEnd = useSelector(
    (state) => state.user.data.subscription_cancel_at_period_end
  );

  // Add new state for trial period
  const getTrialEndDate = () => {
    if (!userCreatedAt) return null;
    const createdDate = new Date(userCreatedAt);
    createdDate.setDate(createdDate.getDate() + 14);
    return createdDate;
  };

  // Initialize state with values from Redux
  useEffect(() => {
    setUsageBasedPricing(allowMonthlySpendLimit);
    setMonthlySpendLimit(monthlySpendLimitValue.toString());
  }, [allowMonthlySpendLimit, monthlySpendLimitValue]);

  // Track if there are unsaved changes
  const parseIntSafe = (value) => {
    const parsed = parseInt(value);
    return !isNaN(parsed) ? parsed : 0;
  };

  const currentSpendLimit = parseIntSafe(monthlySpendLimit);
  const storedSpendLimit = parseIntSafe(monthlySpendLimitValue);

  const hasUnsavedChanges =
    usageBasedPricing !== allowMonthlySpendLimit ||
    currentSpendLimit !== storedSpendLimit;

  const handleClose = () => {
    dispatch(updateModal({ name: "", data: null }));
  };

  const handleSubscribe = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    setMessage("");

    try {
      const token = await getAccessTokenSilently();
      const data = await processSubscription(token, userId);

      if (data?.url) {
        window.location.href = data.url;
      } else {
        setMessage("Failed to start subscription. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleManageSubscription = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    setMessage("");

    try {
      const token = await getAccessTokenSilently();
      const data = await manageSubscription(token, userId);

      if (data?.url) {
        window.location.href = data.url;
      } else {
        setMessage(
          "Failed to access subscription management. Please try again."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Failed to access subscription management. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleToggleUsageBasedPricing = (checked) => {
    setUsageBasedPricing(checked);
    if (!checked) {
      setMonthlySpendLimit("0");
    }
    handleSpendLimitUpdate(checked ? parseInt(monthlySpendLimit) : 0, checked);
  };

  const handleSpendLimitChange = (e) => {
    const newValue = e.target.value;
    if (newValue >= 0) {
      setMonthlySpendLimit(newValue);
    }
  };

  const handleSpendLimitUpdate = async (newLimit, newAllowLimit) => {
    if (isProcessing) return;
    setIsProcessing(true);
    setMessage("");

    try {
      const token = await getAccessTokenSilently();
      const response = await updateSpendLimit(
        token,
        userId,
        newLimit,
        newAllowLimit
      );

      dispatch(
        updateUser({
          data: {
            monthly_spend_limit: newLimit,
            allow_monthly_spend_limit: newAllowLimit,
          },
        })
      );

      setMessage("Spend limit updated successfully");
    } catch (error) {
      console.error("Error:", error);
      setMessage("Failed to update spend limit. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSpendLimitBlur = () => {
    const parsedValue = parseInt(monthlySpendLimit);
    if (isNaN(parsedValue) || parsedValue < 0) {
      setMonthlySpendLimit(monthlySpendLimitValue.toString());
      setUsageBasedPricing(allowMonthlySpendLimit);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "";

    try {
      const date = new Date(
        /^\d+$/.test(timestamp) ? parseInt(timestamp) * 1000 : timestamp
      );

      if (isNaN(date.getTime())) {
        return "";
      }

      return new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const getUsageBoxTitle = () => {
    if (!subscriptionId && isInTrialPeriod()) {
      return `Usage (2 week free trial ending ${formatDate(
        getTrialEndDate()
      )})`;
    }

    const formattedStartDate = formatDate(currentPeriodStart);
    return formattedStartDate ? `Usage (since ${formattedStartDate})` : "Usage";
  };

  return (
    <div className="pm-overlay" onClick={handleClose}>
      <div className="pm-container" onClick={(e) => e.stopPropagation()}>
        <div className="pm-header">
          <h1>Settings</h1>
          <button className="pm-close" onClick={handleClose}>
            ×
          </button>
        </div>

        <h3 className="pm-subheader">
          You can manage your account, billing, and team settings here
        </h3>

        <div className="pm-content">
          <div className="pm-top-row">
            <div className="pm-profile-box">
              <img
                src={userData?.picture || "/images/default-user-icon.png"}
                alt="Profile"
                className="pm-profile-image"
              />
              <div className="pm-profile-info">
                <h4>{userData?.name}</h4>
                <p>{userData?.email}</p>
              </div>
            </div>

            <div className="pm-usage-box">
              <h3>{getUsageBoxTitle()}</h3>
              <div className="pm-progress-container">
                <div
                  className="pm-progress-bar"
                  style={{ width: `${(currentUsage / maxUsage) * 100}%` }}
                />
                <div style={{ height: "8px" }}></div>
                <span className="pm-progress-text">
                  {currentUsage} / {maxUsage} requests
                </span>
              </div>
              <p className="pm-usage-note">
                Note: L1 and L1-mini messages start as 1 request, while
                L1-reasoning messages start as 2 requests. Tool calls with the messages (the boxes) are counted as 1 request each. Generating cloning plans costs extra requests based on construct count: 1-8
                plans costs extra requests based on construct count: 1-8
                constructs (+1 request), 9-24 (+2 requests), 25-96 (+5
                requests), and 97-384 (+10 requests).
              </p>
            </div>
          </div>

          <div className="pm-bottom-row">
            <div className="pm-account-box">
              <h3>Account</h3>
              {!subscriptionId ? (
                <button
                  className="pm-button pm-primary"
                  onClick={handleSubscribe}
                  disabled={isProcessing}
                >
                  Subscribe
                </button>
              ) : (
                <div className="pm-subscription-controls">
                  <button
                    className="pm-button pm-primary"
                    onClick={handleManageSubscription}
                    disabled={isProcessing}
                  >
                    Manage Subscription
                  </button>
                  {currentPeriodEnd && (
                    <p className="pm-renewal-date">
                      {subscriptionCancelAtPeriodEnd
                        ? `Subscription is cancelled and will NOT renew on ${formatDate(
                            currentPeriodEnd
                          )}. Click Manage Subscription to resubscribe`
                        : `Subscription renews on ${formatDate(
                            currentPeriodEnd
                          )}`}
                    </p>
                  )}
                  {!subscriptionCancelAtPeriodEnd && (
                    <button
                      className="pm-cancel-link"
                      onClick={handleManageSubscription}
                      disabled={isProcessing}
                    >
                      Cancel subscription
                    </button>
                  )}
                </div>
              )}
            </div>

            <div className="pm-usage-settings">
              <div className="pm-toggle-container">
                <label
                  className={`pm-toggle ${
                    !subscriptionId ? "pm-disabled" : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    checked={usageBasedPricing}
                    onChange={(e) =>
                      handleToggleUsageBasedPricing(e.target.checked)
                    }
                    disabled={isProcessing || !subscriptionId}
                  />
                  <span className="pm-toggle-slider"></span>
                </label>
                <span>Enable usage-based pricing</span>
              </div>

              {!subscriptionId && (
                <p className="pm-upgrade-notice">
                  Usage-based pricing is only available for the pro tier
                </p>
              )}

              <div
                className={`pm-spend-limit ${
                  !subscriptionId ? "pm-disabled" : ""
                }`}
              >
                <label>Monthly spend limit</label>
                <div className="pm-input-group">
                  <span className="pm-currency">$</span>
                  <input
                    type="number"
                    value={monthlySpendLimit}
                    onChange={handleSpendLimitChange}
                    onBlur={handleSpendLimitBlur}
                    disabled={
                      !usageBasedPricing || isProcessing || !subscriptionId
                    }
                    min="0"
                    step="1"
                  />
                </div>
                <p className="pm-current-spend">
                  Current spend this month: ${currentSpend}
                </p>
              </div>

              {hasUnsavedChanges && (
                <button
                  className="pm-button pm-primary"
                  onClick={() =>
                    handleSpendLimitUpdate(
                      parseInt(monthlySpendLimit),
                      usageBasedPricing
                    )
                  }
                  disabled={isProcessing}
                >
                  Save Changes
                </button>
              )}
            </div>
          </div>
        </div>

        {message && (
          <div
            className={`pm-message ${
              message.includes("error") ? "pm-error" : "pm-success"
            }`}
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentModal;
