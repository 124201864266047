// Helper function to check if a tool call should be initially hidden
export const isToolCallHidden = (callId, functionName, hiddenToolCalls) => {
  // Create a new Set if hiddenToolCalls is undefined/null
  const toolCalls =
    hiddenToolCalls instanceof Set ? hiddenToolCalls : new Set();

  // If the call has been manually toggled, use the toggled state
  if (toolCalls.has(callId)) {
    return true;
  } else if (toolCalls.has(`shown_${callId}`)) {
    return false;
  }

  // Changed default behavior: show all tool calls by default
  return false;
};

// Mapping objects
export const fileIdentifierMapping = {
  configure_design: "design_name",
  analyze_design: "design_name",
  edit_dna_features: "filename",
  update_design_parts: "design_name",
  update_design_bins: "design_name",
  edit_dna_file: "filename",
  simulate_reaction: "filename",
  create_dna_file: "filename",
  get_information: "filename",
  read_lab_notebook: "filename",
  edit_lab_notebook: "filename",
  read_python_script: "filename",
  read_csv_file: "filename",
  edit_python_script: "filename",
  edit_file: "filename",
  simulate_digest: "parent_file",
  simulate_pcr: "parent_file",
  read_paper_sections: "filename",
  search_papers: "filenames",
};

export const extractFilename = (args) => {
  try {
    if (typeof args === "string") {
      args = JSON.parse(args);
    }

    // Get the function name from the context if available
    const functionName = args.function_name || args.tool_name;

    // Special handling for configure_design's nested structure
    if (functionName === "configure_design") {
      if (args.request?.design_name) {
        return args.request.design_name;
      }
      // Handle deeply nested request structure
      if (args.request?.request?.design_name) {
        return args.request.request.design_name;
      }
    }

    // Determine which key to use for the file identifier
    const fileKey = fileIdentifierMapping[functionName] || "filename";

    // First check the primary key based on the function mapping
    const primaryValue =
      args[fileKey] ||
      args.request?.[fileKey] ||
      args.request?.request?.[fileKey];

    if (primaryValue) {
      // Handle array of filenames (convert to comma-separated string)
      if (Array.isArray(primaryValue)) {
        return primaryValue.join(", ");
      }
      return primaryValue;
    }

    // If primary key doesn't have a value, try all possible paths to find the identifier
    const paths = [
      args.filename,
      args.design_name,
      args.parent_file,
      args.request?.parent_file,
      args.request?.request?.parent_file,
      args.request?.filename,
      args.request?.design_name,
      args.request?.request?.filename,
      args.request?.request?.design_name,
      // Additional paths for paper references
      args.paper_reference,
      args.request?.paper_reference,
      args.request?.request?.paper_reference,
      // Specifically handle filenames array
      args.filenames,
      args.request?.filenames,
      args.request?.request?.filenames,
    ];

    // Return the first valid path
    for (const path of paths) {
      if (path) {
        // Handle array of filenames (convert to comma-separated string)
        if (Array.isArray(path)) {
          return path.join(", ");
        }
        return path;
      }
    }
  } catch (e) {
    // If JSON parsing fails, try multiple regex patterns
    const patterns = [
      /"design_name":\s*"([^"]+)"/,
      /'design_name':\s*'([^']+)'/,
      /"filename":\s*"([^"]+)"/,
      /'filename':\s*'([^']+)'/,
      /design_name["']?\s*:\s*["']([^"']+)["']/i,
      /filename["']?\s*:\s*["']([^"']+)["']/i,
      /"filenames":\s*\[(.*?)\]/,
      /'filenames':\s*\[(.*?)\]/,
      /"paper_reference":\s*"([^"]+)"/,
      /'paper_reference':\s*'([^']+)'/,
    ];

    const text = typeof args === "string" ? args : JSON.stringify(args);

    for (const pattern of patterns) {
      const match = text.match(pattern);
      if (match) {
        // Handle array pattern for filenames
        if (pattern.toString().includes("filenames")) {
          try {
            // Try to parse the array content
            const arrayContent = `[${match[1]}]`;
            const fileArray = JSON.parse(arrayContent.replace(/'/g, '"'));
            return fileArray.join(", ");
          } catch (e) {
            // If parsing fails, return the raw match
            return match[1].replace(/"/g, "").replace(/'/g, "");
          }
        }
        return match[1];
      }
    }
  }
  return null;
};

export const getDisplayName = (functionName) => {
  if (!functionName) return "";
  return functionName
    .split("_")
    .map((word) => {
      if (word.toLowerCase() === "id") return "ID";
      if (word.toLowerCase() === "dna") return "DNA";
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const handleToggle = (
  callId,
  isCurrentlyHidden,
  hiddenToolCalls,
  setHiddenToolCalls
) => {
  // Create a new Set if hiddenToolCalls is undefined/null
  const toolCalls =
    hiddenToolCalls instanceof Set ? hiddenToolCalls : new Set();
  const newHiddenToolCalls = new Set(toolCalls);

  if (isCurrentlyHidden) {
    // Remove both possible states
    newHiddenToolCalls.delete(callId);
    newHiddenToolCalls.add(`shown_${callId}`);
  } else {
    // Remove shown state and add hidden state
    newHiddenToolCalls.delete(`shown_${callId}`);
    newHiddenToolCalls.add(callId);
  }
  setHiddenToolCalls(newHiddenToolCalls);
};

export const shouldShowFileActions = (
  filename,
  messages,
  messageIndex,
  currentProject,
  chatType
) => {
  if (!currentProject?.files) return false;

  // For agent chat type, keep existing behavior of showing actions on latest message
  if (chatType === "agent") {
    const aiMessages = messages.chat_contents.filter(
      (m) => m.role === "assistant"
    );
    const isLatestAiMessage = messageIndex === aiMessages.length - 1;
    if (!isLatestAiMessage) return false;
  }

  // Find the file ID where the filename matches
  const fileId = Object.keys(currentProject.files).find(
    (id) => currentProject.files[id].file_name === filename
  );

  if (!fileId) return false;

  const file = currentProject.files[fileId];

  // Check if there's an edit version
  const hasEdits = file.s3_url?.includes("_edit.");
  if (!hasEdits) return false;

  // For chat type, show actions on the message that was applied
  if (chatType === "chat") {
    return file.applied_message_index === messageIndex;
  }

  return true;
};
