import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const stopChatMessage = createAsyncThunk(
  "projects/stopChatMessage",
  async ({ projectId }, { getState }) => {
    const token = getState().user.access_token;

    console.log("StopChatMessage - Input params:", { projectId });

    try {
      const requestBody = {
        project_id: projectId,
      };

      const response = await fetchWithToken(
        `${API_URL}/api/stop-chat-message`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        },
        token
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("StopChatMessage - Server error response:", {
          status: response.status,
          statusText: response.statusText,
          errorText,
        });
        throw new Error(`Failed to stop chat message: ${errorText}`);
      }

      const result = await response.json();
      console.log("StopChatMessage - Server response:", result);

      if (result.status === "success") {
        return result.data;
      } else {
        throw new Error(result.message || "Failed to stop chat message");
      }
    } catch (error) {
      console.error("StopChatMessage - Error:", {
        name: error.name,
        message: error.message,
        stack: error.stack,
      });
      throw error;
    }
  }
);

export const stopChatMessageReducer = {
  [stopChatMessage.fulfilled]: (state, action) => {
    console.log("StopChatMessage reducer - Fulfilled:", action.payload);
    state.currentStreamedUpdate = null;
    state.streamProgress = null;
  },
  [stopChatMessage.pending]: (state, action) => {
    console.log("StopChatMessage reducer - Pending:", action.meta.arg);
    state.error = null;
  },
  [stopChatMessage.rejected]: (state, action) => {
    console.error("StopChatMessage reducer - Rejected:", {
      error: action.error.message,
      meta: action.meta,
    });
    state.error = action.error.message;
  },
};
