import { createSlice, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  editNotebookData,
  editNotebookDataReducer,
} from "./project_reducers/editNotebookData.js";

import {
  fetchProjects,
  fetchProjectsReducer,
} from "./project_reducers/fetchProjects.js";

import { fetchChat, fetchChatReducer } from "./project_reducers/fetchChat.js";

import {
  createNewProject,
  createNewProjectReducer,
} from "./project_reducers/createNewProject.js";

import {
  renameExistingProject,
  renameExistingProjectReducer,
} from "./project_reducers/renameExistingProject.js";

import {
  deleteExistingProject,
  deleteExistingProjectReducer,
} from "./project_reducers/deleteExistingProject.js";

import {
  deleteProjectFile,
  deleteProjectFileReducer,
} from "./project_reducers/deleteProjectFile.js";

import {
  renameFileGlobal,
  renameFileGlobalReducer,
} from "./project_reducers/renameFileGlobal.js";

import {
  sendChatMessage,
  sendChatMessageReducer,
} from "./project_reducers/sendChatMessage.js";

import {
  uploadFile,
  uploadFileReducer,
} from "./project_reducers/uploadFile.js";

import {
  addFileToProject,
  addFileToProjectReducer,
} from "./project_reducers/addFileToProject.js";

import {
  fetchArtifactData,
  fetchArtifactDataReducer,
} from "./project_reducers/fetchArtifactData.js";

import {
  editProtocolData,
  editProtocolDataReducer,
} from "./project_reducers/editProtocolData.js";

import {
  generateProjectInstructions,
  generateProjectInstructionsReducer,
} from "./project_reducers/generateProjectInstructions.js";

import {
  navigateDesign,
  navigateDesignReducer,
} from "./project_reducers/navigateDesign.js";

import {
  submitArtifactInputs,
  submitArtifactInputsReducer,
} from "./project_reducers/submitArtifactInputs.js";

import {
  getOrderData,
  getOrderDataReducer,
} from "./project_reducers/getOrderData.js";

import {
  submitOrder,
  submitOrderReducer,
} from "./project_reducers/submitOrder.js";

import { uploadNotebookImage } from "./project_reducers/uploadNotebookImage.js";

import {
  fetchNextProjects,
  fetchNextProjectsReducer,
} from "./project_reducers/fetchNextProjects.js";

import { loadProjectFromNotification } from "./project_reducers/loadProjectFromNotification.js";

import {
  manageCloningPlanAction,
  manageCloningPlanActionReducer,
} from "./project_reducers/manageCloningPlanAction.js";

import {
  manageChatAction,
  manageChatActionReducer,
} from "./project_reducers/manageChatAction.js";

import {
  manageEditableNotebookAction,
  manageEditableNotebookActionReducer,
} from "./project_reducers/manageEditableNotebookAction.js";

import {
  fetchNextChats,
  fetchNextChatsReducer,
} from "./project_reducers/fetchNextChats.js";

import {
  fetchProject,
  fetchProjectReducer,
} from "./project_reducers/fetchProject.js";

import {
  handleDiffChanges,
  handleDiffChangesReducer,
} from "./project_reducers/handleDiffChanges.js";

import {
  managePythonFile,
  managePythonFileReducer,
} from "./project_reducers/managePythonFile.js";

import {
  runPythonCode,
  runPythonCodeReducer,
} from "./project_reducers/runPythonCode.js";

import {
  applyToolCall,
  applyToolCallReducer,
} from "./project_reducers/applyToolCall.js";

import {
  stopChatMessage,
  stopChatMessageReducer,
} from "./project_reducers/stopChatMessage.js";

import {
  handleFileClick,
  handleFileClickReducer,
} from "./project_reducers/handleFileClick.js";

// File type icons mapping
export const FILE_TYPE_ICONS = {
  markdown: "📝",
  DNA: (
    <img
      src="/images/dna-archive-fix.svg"
      alt="DNA"
      style={{ width: "1em", height: "1em" }}
    />
  ),
  RNA: (
    <img
      src="/images/rna-archive-fix.svg"
      alt="RNA"
      style={{ width: "1em", height: "1em" }}
    />
  ),
  Protein: (
    <img
      src="/images/protein.svg"
      alt="Protein"
      style={{ width: "1em", height: "1em" }}
    />
  ),
  Design: (
    <img
      src="/images/cloning-plan.svg"
      alt="Design"
      style={{ width: "1em", height: "1em" }}
    />
  ),
  protocol: "📋",
  Output: (
    <img
      src="/images/structured-protocol.svg"
      alt="Output"
      style={{ width: "1em", height: "1em" }}
    />
  ),
  chat: "💬",
  Paper: (
    <img
      src="/images/paper.svg"
      alt="Paper"
      style={{ width: "1em", height: "1em" }}
    />
  ),
  Web: (
    <img
      src="/images/web.svg"
      alt="Web"
      style={{ width: "1em", height: "1em" }}
    />
  ),
  Notebook: (
    <img
      src="/images/protocol.svg"
      alt="Protocol"
      style={{ width: "1em", height: "1em" }}
    />
  ),
  Python: (
    <img
      src="/images/python.svg"
      alt="Python"
      style={{ width: "1em", height: "1em" }}
    />
  ),
  Data: (
    <img
      src="/images/data.svg"
      alt="Data"
      style={{ width: "1em", height: "1em" }}
    />
  ),
  default: "📄",
};

export {
  editNotebookData,
  fetchProjects,
  fetchChat,
  createNewProject,
  renameExistingProject,
  deleteExistingProject,
  deleteProjectFile,
  renameFileGlobal,
  sendChatMessage,
  uploadFile,
  addFileToProject,
  fetchArtifactData,
  editProtocolData,
  generateProjectInstructions,
  navigateDesign,
  submitArtifactInputs,
  getOrderData,
  submitOrder,
  uploadNotebookImage,
  fetchNextProjects,
  loadProjectFromNotification,
  manageCloningPlanAction,
  manageChatAction,
  manageEditableNotebookAction,
  fetchNextChats,
  fetchProject,
  handleDiffChanges,
  managePythonFile,
  runPythonCode,
  applyToolCall,
  stopChatMessage,
  handleFileClick,
};

// Helper function to convert files_open list to openFiles structure
export const convertFilesOpenToOpenFiles = (
  filesOpen = [],
  projectFiles = {}
) => {
  // Add type checking and conversion
  if (!Array.isArray(filesOpen)) {
    // If it's an object, convert to array of keys
    if (typeof filesOpen === "object" && filesOpen !== null) {
      filesOpen = Object.keys(filesOpen);
    } else {
      // If neither array nor object, return empty object
      return {};
    }
  }

  return filesOpen.reduce((acc, fileId, index) => {
    const file = projectFiles[fileId];
    if (file) {
      acc[fileId] = {
        id: fileId,
        name: file.file_name,
        type: file.file_type,
        content: null,
        order: index,
      };
    }
    return acc;
  }, {});
};

export const clearChat = createAction("projects/clearChat");

// Add to your action types
export const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT";

// Project slice
const initialState = {
  projectList: [],
  current: null,
  currentProject: null,
  chat: {},
  isLoading: false,
  isProjectLoading: false,
  isChatLoading: false,
  isMessageSending: false,
  error: null,
  artifact: null,
  artifactType: null,
  modal: { name: "", data: null },
  isSidebarVisible: true,
  design: null,
  loadingMessage: "",
  sendMessageError: null,
  isGeneratingInstructions: false,
  generateInstructionsError: null,
  orderData: null,
  status: "idle",
  designUsageCredits: null,
  shouldOpenGenerateInstructions: false,
  projectsNextToken: null,
  tools_file_ids: [],
  isProjectInitialized: false,
  initializedProjects: {},
  currentSequence: null,
  streamProgress: null,
  currentStreamedUpdate: null,
  currentPopupStreamedUpdate: null,
  activeChat: "agent",
  currentChatId: null,
  lastStreamedMessage: null,
  isChatPanelOpen: true,
  openFiles: {}, // Will be populated from files_open list
  activeFileId: null,
  selectedModel: "L1",
  localFilePills: [],
  popUpChatMessages: [], // Add this for ephemeral messages
  isPopupChatOpen: false,
  isLoadingMoreChats: false,
  sequenceViewerSelection: null, // {start: number, end: number, length: number}
  notebookHighlightedText: null, // { text: string, fullText: string, start: number, end: number, type: "notebook" | "pdf" }
  sequenceViewerType: null, // Add this to track the viewer type
  editorNeedsRemount: false,
  isRunningPython: false,
  pythonOutput: {
    stdout: "",
    error: "",
  },
  isChatBlockApplying: false,
  applyingChatBlock: null, // Will store {filename: string, messageIndex: number} or null
  currentStreamedToolCalls: [], // Add this new field
  applyingToolCall: null, // Will store {filename: string, request: object} or null
};

const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    updateModal(state, action) {
      console.log("Updating modal:", action.payload);
      state.modal = action.payload;
    },
    closeModal(state) {
      console.log("Closing modal");
      state.modal = { name: "", data: null };
    },
    updateProjectWithNewFile(state, action) {
      const { projectId, files } = action.payload;
      state.projectList = state.projectList.map((p) => {
        if (p.project_id === projectId) {
          return { ...p, files };
        }
        return p;
      });
    },
    setCurrentProjectId(state, action) {
      state.currentProjectId = action.payload;
      // Only update current project from existing data
      state.currentProject = state.projectList.find(
        (project) => project.project_id === action.payload
      );

      // Update openFiles from files_open list if we have the project data
      if (state.currentProject) {
        state.openFiles = convertFilesOpenToOpenFiles(
          state.currentProject.files_open,
          state.currentProject.files
        );
        // Set activeFileId to first file if there are open files
        const openFileIds = Object.keys(state.openFiles);
        state.activeFileId = openFileIds.length > 0 ? openFileIds[0] : null;
      } else {
        state.openFiles = {};
        state.activeFileId = null;
      }

      // Clear chat state when switching projects
      state.chat = {};
      state.currentChatId = null;
    },
    setIsProjectLoading(state, action) {
      state.isProjectLoading = action.payload;
    },
    updateArtifact(state, action) {
      state.artifact = action.payload;
    },
    updateProjectList(state, action) {
      const sortedProjects = [...action.payload].sort((a, b) => {
        const dateA = new Date(a.last_opened_date || 0);
        const dateB = new Date(b.last_opened_date || 0);
        return dateB - dateA;
      });
      state.projectList = sortedProjects;
    },
    updateChat: (state, action) => {
      const { chat_id, project_id, chat_contents } = action.payload;

      // Initialize the chat if it doesn't exist
      if (!state.chat[chat_id]) {
        state.chat[chat_id] = {
          chat_id: chat_id,
          project_id: project_id,
          chat_contents: [],
        };
      }

      // Update the chat contents
      state.chat[chat_id].chat_contents = chat_contents;
      state.currentProject.agent_chat_files[chat_id] = chat_contents;
      // Set as current chat
      state.currentChatId = chat_id;
    },
    resetChat(state, action) {
      const projectId = action.payload;
      state.chat = {}; // Clear all chat data
      state.currentChatId = null; // Reset current chat ID
    },
    toggleSidebar(state) {
      state.isSidebarVisible = !state.isSidebarVisible;
    },
    setSidebarVisibility(state, action) {
      state.isSidebarVisible = action.payload;
    },
    addUserMessage: (state, action) => {
      const { projectId, message, chatId: payloadChatId } = action.payload;
      const chatId = payloadChatId || state.currentChatId || projectId;

      // Create a temporary chat ID if none exists
      if (!state.chat[chatId]) {
        state.chat[chatId] = {
          chat_id: chatId,
          project_id: projectId,
          chat_contents: [],
        };
      }

      // Add the message to the chat contents
      state.chat[chatId].chat_contents.push({
        content: message,
        file_upload: null,
        role: "user",
        timestamp: new Date().toISOString(),
      });
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsChatLoading: (state, action) => {
      state.isChatLoading = action.payload;
    },
    setLoadingMessage: (state, action) => {
      state.loadingMessage = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSendMessageError: (state, action) => {
      state.sendMessageError = action.payload;
    },
    clearSendMessageError: (state) => {
      state.sendMessageError = null;
    },
    setIsGeneratingInstructions: (state, action) => {
      state.isGeneratingInstructions = action.payload;
    },
    setGenerateInstructionsError: (state, action) => {
      state.generateInstructionsError = action.payload;
    },
    clearGenerateInstructionsError: (state) => {
      state.generateInstructionsError = null;
    },
    updateProject(state, action) {
      const updatedProject = action.payload;
      // Deduplicate files_open list
      if (updatedProject.files_open) {
        updatedProject.files_open = [...new Set(updatedProject.files_open)];
      }

      // Compute inEditState by checking file s3_urls
      updatedProject.inEditState = Object.values(
        updatedProject.files || {}
      ).some((file) => file.s3_url && file.s3_url.includes("_edit."));

      state.projectList = state.projectList.map((project) =>
        project.project_id === updatedProject.project_id
          ? updatedProject
          : project
      );
      if (state.currentProjectId === updatedProject.project_id) {
        state.currentProject = updatedProject;
        // Update openFiles from files_open list
        const filesOpen = updatedProject.files_open || [];
        state.openFiles = convertFilesOpenToOpenFiles(
          filesOpen,
          updatedProject.files
        );
      }
    },
    setProjectFiles(state, action) {
      const { projectId, files } = action.payload;

      // Update files in projectList
      state.projectList = state.projectList.map((project) => {
        if (project.project_id === projectId) {
          return {
            ...project,
            files,
            // Compute inEditState by checking file s3_urls
            inEditState: Object.values(files || {}).some(
              (file) => file.s3_url && file.s3_url.includes("_edit.")
            ),
          };
        }
        return project;
      });

      // Update current project if it matches
      if (state.currentProjectId === projectId) {
        state.currentProject = {
          ...state.currentProject,
          files,
          // Compute inEditState by checking file s3_urls
          inEditState: Object.values(files || {}).some(
            (file) => file.s3_url && file.s3_url.includes("_edit.")
          ),
        };

        // Update openFiles from files_open list with new files
        const filesOpen = state.currentProject.files_open || [];
        state.openFiles = convertFilesOpenToOpenFiles(filesOpen, files);
      }
    },
    setShouldOpenGenerateInstructions: (state, action) => {
      state.shouldOpenGenerateInstructions = action.payload;
    },
    appendProjects(state, action) {
      state.projectList = [...state.projectList, ...action.payload.projects];
      state.projectsNextToken = action.payload.nextToken;
    },
    setCurrentStreamedUpdate: (state, action) => {
      state.currentStreamedUpdate = action.payload;
    },
    setCurrentPopupStreamedUpdate: (state, action) => {
      state.currentPopupStreamedUpdate = action.payload;
    },
    setStreamProgress: (state, action) => {
      state.streamProgress = action.payload;
    },
    setIsLoadingFiles: (state, action) => {
      state.isLoadingFiles = action.payload;
    },
    addToolsFileIds: (state, action) => {
      // Expect payload to be array of { id, name }
      const newFiles = action.payload.filter(
        (file) =>
          !state.tools_file_ids.some((existing) => existing.id === file.id)
      );
      state.tools_file_ids = [...state.tools_file_ids, ...newFiles];
    },
    removeToolFile: (state, action) => {
      state.tools_file_ids = state.tools_file_ids.filter(
        (file) => file.id !== action.payload
      );
    },
    setProjectInitialized: (state, action) => {
      state.isProjectInitialized = true;
    },
    setProjectFullyLoaded: (state, action) => {
      const projectId = action.payload;
      state.initializedProjects[projectId] = true;
    },
    setCurrentSequence(state, action) {
      state.currentSequence = action.payload;
    },
    setArtifactType: (state, action) => {
      state.artifactType = action.payload;
    },
    setActiveChat: (state, action) => {
      const chatType = action.payload;
      state.activeChat = chatType;

      // Clear current chat when switching types
      state.chat = {};
      state.currentChatId = null;
    },
    setIsMessageSending: (state, action) => {
      state.isMessageSending = action.payload;
    },
    setChatPanelOpen: (state, action) => {
      state.isChatPanelOpen = action.payload;
    },
    addFileTab: (state, action) => {
      const {
        fileId,
        fileName,
        fileType,
        content,
        setActive = false,
      } = action.payload;
      // Get the maximum order value and add 1, or use 0 if no files exist
      const order =
        Object.values(state.openFiles).reduce(
          (max, file) => Math.max(max, file.order),
          -1
        ) + 1;

      // Add to openFiles
      state.openFiles[fileId] = {
        id: fileId,
        name: fileName,
        type: fileType,
        content,
        order,
      };

      // Only set active if explicitly requested
      if (setActive) {
        state.activeFileId = fileId;
      }

      // Update files_open in currentProject to match
      if (state.currentProject) {
        state.currentProject.files_open = Object.keys(state.openFiles);
      }
    },
    removeFileTab: (state, action) => {
      const fileId = action.payload;
      const wasActive = state.activeFileId === fileId;

      // Get remaining files sorted by order
      const remainingFiles = Object.entries(state.openFiles)
        .filter(([id]) => id !== fileId)
        .sort((a, b) => a[1].order - b[1].order);

      // Create new openFiles object with sequential orders
      const newOpenFiles = {};
      remainingFiles.forEach(([id, file], index) => {
        newOpenFiles[id] = {
          ...file,
          order: index, // Ensure sequential ordering
        };
      });

      state.openFiles = newOpenFiles;

      // Update files_open in currentProject to match
      if (state.currentProject) {
        state.currentProject.files_open = Object.keys(newOpenFiles);
      }

      // If the closed tab was active, activate the next available tab
      if (wasActive && remainingFiles.length > 0) {
        state.activeFileId = remainingFiles[remainingFiles.length - 1][0];
      } else if (remainingFiles.length === 0) {
        state.activeFileId = null;
      }
    },
    setActiveFileTab: (state, action) => {
      state.activeFileId = action.payload;
    },
    updateFileTabContent: (state, action) => {
      const { fileId, content } = action.payload;
      if (state.openFiles[fileId]) {
        state.openFiles[fileId].content = content;
      }
    },
    updateOpenFiles: (state, action) => {
      state.openFiles = action.payload;

      // Update files_open in currentProject to match
      if (state.currentProject) {
        state.currentProject.files_open = Object.keys(action.payload);
      }
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
    setLocalFilePills: (state, action) => {
      state.localFilePills = action.payload;
    },
    updatePopUpMessages: (state, action) => {
      const lastMsg =
        state.popUpChatMessages[state.popUpChatMessages.length - 1];
      const chunkText = action.payload;

      if (lastMsg && lastMsg.role === "assistant") {
        // Update existing assistant message with new chunk
        lastMsg.content = chunkText;
      } else {
        // Add new assistant message
        state.popUpChatMessages.push({
          role: "assistant",
          content: chunkText,
          timestamp: new Date().toISOString(),
        });
      }
    },
    finishPopUpMessages: (state, action) => {
      const lastMsg =
        state.popUpChatMessages[state.popUpChatMessages.length - 1];
      const finalText = action.payload;

      if (lastMsg && lastMsg.role === "assistant") {
        // Update existing assistant message with final content
        lastMsg.content = finalText;
        lastMsg.isComplete = true;
      } else {
        // Add new completed assistant message
        state.popUpChatMessages.push({
          role: "assistant",
          content: finalText,
          timestamp: new Date().toISOString(),
          isComplete: true,
        });
      }
    },
    clearPopUpMessages: (state) => {
      state.popUpChatMessages = [];
    },
    addPopUpUserMessage: (state, action) => {
      state.popUpChatMessages.push({
        role: "user",
        content: action.payload,
        timestamp: new Date().toISOString(),
      });
    },
    setPopupChatOpen: (state, action) => {
      state.isPopupChatOpen = action.payload;
    },
    setSequenceViewerSelection: (state, action) => {
      state.sequenceViewerSelection = action.payload;
    },
    setNotebookHighlightedText: (state, action) => {
      state.notebookHighlightedText = action.payload;
    },
    setSequenceViewerType: (state, action) => {
      state.sequenceViewerType = action.payload;
    },
    setEditorNeedsRemount: (state, action) => {
      state.editorNeedsRemount = action.payload;
    },
    setIsChatBlockApplying: (state, action) => {
      state.isChatBlockApplying = action.payload;
    },
    setApplyingChatBlock: (state, action) => {
      state.applyingChatBlock = action.payload;
    },
    resetPythonOutput: (state, action) => {
      state.pythonOutput = action.payload;
      state.isRunningPython = false;
    },
    setCurrentStreamedToolCalls: (state, action) => {
      state.currentStreamedToolCalls = action.payload;
    },
    updateProjectChatFiles: (state, action) => {
      const { projectId, agent_chat_files, chat_chat_files, popup_chat_files } =
        action.payload;

      // Find the project in both lists
      const projectInList = state.projectList.find(
        (p) => p.project_id === projectId
      );
      if (projectInList) {
        if (agent_chat_files) projectInList.agent_chat_files = agent_chat_files;
        if (chat_chat_files) projectInList.chat_chat_files = chat_chat_files;
        if (popup_chat_files) projectInList.popup_chat_files = popup_chat_files;
      }

      // Update current project if it matches
      if (state.currentProject?.project_id === projectId) {
        if (agent_chat_files)
          state.currentProject.agent_chat_files = agent_chat_files;
        if (chat_chat_files)
          state.currentProject.chat_chat_files = chat_chat_files;
        if (popup_chat_files)
          state.currentProject.popup_chat_files = popup_chat_files;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SET_ACTIVE_CHAT, (state, action) => {
        state.activeChat = action.payload;
      })
      .addCase(addUserMessage, (state, action) => {
        const { projectId, message, chatId } = action.payload;
        const targetChatId = chatId || projectId;

        // First, if there's a stored streamed message, add it to the chat
        if (state.lastStreamedMessage) {
          if (!state.chat[state.lastStreamedMessage.chatId]) {
            state.chat[state.lastStreamedMessage.chatId] = {
              chat_id: state.lastStreamedMessage.chatId,
              project_id: projectId,
              chat_contents: [],
            };
          }

          // Add the stored streamed message
          state.chat[state.lastStreamedMessage.chatId].chat_contents.push({
            content: state.lastStreamedMessage.content,
            file_upload: null,
            role: "assistant",
            timestamp: state.lastStreamedMessage.timestamp,
          });

          // Clear the stored message
          state.lastStreamedMessage = null;
        }

        // Initialize chat if it doesn't exist
        if (!state.chat[targetChatId]) {
          state.chat[targetChatId] = {
            chat_id: targetChatId,
            project_id: projectId,
            chat_contents: [],
          };
        }

        // Add the new user message
        state.chat[targetChatId].chat_contents.push({
          content: message,
          file_upload: null,
          role: "user",
          timestamp: new Date().toISOString(),
        });
      });

    Object.entries(editNotebookDataReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(fetchProjectsReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(fetchChatReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(createNewProjectReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(renameExistingProjectReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(deleteExistingProjectReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(deleteProjectFileReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(renameFileGlobalReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(sendChatMessageReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(uploadFileReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(addFileToProjectReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(fetchArtifactDataReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(editProtocolDataReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(generateProjectInstructionsReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(navigateDesignReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(submitArtifactInputsReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(getOrderDataReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(submitOrderReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(fetchNextProjectsReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(manageCloningPlanActionReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(manageChatActionReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(manageEditableNotebookActionReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(fetchNextChatsReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(fetchProjectReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(handleDiffChangesReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(managePythonFileReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(runPythonCodeReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(applyToolCallReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(stopChatMessageReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(handleFileClickReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
  },
});

export const {
  setCurrentProjectId,
  updateChat,
  resetChat,
  updateProjectList,
  updateArtifact,
  updateProjectWithNewFile,
  updateModal,
  closeModal,
  toggleSidebar,
  setSidebarVisibility,
  addUserMessage,
  setIsLoading,
  setIsChatLoading,
  setCurrentStreamedUpdate,
  setCurrentPopupStreamedUpdate,
  setStreamProgress,
  setIsLoadingFiles,
  setLoadingMessage,
  setError,
  setSendMessageError,
  clearSendMessageError,
  setIsGeneratingInstructions,
  setGenerateInstructionsError,
  clearGenerateInstructionsError,
  updateProject,
  setIsProjectLoading,
  setIsPopupChatLoading,
  setShouldOpenGenerateInstructions,
  appendProjects,
  addToolsFileIds,
  removeToolFile,
  setProjectInitialized,
  setProjectFullyLoaded,
  setCurrentSequence,
  setArtifactType,
  setActiveChat,
  setIsMessageSending,
  setChatPanelOpen,
  addFileTab,
  removeFileTab,
  setActiveFileTab,
  updateFileTabContent,
  updateOpenFiles,
  setSelectedModel,
  setLocalFilePills,
  updatePopUpMessages,
  finishPopUpMessages,
  clearPopUpMessages,
  addPopUpUserMessage,
  setPopupChatOpen,
  setSequenceViewerSelection,
  setNotebookHighlightedText,
  setSequenceViewerType,
  setEditorNeedsRemount,
  setIsChatBlockApplying,
  setApplyingChatBlock,
  resetPythonOutput,
  setCurrentStreamedToolCalls,
  setProjectFiles,
  updateProjectChatFiles,
} = projectSlice.actions;

export default projectSlice.reducer;
