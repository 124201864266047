import { createAsyncThunk } from "@reduxjs/toolkit";
import { addFileTab, setActiveFileTab, fetchArtifactData } from "../project.js";
import { updateFilesOpen } from "../../api/files_api.js";

export const handleFileClick = createAsyncThunk(
  "projects/handleFileClick",
  async ({ fileData }, { getState, dispatch }) => {
    const fileId = fileData.uuid;
    const fileName = fileData.name;
    const fileType = fileData.file_type;
    const state = getState();
    const openFiles = state.project.openFiles;
    const currentProjectId = state.project.currentProjectId;
    const userId = state.user.user_id;
    const token = state.user.access_token;

    // Check if file is already open
    if (openFiles[fileId]) {
      // If file is already open, just activate it without changing order
      dispatch(setActiveFileTab(fileId));
      console.log("fetching artifact data for fileId in handleFileClick already open:", fileId);
      dispatch(fetchArtifactData({ fileId }));
      return;
    }

    // Only add new tab if file isn't already open
    dispatch(
      addFileTab({
        fileId,
        fileName,
        fileType,
        content: null,
        setActive: true,
      })
    );

    // Update files_open in backend
    if (currentProjectId && userId && token) {
      const updatedFileIds = [...Object.keys(openFiles), fileId];
      await updateFilesOpen(
        currentProjectId,
        updatedFileIds,
        userId,
        token
      ).catch((error) => console.error("Failed to update files open:", error));
    }

    // Then fetch the artifact data
    console.log("fetching artifact data for fileId in handleFileClick:", fileId);
    dispatch(
      fetchArtifactData({
        fileId,
        fileType,
      })
    );
  }
);

export const handleFileClickReducer = {
  [handleFileClick.fulfilled]: (state, action) => {
    // State updates are handled by the dispatched actions
  },
};
