import React, { useState, useRef, useEffect } from "react";
import { BiFolderOpen, BiFolder, BiFile } from "react-icons/bi";
import "../style/Sidebar.css";
import FileExplorerModal from "./FileExplorerModal.js";
import UserPreferencesModal from "./UserPreferencesModal.js";
import { useDispatch, useSelector } from "react-redux";
import {
  updateArtifact,
  updateModal,
  toggleSidebar,
  fetchProjects,
  resetChat,
  fetchArtifactData,
  setIsProjectLoading,
  fetchNextProjects,
  setProjectInitialized,
  setProjectFullyLoaded,
  addFileTab,
  FILE_TYPE_ICONS,
  setActiveFileTab,
  deleteProjectFile,
} from "../store/project.js";
import { downloadFiles, fetchProtocols } from "../api/files_api.js";
import { useAuth0 } from "@auth0/auth0-react";
import {
  updateAccessToken,
  fetchUser,
  fetchUserCredits,
  setUserStatus,
  setPopupMessage,
  setInitialized,
  deleteFileFromLibrary,
} from "../store/user.js";
import PaymentModal from "./PaymentModal.js"; // Add this import
import GroupManagementModal from "./GroupManagementModal.js";
import CloningPlanModal from "./CloningPlanModal.js";
import EditableNotebookModal from "./EditableNotebookModal.js";
import PythonModal from "./PythonModal.js";
import { fetchProject } from "../store/project_reducers/fetchProject.js";
import { handleFileClick } from "../store/project.js";
// import sidebarIcon from "../icons/sidebar.svg";

function Sidebar() {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [openFolders, setOpenFolders] = useState({});
  const [projectFiles, setProjectFiles] = useState({});

  const dropdownRef = useRef(null);
  const projectItemRef = useRef(null);
  const userDropdownRef = useRef(null);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.access_token);

  const isVisible = useSelector((state) => state.project.isSidebarVisible);
  const user_id = useSelector((state) => state.user.user_id);
  const usageCredits = useSelector((state) => state.user.usageCredits);
  const isInitialized = useSelector((state) => state.user.isInitialized);

  const { projectList, currentProjectId, modal, design, currentProject } =
    useSelector((state) => state.project);

  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  const [hasFetchedProjects, setHasFetchedProjects] = useState(false);

  const chat = useSelector((state) => state.project.chat);

  const projectsNextToken = useSelector(
    (state) => state.project.projectsNextToken
  );

  const isProjectInitialized = useSelector(
    (state) => state.project.isProjectInitialized
  );
  const initializedProjects = useSelector(
    (state) => state.project.initializedProjects
  );

  const [dropdownPosition, setDropdownPosition] = useState(null);

  const openFiles = useSelector((state) => state.project.openFiles);
  const userId = useSelector((state) => state.user.user_id);

  const subscriptionId = useSelector(
    (state) => state.user.data?.subscription_id
  );

  const [deletingFile, setDeletingFile] = useState(null);
  const [isDeletingInProgress, setIsDeletingInProgress] = useState(false);

  const handleProjectClick = (event, projectId) => {
    if (!event.target.closest(".project-menu-icon")) {
      dispatch(setIsProjectLoading(true));
      dispatch(fetchProject(projectId));
    }
  };

  const toggleDropdown = (event, projectId) => {
    event.stopPropagation(); // Prevent project selection
    if (activeDropdown === projectId) {
      setActiveDropdown(null);
    } else {
      const rect = event.currentTarget.getBoundingClientRect();
      const dropdown = document.createElement("div");
      dropdown.style.position = "fixed";
      dropdown.style.top = `${rect.bottom}px`;
      dropdown.style.left = `${rect.right}px`;
      document.body.appendChild(dropdown);
      const dropdownRect = dropdown.getBoundingClientRect();
      document.body.removeChild(dropdown);

      // Store the position for the dropdown
      setDropdownPosition({
        top: rect.bottom,
        right: window.innerWidth - rect.right,
      });
      setActiveDropdown(projectId);
    }
  };

  // Handle Dropdown for Rename / Open Modal
  const handleProjectRename = (event, projectId) => {
    event.stopPropagation(); // Prevent project selection
    const projectToRename = projectList.find((p) => p.project_id === projectId);
    dispatch(updateModal({ name: "rename_project", data: projectToRename }));
    setActiveDropdown(null);
  };

  // Handle Dropdown for Delete / Open Modal
  const handleDeleteProject = (event, projectId) => {
    event.stopPropagation(); // Prevent project selection
    dispatch(
      updateModal({
        name: "delete_project",
        data: projectList.find((p) => p.project_id === projectId),
      })
    );
    setActiveDropdown(null);
  };

  // Handle Dropdown for Delete File / Open Modal
  const handleFileDelete = (event, fileData) => {
    event.stopPropagation();

    // Check if the file has a parent_file_id and should be deleted globally
    if (fileData.parent_file_id) {
      // Get the parent file
      const parentFile = projectList.find(
        (p) => p.project_id === currentProjectId
      )?.files?.[fileData.parent_file_id];

      // If parent is a Design file (cloning plan), show delete confirmation modal
      if (parentFile && parentFile.file_type === "Design") {
        setDeletingFile(fileData);
        return;
      }
    }

    // For other files, proceed with the regular modal-based deletion
    dispatch(updateModal({ name: "delete_file", data: fileData }));
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsDeletingInProgress(true);

      // Delete from project first
      await dispatch(
        deleteProjectFile({
          projectId: currentProjectId,
          fileId: deletingFile.uuid,
        })
      ).unwrap();

      // Then delete globally from library
      await dispatch(
        deleteFileFromLibrary({
          fileIds: [deletingFile.uuid],
          fileNames: [deletingFile.name || deletingFile.file_name],
          userId: user_id,
        })
      ).unwrap();

      setIsDeletingInProgress(false);
      setDeletingFile(null);
    } catch (error) {
      console.error("Error deleting file:", error);
      setIsDeletingInProgress(false);
      setDeletingFile(null);
    }
  };

  const handleProjectDownload = (event, projectId) => {
    event.stopPropagation();
    downloadFiles(projectId, "project", token);
    setActiveDropdown(null);
  };

  const handleProtocolExport = async (event, projectId) => {
    event.stopPropagation();
    try {
      // Get the current project from Redux store
      const currentProject = projectList.find(
        (p) => p.project_id === projectId
      );
      if (!currentProject) {
        throw new Error("Project not found");
      }

      // Filter for markdown files in the outputs
      const protocolFiles = Object.entries(currentProject.files || {})
        .filter(
          ([_, file]) =>
            file.file_type === "Output" && file.file_name.endsWith(".md")
        )
        .map(([fileId, file]) => ({
          fileId,
          fileName: file.file_name,
        }))
        .sort((a, b) => a.fileName.localeCompare(b.fileName));

      const content = await fetchProtocols(protocolFiles, token);

      // Create file download
      const blob = new Blob([content], { type: "text/markdown" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "protocols.md";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      // Copy to clipboard
      await navigator.clipboard.writeText(content);
      dispatch(setPopupMessage("Protocols exported and copied to clipboard"));
    } catch (error) {
      console.error("Error exporting protocols:", error);
      dispatch(setPopupMessage("Failed to export protocols"));
    }
    setActiveDropdown(null);
  };

  const toggleFolder = (folderId) => {
    setOpenFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId],
    }));
  };

  const renderProjectFiles = (projectId) => {
    if (projectId !== currentProjectId) return null;
    const files = projectFiles[projectId] || [];

    return (
      <ul className="file-tree">
        <div className="file-tree-content">{renderFileTree(files)}</div>
      </ul>
    );
  };

  const renderFileTree = (items) => {
    return items
      .map((item) => {
        if (item.type === "file") {
          let displayName = item.name;

          // For Chat files, use chat_name if available
          if (item.file_type === "Chat" && item.chat_name) {
            displayName = item.chat_name;
          }
          // Remove the condition that removes .csv extension
          // Keep all extensions visible now

          // Check if file is in Outputs or Constructs folder
          const isProtectedFile = item.file_type !== "DNA";

          // Check if the file is in edit state
          const isEditState = item.s3_url && item.s3_url.includes("_edit.");

          return (
            <li
              key={item.uuid}
              className="file-item"
              onClick={(e) => dispatch(handleFileClick({ fileData: item }))}
              title={displayName}
              role="menuitem"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  dispatch(handleFileClick({ fileData: item }));
                }
              }}
              aria-label={`File: ${displayName}`}
            >
              <span className="file-item-icon">
                {FILE_TYPE_ICONS[item.file_type] || FILE_TYPE_ICONS.default}
              </span>
              <span
                className={`file-item-name ${
                  isEditState ? "file-edit-state" : ""
                }`}
              >
                {displayName}
              </span>
              <img
                src="/images/three-dots.svg"
                alt="Menu"
                className="file-menu-icon"
                onClick={(e) => toggleDropdown(e, item.uuid)}
              />
              {activeDropdown === item.uuid && (
                <div
                  ref={dropdownRef}
                  className="file-dropdown"
                  onMouseLeave={() => setActiveDropdown(null)}
                >
                  {/* Add rename option for DNA, Paper, Notebook, Python and Plan files */}
                  {(item.file_type === "DNA" ||
                    item.file_type === "Paper" ||
                    item.file_type === "Notebook" ||
                    item.file_type === "Python" ||
                    (item.file_type === "Design" &&
                      item.name.endsWith(".plan"))) && (
                    <button
                      className="dropdown-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                          updateModal({
                            name:
                              item.file_type === "Notebook"
                                ? "editable_notebook"
                                : item.file_type === "Design"
                                ? "cloning_plan"
                                : item.file_type === "Python"
                                ? "python"
                                : "user_file",
                            data: {
                              mode: "rename",
                              ...(item.file_type === "Notebook"
                                ? {
                                    notebookId: item.uuid,
                                    notebookName: item.name,
                                  }
                                : item.file_type === "Design"
                                ? {
                                    planId: item.uuid,
                                    planName: item.name.slice(0, -5), // Remove .plan extension
                                  }
                                : item.file_type === "Python"
                                ? {
                                    pythonId: item.uuid,
                                    pythonName: item.name,
                                  }
                                : {
                                    fileId: item.uuid,
                                    fileName: item.name,
                                  }),
                            },
                          })
                        );
                        setActiveDropdown(null);
                      }}
                    >
                      <span>Rename</span>
                      <img
                        src="/images/pencil.svg"
                        alt="Rename"
                        className="dropdown-icon"
                      />
                    </button>
                  )}
                  {/* Allow removal for all files except outputs */}
                  {item.file_type !== "Output" && (
                    <button
                      className="dropdown-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFileDelete(e, item);
                      }}
                    >
                      <span>Remove</span>
                      <img
                        src="/images/trash.svg"
                        alt="Remove"
                        className="dropdown-icon"
                      />
                    </button>
                  )}
                  <button
                    className="dropdown-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadFiles(item.uuid, "file", token);
                      setActiveDropdown(null);
                    }}
                  >
                    <span>Download</span>
                    <img
                      src="/images/download.svg"
                      alt="Download"
                      className="dropdown-icon"
                    />
                  </button>
                </div>
              )}
            </li>
          );
        } else if (item.type === "folder") {
          const isOpen = openFolders[item.id];
          return (
            <React.Fragment key={item.id}>
              <li
                className="folder-item"
                onClick={() => toggleFolder(item.id)}
                title={item.name}
                role="treeitem"
                tabIndex={0}
                onKeyDown={(e) => handleFolderKeyDown(e, item.id)}
                aria-expanded={isOpen}
                aria-label={`Folder: ${item.name}`}
              >
                {isOpen ? (
                  <BiFolderOpen className="file-item-icon" />
                ) : (
                  <BiFolder className="file-item-icon" />
                )}
                <span className="file-item-name">{item.name}</span>
                {(item.name === "Sequences" || item.name === "Papers") && (
                  <img
                    src="/images/plus.svg"
                    alt="Add File"
                    className="folder-add-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(
                        updateModal({
                          name: "dna_archive_project",
                          data: { projectId: currentProjectId },
                        })
                      );
                    }}
                  />
                )}
                {(item.name === "Cloning Plans" ||
                  item.name === "Notebooks") && (
                  <img
                    src="/images/plus.svg"
                    alt={`Add ${
                      item.name === "Cloning Plans"
                        ? "Cloning Plan"
                        : "Notebook"
                    }`}
                    className="folder-add-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(
                        updateModal({
                          name:
                            item.name === "Cloning Plans"
                              ? "cloning_plan"
                              : "editable_notebook",
                          data: { mode: "create" },
                        })
                      );
                    }}
                  />
                )}
                {item.name === "Analysis" && (
                  <img
                    src="/images/plus.svg"
                    alt="Add Python File"
                    className="folder-add-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(
                        updateModal({
                          name: "python",
                          data: { mode: "create" },
                        })
                      );
                    }}
                  />
                )}
                {item.name === "Data" && (
                  <img
                    src="/images/plus.svg"
                    alt="Add Data File"
                    className="folder-add-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(
                        updateModal({
                          name: "dna_archive_project",
                          data: { projectId: currentProjectId, type: "Data" },
                        })
                      );
                    }}
                  />
                )}
                {item.name !== "Sequences" &&
                  item.name !==
                    "Cloning Plans" /* && item.name !== "Chats" */ && (
                    <img
                      src="/images/three-dots.svg"
                      alt="Menu"
                      className="project-menu-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(e, item.id);
                      }}
                    />
                  )}
                {activeDropdown === item.id && !item.isDesignFolder && (
                  <div
                    ref={dropdownRef}
                    className="project-dropdown"
                    onMouseLeave={() => setActiveDropdown(null)}
                    style={{
                      top: dropdownPosition?.top + "px",
                      right: dropdownPosition?.right + "px",
                    }}
                    role="menu"
                    aria-label="Project actions"
                  >
                    {/* Only show dropdown options for non-design folders */}
                    {/* Design folders are now removed */}
                  </div>
                )}
              </li>
              {isOpen && (
                <ul className="indented">{renderFileTree(item.children)}</ul>
              )}
            </React.Fragment>
          );
        }
        return null;
      })
      .filter(Boolean);
  };

  const handlePaymentClick = () => {
    dispatch(updateModal({ name: "payments", data: null }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        projectItemRef.current &&
        !projectItemRef.current.contains(event.target) &&
        userDropdownRef.current &&
        !userDropdownRef.current.contains(event.target)
      ) {
        setActiveDropdown(null);
        setUserDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (currentProjectId) {
      const currentProject = projectList.find(
        (p) => p.project_id === currentProjectId
      );
      if (currentProject) {
        const files = [];

        // Create base folders
        const partsFolder = {
          id: "parts-folder",
          name: "Sequences",
          type: "folder",
          children: [],
        };

        const cloningPlansFolder = {
          id: "cloning-plans-folder",
          name: "Cloning Plans",
          type: "folder",
          children: [],
        };

        const protocolsFolder = {
          id: "protocols-folder",
          name: "Notebooks",
          type: "folder",
          children: [],
        };

        const papersFolder = {
          id: "papers-folder",
          name: "Papers",
          type: "folder",
          children: [],
        };

        // Add new Python and Data folders
        const pythonFolder = {
          id: "python-folder",
          name: "Analysis",
          type: "folder",
          children: [],
        };

        const dataFolder = {
          id: "data-folder",
          name: "Data",
          type: "folder",
          children: [],
        };

        // First pass: Process all files
        const projectFiles = currentProject.files || {};
        const fileEntries = Object.entries(projectFiles);

        // Check if we're going from 0 to 1 file
        const prevFileCount = projectFiles[currentProjectId]?.length || 0;
        const newFileCount = fileEntries.length;

        if (prevFileCount === 0 && newFileCount === 1) {
          // Get the single file data
          const [fileId, fileData] = fileEntries[0];
          // Dispatch handleFileClick for the new file
          console.log("dispatching handleFileClick for fileId in Sidebar:", fileId);
          dispatch(
            handleFileClick({
              fileData: {
                uuid: fileId,
                name: fileData.file_name,
                file_type: fileData.file_type,
                ...fileData,
              },
            })
          );
        }

        fileEntries.forEach(([fileId, file]) => {
          const fileObj = {
            ...file,
            uuid: fileId,
            type: "file",
            name: file.file_name,
            chat_name: file.chat_name,
          };

          if (file.file_type === "Design") {
            // Add the design file directly to the Cloning Plans folder
            cloningPlansFolder.children.push(fileObj);
          } else if (file.file_type === "Notebook") {
            protocolsFolder.children.push(fileObj);
          } else if (
            file.file_type === "Paper" ||
            file.file_type === "Papers"
          ) {
            papersFolder.children.push(fileObj);
          } else if (file.file_type === "Python") {
            pythonFolder.children.push(fileObj);
          } else if (file.file_type === "Data") {
            dataFolder.children.push(fileObj);
          }
        });

        // Second pass: Process Output files and DNA files with parent_file_id
        Object.entries(currentProject.files || {}).forEach(([fileId, file]) => {
          if (file.parent_file_id) {
            if (file.file_type === "Output") {
              // Check if parent is a Design file
              const parentFile = projectFiles[file.parent_file_id];

              if (parentFile && parentFile.file_type === "Design") {
                // If parent is a Design file, add output to Cloning Plans folder
                cloningPlansFolder.children.push({
                  ...file,
                  uuid: fileId,
                  type: "file",
                  name: file.file_name,
                });
              }
            } else if (["DNA", "Protein", "RNA"].includes(file.file_type)) {
              // All DNA/Protein/RNA files go to Parts folder regardless of parent
              partsFolder.children.push({
                ...file,
                uuid: fileId,
                type: "file",
                name: file.file_name,
              });
            }
          } else if (["DNA", "Protein", "RNA"].includes(file.file_type)) {
            // DNA/Protein/RNA files without parent_file_id go to Parts folder
            partsFolder.children.push({
              ...file,
              uuid: fileId,
              type: "file",
              name: file.file_name,
            });
          }
        });

        // Sort files within each folder alphabetically
        const sortFiles = (items) => {
          return items
            .sort((a, b) => {
              // First sort by type (folders before files)
              if (a.type !== b.type) {
                return a.type === "folder" ? -1 : 1;
              }
              // Then sort by name alphabetically
              return a.name.localeCompare(b.name);
            })
            .map((item) => {
              if (item.type === "folder" && item.children) {
                return {
                  ...item,
                  children: sortFiles(item.children),
                };
              }
              return item;
            });
        };

        partsFolder.children = sortFiles(partsFolder.children);
        cloningPlansFolder.children = sortFiles(cloningPlansFolder.children);
        protocolsFolder.children = sortFiles(protocolsFolder.children);
        papersFolder.children = sortFiles(papersFolder.children);
        pythonFolder.children = sortFiles(pythonFolder.children);
        dataFolder.children = sortFiles(dataFolder.children);

        // Add all folders in specified order
        files.push(
          partsFolder,
          cloningPlansFolder,
          protocolsFolder,
          papersFolder,
          pythonFolder,
          dataFolder
        );

        setProjectFiles((prevFiles) => ({
          ...prevFiles,
          [currentProjectId]: files,
        }));

        // Set the folder open states
        const folderStates = {
          "parts-folder": true,
          "cloning-plans-folder": true,
          "protocols-folder": true,
          "papers-folder": true,
          "python-folder": true,
          "data-folder": true,
        };

        setOpenFolders((prev) => ({
          ...prev,
          ...folderStates,
        }));
      }
    }
  }, [currentProjectId, projectList, currentProject?.files]);

  const handleAuthAction = () => {
    if (isAuthenticated) {
      logout({ returnTo: window.location.origin });
    } else {
      loginWithRedirect();
    }
  };

  const fetchAccessToken = async () => {
    try {
      const accessTokenTmp = await getAccessTokenSilently();
      return accessTokenTmp;
    } catch (error) {
      console.error("Error getting access token:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const accessTokenTmp = await fetchAccessToken();
        if (accessTokenTmp) {
          dispatch(updateAccessToken(accessTokenTmp));
          const result = await dispatch(
            fetchUser({ token: accessTokenTmp, user_auth0: user })
          );

          if (result?.payload?.data?.data?.user_id) {
            // Only fetch projects if not already initialized
            if (!isProjectInitialized) {
              dispatch(fetchProjects(result.payload.data.data.user_id));
              dispatch(setProjectInitialized());
            }
            dispatch(setInitialized(true));
            setHasFetchedProjects(true);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (isAuthenticated && !hasFetchedProjects && !isInitialized) {
      fetchUserData();
    } else if (!isAuthenticated && !isLoading) {
      dispatch(setUserStatus("unauthenticated"));
    }
  }, [
    isAuthenticated,
    hasFetchedProjects,
    isLoading,
    isInitialized,
    isProjectInitialized,
  ]);

  useEffect(() => {
    if (isAuthenticated && user_id) {
      dispatch(fetchUserCredits());
    }
  }, [isAuthenticated, user_id, dispatch]);

  useEffect(() => {
    if (!currentProjectId) {
      dispatch(resetChat(currentProjectId));
      return;
    }

    // If we haven't done an initial load for this project yet
    const shouldFetchData = !initializedProjects[currentProjectId];
    console.log("shouldFetchData", shouldFetchData);
    if (shouldFetchData) {
      dispatch(fetchProject(currentProjectId));
      dispatch(setProjectFullyLoaded(currentProjectId));
    }
  }, [currentProjectId, initializedProjects, dispatch]);

  const toggleUserDropdown = (event) => {
    event.stopPropagation();
    setUserDropdownOpen(!userDropdownOpen);
  };

  // Add keyboard handler for project items
  const handleProjectKeyDown = (event, projectId) => {
    if (event.key === "Enter" || event.key === " ") {
      handleProjectClick(event, projectId);
    }
  };

  // Add keyboard handler for folder items
  const handleFolderKeyDown = (event, folderId) => {
    if (event.key === "Enter" || event.key === " ") {
      toggleFolder(folderId);
    }
  };

  const handleLoadMore = () => {
    if (projectsNextToken) {
      dispatch(fetchNextProjects(projectsNextToken));
    }
  };

  const handleHelpItemClick = (url, isEmail = false) => {
    if (isEmail) {
      window.location.href = `mailto:${url}`;
    } else {
      window.open(url, "_blank");
    }
    setUserDropdownOpen(false);
  };

  return (
    <section
      className={`sidebar ${isVisible ? "" : "hidden"}`}
      role="navigation"
      aria-label="Project navigation"
    >
      {!isVisible ? (
        <button
          className="sidebar-toggle"
          onClick={() => dispatch(toggleSidebar())}
          aria-label="Open sidebar menu"
        >
          <img src="/images/sidebar.svg" alt="" role="presentation" />
        </button>
      ) : (
        <>
          <div className="sidebar-content">
            <div className="sidebar-top">
              <div className="sidebar-header">
                <img
                  src="/images/sidebar.svg"
                  alt="Sidebar"
                  className="sidebar-icon"
                  onClick={() => dispatch(toggleSidebar())}
                />
              </div>
              <div className="project-header">
                <div className="project-header-name">Projects</div>
                {isAuthenticated && (
                  <img
                    src="/images/new_project.svg"
                    alt="Create new project"
                    className="new-project-icon"
                    onClick={() =>
                      dispatch(
                        updateModal({ name: "create_project", data: null })
                      )
                    }
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        dispatch(
                          updateModal({ name: "create_project", data: null })
                        );
                      }
                    }}
                  />
                )}
              </div>
              <div className="project-list">
                <ul>
                  {projectList.length > 0 ? (
                    <>
                      {projectList.map((project) => (
                        <div
                          key={project.project_id}
                          className={`project-group ${
                            project.project_id === currentProjectId
                              ? "selected"
                              : ""
                          }`}
                        >
                          <li
                            className={`project-item ${
                              project.project_id === currentProjectId
                                ? "selected"
                                : ""
                            }`}
                            ref={projectItemRef}
                            onClick={(event) =>
                              handleProjectClick(event, project.project_id)
                            }
                            title={project.project_name}
                            role="menuitem"
                            tabIndex={0}
                            onKeyDown={(e) =>
                              handleProjectKeyDown(e, project.project_id)
                            }
                            aria-selected={
                              project.project_id === currentProjectId
                            }
                          >
                            <span
                              className="project-name"
                              title={project.project_name}
                            >
                              {project.project_name}
                            </span>
                            <img
                              src="/images/three-dots.svg"
                              alt="Menu"
                              className="project-menu-icon"
                              onClick={(event) =>
                                toggleDropdown(event, project.project_id)
                              }
                            />
                            {activeDropdown === project.project_id && (
                              <div
                                ref={dropdownRef}
                                className="project-dropdown"
                                onMouseLeave={() => setActiveDropdown(null)}
                                style={{
                                  top: dropdownPosition?.top + "px",
                                  right: dropdownPosition?.right + "px",
                                }}
                                role="menu"
                                aria-label="Project actions"
                              >
                                <button
                                  className="dropdown-button"
                                  onClick={(event) =>
                                    handleProjectRename(
                                      event,
                                      project.project_id
                                    )
                                  }
                                >
                                  <span>Rename</span>
                                  <img
                                    src="/images/pencil.svg"
                                    alt="Rename"
                                    className="dropdown-icon"
                                  />
                                </button>
                                <button
                                  className="dropdown-button"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    dispatch(
                                      updateModal({
                                        name: "dna_archive_project",
                                        data: { projectId: project.project_id },
                                      })
                                    );
                                    setActiveDropdown(null);
                                  }}
                                >
                                  <span>Add File</span>
                                  <img
                                    src="/images/plus.svg"
                                    alt="Add File"
                                    className="dropdown-icon"
                                  />
                                </button>
                                <button
                                  className="dropdown-button"
                                  onClick={(event) =>
                                    handleProjectDownload(
                                      event,
                                      project.project_id
                                    )
                                  }
                                >
                                  <span>Download</span>
                                  <img
                                    src="/images/download.svg"
                                    alt="Download"
                                    className="dropdown-icon"
                                  />
                                </button>
                                <button
                                  className="dropdown-button"
                                  onClick={(event) =>
                                    handleProtocolExport(
                                      event,
                                      project.project_id
                                    )
                                  }
                                >
                                  <span>Copy Protocols</span>
                                  <img
                                    src="/images/copy-csv.svg"
                                    alt="Export"
                                    className="dropdown-icon"
                                  />
                                </button>
                                <button
                                  className="dropdown-button"
                                  onClick={(event) =>
                                    handleDeleteProject(
                                      event,
                                      project.project_id
                                    )
                                  }
                                >
                                  <span>Delete</span>
                                  <img
                                    src="/images/trash.svg"
                                    alt="Delete"
                                    className="dropdown-icon"
                                  />
                                </button>
                              </div>
                            )}
                          </li>

                          {renderProjectFiles(project.project_id)}
                        </div>
                      ))}
                      {projectsNextToken && (
                        <div className="load-more-item">
                          <button
                            className="load-more-button"
                            onClick={handleLoadMore}
                            aria-label="Load more projects"
                          >
                            Load More
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <div></div>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="sidebar-info">
            {isAuthenticated ? (
              <>
                {!subscriptionId ? (
                  // Show "Click to Subscribe" button and Preferences if no subscription
                  <>
                    <div
                      className="sidebar-info-item"
                      onClick={handlePaymentClick}
                      style={{ cursor: "pointer" }}
                      role="button"
                      tabIndex={0}
                      aria-label="Click to subscribe"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          handlePaymentClick();
                        }
                      }}
                    >
                      <div className="sidebar-bottom-img-container">
                        <img
                          src="/images/credit_card.svg"
                          alt="Subscribe"
                          className="sidebar-bottom-img"
                        />
                      </div>
                      <p className="sidebar-bottom-text">Click To Subscribe</p>
                    </div>
                    <div
                      className="sidebar-info-item"
                      onClick={() => {
                        dispatch(
                          updateModal({
                            name: "user_preferences",
                            data: null,
                          })
                        );
                      }}
                      style={{ cursor: "pointer" }}
                      role="button"
                      tabIndex={0}
                      aria-label="Open preferences"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          dispatch(
                            updateModal({
                              name: "user_preferences",
                              data: null,
                            })
                          );
                        }
                      }}
                    >
                      <div className="sidebar-bottom-img-container">
                        <img
                          src="/images/settings.svg"
                          alt="Preferences"
                          className="sidebar-bottom-img"
                        />
                      </div>
                      <p className="sidebar-bottom-text">Preferences</p>
                    </div>
                  </>
                ) : (
                  // Show Settings dropdown if subscribed
                  <div
                    className="sidebar-info-item"
                    onClick={() => {
                      dispatch(
                        updateModal({
                          name: "user_preferences",
                          data: null,
                        })
                      );
                    }}
                    style={{ cursor: "pointer" }}
                    role="button"
                    tabIndex={0}
                    aria-label="Open preferences"
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        dispatch(
                          updateModal({
                            name: "user_preferences",
                            data: null,
                          })
                        );
                      }
                    }}
                  >
                    <div className="sidebar-bottom-img-container">
                      <img
                        src="/images/settings.svg"
                        alt="Settings"
                        className="sidebar-bottom-img"
                      />
                    </div>
                    <p className="sidebar-bottom-text">Preferences</p>
                  </div>
                )}
                <div
                  className="sidebar-info-item sidebar-info-item-dna"
                  onClick={() =>
                    dispatch(
                      updateModal({ name: "dna_archive_general", data: null })
                    )
                  }
                  style={{ cursor: "pointer" }}
                  role="button"
                  tabIndex={0}
                  aria-label="Open Files"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      dispatch(
                        updateModal({
                          name: "dna_archive_general",
                          data: null,
                        })
                      );
                    }
                  }}
                >
                  <div className="sidebar-bottom-img-container">
                    <img
                      src="/images/dna-archive-fix.svg"
                      alt="DNA"
                      className="sidebar-bottom-img"
                    />
                  </div>
                  <p className="sidebar-bottom-text">Files & Parts</p>
                </div>
                <div
                  className="sidebar-info-item user-dropdown-trigger"
                  onClick={toggleUserDropdown}
                  ref={userDropdownRef}
                  style={{ cursor: "pointer", position: "relative" }}
                  role="button"
                  aria-haspopup="true"
                  aria-expanded={userDropdownOpen}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      toggleUserDropdown(e);
                    }
                  }}
                >
                  <div className="sidebar-bottom-img-container">
                    <img
                      src={
                        user.name === "Scott Stankey" ||
                        user.name === "Mike Magaraci"
                          ? "https://www.labkick.ai/K-mark.svg"
                          : user.picture || "/images/default-user-icon.png"
                      }
                      alt={
                        user.name === "Scott Stankey" ||
                        user.name === "Mike Magaraci"
                          ? "LabKick Team"
                          : user.name
                      }
                      width="20"
                      height="20"
                      className="sidebar-bottom-img user-picture"
                    />
                  </div>
                  <p className="sidebar-bottom-text">
                    {user.name === "Scott Stankey" ||
                    user.name === "Mike Magaraci"
                      ? "LabKick Team"
                      : user.name}
                  </p>
                  {userDropdownOpen && (
                    <div
                      className="user-dropdown"
                      onMouseLeave={() => setUserDropdownOpen(false)}
                    >
                      <button
                        className="dropdown-button"
                        onClick={() => {
                          dispatch(
                            updateModal({ name: "payments", data: null })
                          );
                          setUserDropdownOpen(false);
                        }}
                      >
                        <span>Account Settings</span>
                      </button>
                      <button
                        className="dropdown-button"
                        onClick={() =>
                          handleHelpItemClick(
                            "https://www.labkick.ai/terms_of_service_labkick.html"
                          )
                        }
                      >
                        <span>Terms of Service</span>
                      </button>
                      <button
                        className="dropdown-button"
                        onClick={() =>
                          handleHelpItemClick(
                            "https://www.labkick.ai/privacy_policy_labkick.html"
                          )
                        }
                      >
                        <span>Privacy Policy</span>
                      </button>
                      <button
                        className="dropdown-button"
                        onClick={() =>
                          handleHelpItemClick("support@labkick.ai", true)
                        }
                      >
                        <span>Contact Us</span>
                      </button>
                      <button
                        className="dropdown-button"
                        onClick={() =>
                          handleHelpItemClick("https://www.labkick.ai/tutorial")
                        }
                      >
                        <span>Tutorial</span>
                      </button>
                      <button
                        className="dropdown-button"
                        onClick={() =>
                          handleHelpItemClick("https://www.labkick.ai/appnotes")
                        }
                      >
                        <span>App Notes</span>
                      </button>
                      <button
                        className="dropdown-button"
                        onClick={() => {
                          logout({
                            logoutParams: {
                              returnTo: window.location.origin,
                            },
                          });
                          setUserDropdownOpen(false);
                        }}
                      >
                        <span>Log Out</span>
                      </button>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <button
                  className="auth-button"
                  onClick={() => loginWithRedirect()}
                  aria-label="Log in to account"
                >
                  Log In
                </button>
                <button
                  className="auth-button"
                  onClick={() =>
                    loginWithRedirect({
                      authorizationParams: {
                        screen_hint: "signup",
                        prompt: "login",
                      },
                    })
                  }
                  aria-label="Create new account"
                >
                  Sign Up
                </button>
              </>
            )}
          </div>
        </>
      )}
      {(modal.name === "dna_archive_general" ||
        modal.name === "dna_archive_project" ||
        modal.name === "upload_general" ||
        modal.name === "upload_project") && (
        <FileExplorerModal is_modal="True" />
      )}
      {modal.name === "user_preferences" && <UserPreferencesModal />}
      {modal.name === "payments" && <PaymentModal />}
      {modal.name === "group_management" && <GroupManagementModal />}
      {modal.name === "cloning_plan" && <CloningPlanModal />}
      {modal.name === "editable_notebook" && <EditableNotebookModal />}
      {modal.name === "python" && <PythonModal />}
      {deletingFile && (
        <div className="delete-confirmation-overlay">
          <div className="delete-confirmation-dialog">
            <h3>
              {isDeletingInProgress
                ? "Deletion in Progress"
                : "Confirm Deletion"}
            </h3>
            {isDeletingInProgress ? (
              <p>Deleting file. Please wait...</p>
            ) : (
              <>
                <p>Are you sure you want to permanently delete this file?</p>
                <p>This action cannot be undone.</p>
                <div className="delete-confirmation-buttons">
                  <button
                    onClick={handleDeleteConfirm}
                    className="delete-confirm-button"
                  >
                    Yes, Delete
                  </button>
                  <button
                    onClick={() => setDeletingFile(null)}
                    className="delete-cancel-button"
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default Sidebar;
