import React, { useEffect, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setPopupMessage } from "./store/user.js";
import NotificationsDropdown from "./components/NotificationsDropdown.js";
import { loadProjectFromNotification } from "./store/project.js";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const isCheckingAuth = useSelector((state) => state.user.isCheckingAuth);
  const token = useSelector((state) => state.user.access_token);
  const userId = useSelector((state) => state.user.user_id);
  const [pendingProjectId, setPendingProjectId] = useState(null);
  const popupMessage = useSelector((state) => state.user.popupMessage);
  const isLoadingFiles = useSelector((state) => state.project.isLoadingFiles);
  const loadingMessage = useSelector((state) => state.project.loadingMessage);
  const isGeneratingInstructions = useSelector(
    (state) => state.project.isGeneratingInstructions
  );
  const instructionsLoadingMessage = useSelector(
    (state) => state.project.instructionsLoadingMessage
  );
  const isSidebarVisible = useSelector(
    (state) => state.project.isSidebarVisible
  );

  const isActive = (path) => {
    if (path === "/tools") {
      return (
        location.pathname === "/tools" ||
        location.pathname.startsWith("/tools/")
      );
    }
    return location.pathname === path;
  };

  const isProtocolPage = location.pathname === "/";

  // Handle project URL access
  useEffect(() => {
    if (location.pathname.startsWith("/project/")) {
      const match = location.pathname.match(/\/project\/([a-zA-Z0-9-]+)/);
      if (match) {
        const projectId = match[1];
        setPendingProjectId(projectId);
      }
    }
  }, [location.pathname]);

  // Handle loading project after authentication
  useEffect(() => {
    const loadProject = async () => {
      if (pendingProjectId && token && userId && !isCheckingAuth) {
        try {
          await dispatch(
            loadProjectFromNotification(pendingProjectId)
          ).unwrap();
          navigate("/", { replace: true });
          setPendingProjectId(null);
        } catch (error) {
          console.error("Error loading project:", error);
          dispatch(setPopupMessage("Error loading project. Please try again."));
          navigate("/");
          setPendingProjectId(null);
        }
      }
    };

    loadProject();
  }, [pendingProjectId, token, userId, isCheckingAuth, dispatch, navigate]);

  return (
    <div className="app-container">
      <nav className="top-bar">
        <Link
          to="/"
          className={`top-bar-button ${isActive("/") ? "active" : ""}`}
        >
          Work
        </Link>
        <Link
          to="/lims"
          className={`top-bar-button lims-button ${
            isActive("/lims") ? "active" : ""
          }`}
        >
          Files
        </Link>
        {/* <Link
          to="/tools"
          className={`top-bar-button tools-button ${
            isActive("/tools") ? "active" : ""
          }`}
        >
          Tools
        </Link> */}
        {/* <div className="top-bar-right">
          <NotificationsDropdown />
        </div> */}
      </nav>
      <div
        className={`page-content ${
          isProtocolPage
            ? !isSidebarVisible
              ? "sidebar-hidden"
              : ""
            : "no-sidebar"
        }`}
      >
        <Outlet />
      </div>

      {/* Global loading states and messages */}
      {isLoadingFiles && (
        <div className="loading-overlay" role="alert" aria-busy="true">
          <div className="loading-message">
            <p>{loadingMessage || "Uploading files..."}</p>
          </div>
        </div>
      )}
      {isGeneratingInstructions && (
        <div className="loading-overlay">
          <div className="loading-message">
            <p>{instructionsLoadingMessage || "Generating instructions..."}</p>
          </div>
        </div>
      )}
      {popupMessage && (
        <div className="popup-message" role="alert" aria-live="polite">
          <p>{popupMessage}</p>
          <button
            onClick={() => dispatch(setPopupMessage(null))}
            aria-label="Close message"
          >
            ×
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
