import React, { useState, useRef, useEffect } from "react";
import "../style/ChatBar.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setActiveChat,
  manageChatAction,
  fetchChat,
  resetChat,
  setChatPanelOpen,
  updateChat,
} from "../store/project.js";
import { withEditStateCheck } from "../utils/editStateWrapper.js";
import {
  FaHistory,
  FaTimes,
  FaPencilAlt,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";

function ChatBar() {
  const dispatch = useDispatch();
  const activeChat =
    useSelector((state) => state.project.activeChat) || "agent";
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const currentProject = useSelector((state) => state.project.currentProject);
  const isChatPanelOpen = useSelector((state) => state.project.isChatPanelOpen);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [editingChatId, setEditingChatId] = useState(null);
  const [editingName, setEditingName] = useState("");
  const dropdownRef = useRef(null);
  const historyButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setEditingChatId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (isDropdownOpen && dropdownRef.current && historyButtonRef.current) {
      const buttonRect = historyButtonRef.current.getBoundingClientRect();
      dropdownRef.current.style.top = `${buttonRect.bottom + 4}px`;
      dropdownRef.current.style.right = `${
        window.innerWidth - buttonRect.right
      }px`;
    }
  }, [isDropdownOpen]);

  const getLatestChatFile = (type) => {
    if (!currentProject?.[`${type}_chat_files`]) return null;

    // Filter and sort chat files by last_opened_date
    const chatFiles = Object.values(currentProject[`${type}_chat_files`]).sort(
      (a, b) => {
        const dateA = new Date(b.last_opened_date);
        const dateB = new Date(a.last_opened_date);
        return dateA - dateB;
      }
    );

    return chatFiles[0] || null;
  };

  const handleChatTypeChange = async (type) => {
    dispatch(
      withEditStateCheck(async () => {
        console.log("Setting active chat to:", type);
        dispatch(setActiveChat(type));
        // Find and load the most recent chat of the new type
        const latestChat = getLatestChatFile(type);
        if (latestChat) {
          const chatFileData =
            currentProject[`${type}_chat_files`][latestChat?.chat_id];
          console.log("Chat file data:", chatFileData);
          dispatch(
            updateChat({
              chat_id: latestChat?.chat_id,
              project_id: currentProjectId,
              chat_contents: chatFileData?.chat_contents || [],
            })
          );
        } else {
          // If no existing chat, clear the current chat
          dispatch(resetChat(currentProjectId));
        }
      }, currentProjectId)
    );
  };

  const handleCreateNewChat = () => {
    if (!currentProjectId) return;
    dispatch(
      withEditStateCheck(
        () => dispatch(resetChat(currentProjectId)),
        currentProjectId
      )
    );
  };

  const getCurrentChats = () => {
    if (!currentProject?.[`${activeChat}_chat_files`]) return [];
    const chats = Object.entries(currentProject[`${activeChat}_chat_files`])
      .map(([id, file]) => ({
        id: file.chat_id,
        name: file.chat_name,
        lastModified: file.last_opened_date,
      }))
      .sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));

    console.log("Current chats in dropdown:", chats);
    return chats;
  };

  const handleLoadChat = (chatId) => {
    if (!chatId) return;
    dispatch(
      withEditStateCheck(() => {
        // Get chat data directly from project state
        console.log("Loading chat:", chatId, "with chat type:", activeChat);
        const chatFileData = currentProject[`${activeChat}_chat_files`][chatId];
        if (chatFileData) {
          dispatch(
            updateChat({
              chat_id: chatId,
              project_id: currentProjectId,
              chat_contents: chatFileData.chat_contents || [],
              // Include any other relevant chat data from chatFileData
            })
          );
        }
      }, currentProjectId)
    );
  };

  const handleDeleteChat = async (chatId) => {
    await dispatch(
      manageChatAction({
        projectId: currentProjectId,
        mode: "delete",
        fileId: chatId,
        chatType: activeChat,
      })
    );
    setIsDropdownOpen(false);
  };

  const handleEditChat = (chatId, currentName) => {
    setEditingChatId(chatId);
    setEditingName(currentName);
  };

  const handleSaveEdit = async (chatId) => {
    if (editingName.trim()) {
      await dispatch(
        manageChatAction({
          projectId: currentProjectId,
          mode: "rename",
          chatName: editingName.trim(),
          fileId: chatId,
          chatType: activeChat,
        })
      );
    }
    setEditingChatId(null);
    setEditingName("");
  };

  const handleKeyDown = (e, chatId) => {
    if (e.key === "Enter") {
      handleSaveEdit(chatId);
    } else if (e.key === "Escape") {
      setEditingChatId(null);
      setEditingName("");
    }
  };

  if (!isChatPanelOpen) {
    return (
      <div className="chatbar-container collapsed">
        <button
          className="chatbar-open-button"
          onClick={() => dispatch(setChatPanelOpen(true))}
          aria-label="Open chat panel"
        >
          <FaChevronLeft />
        </button>
      </div>
    );
  }

  return (
    <div className="chatbar-container">
      <div className="chatbar-options">
        <div
          className={`chatbar-option ${
            activeChat === "agent" ? "active no-bottom-bar" : ""
          }`}
          onClick={() => handleChatTypeChange("agent")}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleChatTypeChange("agent");
            }
          }}
        >
          Chat
          {/* <span className="chatbar-shortcut">⌘I</span> */}
        </div>
        {/* <div
          className={`chatbar-option ${activeChat === "chat" ? "active" : ""}`}
          onClick={() => handleChatTypeChange("chat")}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleChatTypeChange("chat");
            }
          }}
        >
          Chat
          <span className="chatbar-shortcut">⌘L</span>
        </div> */}
      </div>
      <div className="chatbar-controls">
        <button
          className="chatbar-new-chat-button"
          onClick={handleCreateNewChat}
        >
          <span className="chatbar-plus-symbol">+</span>
        </button>
        <div className="chatbar-history-dropdown">
          <button
            ref={historyButtonRef}
            className="chatbar-history-button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <FaHistory />
          </button>
          {isDropdownOpen && (
            <div ref={dropdownRef} className="chatbar-dropdown-menu">
              {getCurrentChats().map((chat) => (
                <div key={chat.id} className="chatbar-dropdown-item">
                  {editingChatId === chat.id ? (
                    <input
                      type="text"
                      value={editingName}
                      onChange={(e) => setEditingName(e.target.value)}
                      onBlur={() => handleSaveEdit(chat.id)}
                      onKeyDown={(e) => handleKeyDown(e, chat.id)}
                      autoFocus
                    />
                  ) : (
                    <>
                      <span
                        className="chatbar-dropdown-item-name"
                        onClick={() => handleLoadChat(chat.id)}
                      >
                        {chat.name}
                      </span>
                      <div className="chatbar-dropdown-item-actions">
                        <button
                          onClick={() => handleEditChat(chat.id, chat.name)}
                        >
                          <FaPencilAlt />
                        </button>
                        <button onClick={() => handleDeleteChat(chat.id)}>
                          <FaTrash />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <button
          className="chatbar-close-button"
          onClick={() => dispatch(setChatPanelOpen(false))}
        >
          <FaTimes />
        </button>
      </div>
    </div>
  );
}

export default ChatBar;
