import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { setIsLoading } from "../project.js";

export const manageChatAction = createAsyncThunk(
  "project/manageChat",
  async (
    { projectId, mode, chatName, fileId, chatType = "agent" },
    { dispatch, getState }
  ) => {
    try {
      dispatch(setIsLoading(true));
      const token = getState().user.access_token;
      const updatedProject = await manageChat(
        projectId,
        mode,
        chatName,
        fileId,
        token,
        chatType
      );
      return { projectId, updatedProject };
    } catch (error) {
      console.error("Error managing chat:", error);
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const manageChatActionReducer = {
  [manageChatAction.fulfilled]: (state, action) => {
    console.log(
      "manageChatAction.fulfilled - received project:",
      action.payload
    );
    const { projectId, updatedProject } = action.payload;

    // Update project in list
    state.projectList = state.projectList.map((project) =>
      project.project_id === projectId ? updatedProject : project
    );

    // Update current project if it's the one being modified
    if (state.currentProjectId === projectId) {
      state.currentProject = updatedProject;
    }
    console.log("Updated state:", {
      projectList: state.projectList,
      currentProject: state.currentProject,
    });
  },
};

export const manageChat = async (
  projectId,
  mode,
  chatName,
  fileId,
  token,
  chatType = "agent"
) => {
  console.log("=== manageChat API Call ===");
  console.log("Input parameters:", {
    projectId,
    mode,
    chatName,
    fileId,
    chatType,
    token: "REDACTED",
  });

  const response = await fetchWithToken(
    `${API_URL}/api/manage-chat`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: projectId,
        mode,
        chat_name: chatName,
        file_id: fileId,
        chat_type: chatType,
      }),
    },
    token
  );

  console.log("Response status:", response.status);

  if (!response.ok) {
    const errorText = await response.text();
    console.error("API Error:", errorText);
    throw new Error("Failed to manage chat");
  }

  const result = await response.json();
  console.log("Raw API Response:", result);

  // Parse the nested project JSON string
  const parsedProject = result.data.project
    ? JSON.parse(result.data.project)
    : null;
  console.log("Parsed project data:", parsedProject);
  console.log("=== End manageChat API Call ===");

  return result.status === "success"
    ? parsedProject
    : Promise.reject(result.message);
};
