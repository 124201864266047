import DOMPurify from "dompurify";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useSelector, useDispatch } from "react-redux";
import { components } from "./components.js";
import {
  isToolCallHidden,
  extractFilename,
  getDisplayName,
  handleToggle,
} from "./helpers.js";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { applyToolCall } from "../../store/project.js";

const formatStreamingJson = (jsonString, toolCall) => {
  // For edit_file tool calls, only stream the content_edit portion
  if (
    toolCall?.function?.name === "edit_lab_notebook" ||
    toolCall?.function?.name === "edit_python_script" ||
    toolCall?.function?.name === "edit_file"
  ) {
    try {
      // Try parsing the entire JSON first
      const parsed = JSON.parse(jsonString);
      if (parsed.content_edit) {
        return parsed.content_edit;
      }
    } catch {
      // If parsing fails (e.g. during streaming), fall back to string manipulation
      const startIndex = jsonString.indexOf('"content_edit"');
      if (startIndex === -1) return "";

      // Get everything after "content_edit":
      const contentEdit = jsonString.slice(startIndex + 14);

      // Find the content between the first and last quotes
      const firstQuote = contentEdit.indexOf('"');
      if (firstQuote === -1) return "";

      const content = contentEdit.slice(firstQuote + 1);

      // Look for the closing quote that's followed by a comma or closing brace
      let lastQuoteIndex = -1;
      for (let i = 0; i < content.length; i++) {
        if (content[i] === '"' && content[i - 1] !== "\\") {
          // Check if this quote is followed by a comma or closing brace
          const nextChar = content[i + 1];
          if (nextChar === "," || nextChar === "}") {
            lastQuoteIndex = i;
            break;
          }
        }
      }

      if (lastQuoteIndex === -1) {
        // For streaming content, replace escaped newlines with actual newlines
        return content.replace(/\\n/g, "\n");
      }

      // Return the content between quotes, properly unescaped
      return JSON.parse(`"${content.slice(0, lastQuoteIndex)}"`);
    }
  }

  if (!jsonString.trim().startsWith("{")) {
    return `"${jsonString}"`;
  }

  let formatted = jsonString;
  const openBraces = (jsonString.match(/{/g) || []).length;
  const closeBraces = (jsonString.match(/}/g) || []).length;
  const missingBraces = openBraces - closeBraces;

  if (missingBraces > 0) {
    formatted += "...";
  }

  try {
    const parsed = JSON.parse(formatted);
    return JSON.stringify(parsed, null, 2);
  } catch {
    return formatted
      .replace(/{/g, "{\n  ")
      .replace(/}/g, "\n}")
      .replace(/,/g, ",\n  ");
  }
};

export const MessageHistory = ({
  message,
  messageIndex,
  hiddenToolCalls,
  setHiddenToolCalls,
}) => {
  const parseMessageContent = (content) => {
    if (!content) return "";

    // Handle the new format where content is an array of objects with type and text fields
    if (Array.isArray(content)) {
      return content
        .map((item) => {
          if (item.type === "text" && item.text) {
            return { type: "text", content: item.text };
          }
          return null;
        })
        .filter(Boolean);
    }

    // Original implementation for string content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = DOMPurify.sanitize(content);

    const segments = [];
    let currentText = "";

    const processNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        currentText += node.textContent;
      } else if (
        node.nodeType === Node.ELEMENT_NODE &&
        node.classList.contains("file-tag")
      ) {
        if (currentText) {
          segments.push({ type: "text", content: currentText });
          currentText = "";
        }
        segments.push({
          type: "file-tag",
          content: node.textContent,
          fileId: node.getAttribute("data-file-tag"),
        });
      } else if (node.childNodes.length) {
        Array.from(node.childNodes).forEach(processNode);
      }
    };

    processNode(tempDiv);
    if (currentText) {
      segments.push({ type: "text", content: currentText });
    }

    return segments;
  };

  if (message.role === "tool") {
    return null;
  }

  // For assistant messages, show both content and tool calls
  if (message.role === "assistant") {
    // Handle both string content and array of objects content format
    const hasContent = Array.isArray(message.content)
      ? message.content.some(
          (item) => item.type === "text" && item.text && item.text.trim() !== ""
        )
      : message.content && message.content.trim() !== "";

    const hasToolCalls = message.tool_calls?.some(
      (toolCall) =>
        toolCall.function.name &&
        !toolCall.function.name.toLowerCase().includes("complete") &&
        !toolCall.function.name.toLowerCase().includes("handoff") &&
        !toolCall.function.name.toLowerCase().includes("begin") &&
        !toolCall.function.name.toLowerCase().includes("finish") &&
        !toolCall.function.name.toLowerCase().includes("use")
    );

    // Return null if there's no content and no valid tool calls
    if (!hasContent && !hasToolCalls) {
      return null;
    }

    return (
      <div style={{ display: "block" }}>
        {hasContent && (
          <div className="message-history__content">
            {parseMessageContent(message.content).map((segment, index) => {
              if (segment.type === "text") {
                return (
                  <ReactMarkdown
                    key={index}
                    remarkPlugins={[remarkGfm]}
                    components={components}
                  >
                    {segment.content}
                  </ReactMarkdown>
                );
              } else if (segment.type === "file-tag") {
                return (
                  <span
                    key={index}
                    className="message-history__file-tag file-tag"
                    data-file-tag={segment.fileId}
                    style={{ display: "inline-flex" }}
                  >
                    {segment.content}
                  </span>
                );
              }
              return null;
            })}
          </div>
        )}

        {message.tool_calls
          ?.filter(
            (toolCall) =>
              toolCall.function.name &&
              !toolCall.function.name.toLowerCase().includes("complete") &&
              !toolCall.function.name.toLowerCase().includes("handoff") &&
              !toolCall.function.name.toLowerCase().includes("begin") &&
              !toolCall.function.name.toLowerCase().includes("finish") &&
              !toolCall.function.name.toLowerCase().includes("use")
          )
          .map((toolCall, idx) => (
            <ToolCallBlock
              key={idx}
              toolCall={toolCall}
              messageIndex={messageIndex}
              hiddenToolCalls={hiddenToolCalls}
              setHiddenToolCalls={setHiddenToolCalls}
            />
          ))}
      </div>
    );
  }

  // For user messages, show content only if not empty
  if (message.role === "user") {
    // Handle both string content and array of objects content format
    if (Array.isArray(message.content)) {
      const hasContent = message.content.some(
        (item) => item.type === "text" && item.text && item.text.trim() !== ""
      );
      if (!hasContent) return null;
    } else if (!message.content || message.content.trim() === "") {
      return null;
    }

    const segments = parseMessageContent(message.content);

    return (
      <div style={{ display: "block" }}>
        {segments.map((segment, index) => {
          if (segment.type === "text") {
            return (
              <span key={index} style={{ display: "inline" }}>
                {segment.content.split(/(`[^`]+`)/).map((text, i) => {
                  if (text.startsWith("`") && text.endsWith("`")) {
                    return (
                      <span
                        key={i}
                        className="message-history__file-tag file-tag"
                      >
                        {text.slice(1, -1)}
                      </span>
                    );
                  }
                  return text;
                })}
              </span>
            );
          } else if (segment.type === "file-tag") {
            return (
              <span
                key={index}
                className="message-history__file-tag file-tag"
                data-file-tag={segment.fileId}
                style={{ display: "inline-flex" }}
              >
                {segment.content}
              </span>
            );
          }
          return null;
        })}
      </div>
    );
  }

  return null;
};

const ToolCallBlock = ({
  toolCall,
  messageIndex,
  hiddenToolCalls,
  setHiddenToolCalls,
}) => {
  const dispatch = useDispatch();
  const chatType = useSelector((state) => state.project.activeChat);
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const applyingToolCall = useSelector(
    (state) => state.project.applyingToolCall
  );

  const callId = `message_${messageIndex}_tool_${toolCall.function.name}`;
  const isHidden = isToolCallHidden(
    callId,
    toolCall.function.name,
    hiddenToolCalls
  );
  const args = toolCall.function.arguments || "{}";
  let parsedArgs;
  try {
    parsedArgs = JSON.parse(args);
  } catch {
    parsedArgs = args;
  }

  const filename = extractFilename(parsedArgs);
  const displayName = filename
    ? `${getDisplayName(toolCall.function.name)}: ${filename}`
    : getDisplayName(toolCall.function.name);

  const isApplyingThisToolCall =
    applyingToolCall &&
    applyingToolCall.filename === filename &&
    (JSON.stringify(applyingToolCall.request) === JSON.stringify(parsedArgs) ||
      JSON.stringify(applyingToolCall.request?.request) ===
        JSON.stringify(parsedArgs?.request));

  const applyableToolCalls = [
    "create_dna_design",
    "generate_assembly_plan",
    "manage_dna_design_settings",
    "edit_dna_file",
    "edit_python_script",
    "edit_lab_notebook",
    "edit_dna_features",
    "manage_dna_design_bins",
    "create_dna_file",
    "manage_dna_design_parts",
    "transform_dna_sequence",
    "modify_dna_metadata",
  ];

  const shouldShowApply =
    chatType === "chat" && applyableToolCalls.includes(toolCall.function.name);

  return (
    <div className="message-history__file-block-container file-block-container">
      <div className="message-history__file-block-header file-block-header">
        <span className="message-history__file-block-filename file-block-filename">
          {displayName}
        </span>
        <div className="message-history__file-block-actions file-block-actions">
          <button
            className="message-history__file-action-text-button file-action-text-button"
            onClick={() => {
              const content =
                typeof parsedArgs === "object"
                  ? JSON.stringify(parsedArgs, null, 2)
                  : parsedArgs;
              navigator.clipboard.writeText(content);
            }}
          >
            copy
          </button>
          {shouldShowApply && (
            <>
              {isApplyingThisToolCall ? (
                <div className="message-history__file-block-spinner file-block-spinner">
                  <div className="message-history__spinner-circle spinner-circle"></div>
                </div>
              ) : (
                <button
                  className="message-history__file-action-text-button file-action-text-button"
                  onClick={() => {
                    dispatch(
                      applyToolCall({
                        filename,
                        projectId: currentProjectId,
                        request: parsedArgs,
                        functionName: toolCall.function.name,
                      })
                    );
                  }}
                >
                  apply
                </button>
              )}
            </>
          )}
          <button
            className="message-history__caret-toggle caret-toggle"
            onClick={() =>
              handleToggle(
                callId,
                isHidden,
                hiddenToolCalls,
                setHiddenToolCalls
              )
            }
          >
            {isHidden ? <FaCaretDown /> : <FaCaretUp />}
          </button>
        </div>
      </div>
      {!isHidden && (
        <div className="message-history__file-block-content file-block-content">
          <pre>
            <code
              className={
                toolCall.function.argumentsComplete
                  ? ""
                  : "message-history__streaming streaming"
              }
            >
              {formatStreamingJson(
                toolCall.function.parsedArguments
                  ? JSON.stringify(toolCall.function.parsedArguments)
                  : toolCall.function.arguments || "{}",
                toolCall
              )}
            </code>
          </pre>
        </div>
      )}
    </div>
  );
};
