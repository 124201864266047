// src/components/EditableNotebook.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editNotebookData,
  uploadNotebookImage,
  setNotebookHighlightedText,
  setEditorNeedsRemount,
} from "../store/project.js";
import "@mdxeditor/editor/style.css";
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  linkPlugin,
  linkDialogPlugin,
  tablePlugin,
  codeBlockPlugin,
  codeMirrorPlugin,
  imagePlugin,
  diffSourcePlugin,
  toolbarPlugin,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  UndoRedo,
  InsertImage,
  InsertTable,
  InsertCodeBlock,
  ListsToggle,
  CreateLink,
  DiffSourceToggleWrapper,
  markdownShortcutPlugin,
} from "@mdxeditor/editor";
import "../style/EditableNotebook.css";
import debounce from "lodash.debounce";
import sanitizeMarkdown from "sanitize-markdown";

function EditableNotebook({ fileId }) {
  const dispatch = useDispatch();
  const currentProject = useSelector((state) => state.project.currentProject);
  const artifact = useSelector((state) => state.project.artifact);
  const editorNeedsRemount = useSelector(
    (state) => state.project.editorNeedsRemount
  );
  const isMessageSending = useSelector(
    (state) => state.project.isMessageSending
  );
  const isMessageError = useSelector((state) => state.project.isMessageError);
  const isApplyingChanges = useSelector(
    (state) => state.project.applyingChatBlock
  );
  const editorRef = useRef(null);
  const [value, setValue] = useState(
    typeof artifact?.content === "string" ? artifact?.content : " "
  );
  const [saveStatus, setSaveStatus] = useState("saved");
  const [editorKey, setEditorKey] = useState(0);

  // Keep track of the current fileId for save operations
  const currentFileIdRef = useRef(fileId);

  // Add state to track if component is mounted
  const [isMounted, setIsMounted] = useState(false);

  // Add useEffect to handle mounting
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  // Force remount of editor when artifact changes
  useEffect(() => {
    console.log("editorNeedsRemount", editorNeedsRemount);
    if (editorNeedsRemount) {
      setEditorKey((prev) => prev + 1);
      dispatch(setEditorNeedsRemount(false));
    }
  }, [editorNeedsRemount]);

  // Add this function to sanitize table content
  const sanitizeTableContent = (markdownContent) => {
    if (!markdownContent) return markdownContent;

    // Regex pattern to identify markdown tables
    const tableRegex =
      /(\|[^\n]*\|\n\|[ :]*[-:]*[ :]*\|[^\n]*\n)((?:\|[^\n]*\|\n)*)/g;

    return markdownContent.replace(tableRegex, (table) => {
      // Split table into rows
      const rows = table.split("\n");

      // Process each row
      const processedRows = rows.map((row) => {
        if (!row.trim()) return row;

        // Split row into cells and process each cell
        const cells = row.split("|");
        const processedCells = cells.map((cell, index) => {
          // Skip first and last empty cells created by splitting
          if ((index === 0 || index === cells.length - 1) && !cell.trim()) {
            return cell;
          }

          // Sanitize the cell content
          return sanitizeMarkdown(cell, {
            allowedTags: ["b", "i", "em", "strong", "code"],
            allowedAttributes: {
              "code": ["class"],
            },
          });
        });

        // Rejoin cells into a row
        return processedCells.join("|");
      });

      // Rejoin rows into a table
      return processedRows.join("\n");
    });
  };

  // Modify the useEffect that updates content
  useEffect(() => {
    if (!artifact || !isMounted) return;

    const content =
      typeof artifact?.content === "string" ? artifact?.content : " ";
    const cleanedContent = content
      .split("\n")
      .map((line) => (line.trim() ? line.trimEnd() : line))
      .join("\n");

    // Sanitize tables in the cleaned content
    const sanitizedContent = sanitizeTableContent(cleanedContent || " ");

    setValue(sanitizedContent);

    // Add timeout to ensure DOM is ready
    const timer = setTimeout(() => {
      if (editorRef.current && editorRef.current.setMarkdown) {
        try {
          editorRef.current.setMarkdown(sanitizedContent);
        } catch (error) {
          console.warn("Failed to update editor content:", error);
        }
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [artifact, isMounted]);

  useEffect(() => {
    currentFileIdRef.current = fileId;
  }, [fileId]);

  const handleWrapperClick = useCallback((e) => {
    // Only focus if clicking directly on the wrapper (not on editor or toolbar)
    if (
      e.target.classList.contains("enb-wrapper") ||
      e.target.classList.contains("enb-editable-notebook")
    ) {
      editorRef.current?.focus();
    }
  }, []);

  const debouncedSave = useRef(
    debounce(async (val, savedFileId) => {
      // Check against the ref's current value
      if (savedFileId !== currentFileIdRef.current) {
        console.log("Skipping save - file ID mismatch");
        return;
      }

      try {
        setSaveStatus("saving");
        await dispatch(
          editNotebookData({
            fileId: savedFileId,
            content: val,
          })
        );
        setSaveStatus("saved");
      } catch (error) {
        console.error("Error saving notebook:", error);
        setSaveStatus("editing");
      }
    }, 1500)
  ).current;

  useEffect(() => {
    // Cancel any pending saves when fileId changes
    return () => debouncedSave.cancel();
  }, [fileId, debouncedSave]);

  useEffect(() => {
    debouncedSave(value, fileId);
    setSaveStatus("editing");
    return () => debouncedSave.cancel();
  }, [value, debouncedSave, fileId]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "k") {
        e.preventDefault();
        const selection = window.getSelection();
        if (selection && selection.toString().trim()) {
          dispatch(
            setNotebookHighlightedText({
              text: selection.toString(),
              type: "notebook",
            })
          );
        }
      }
    };

    const notebookContainer = document.querySelector(".enb-editable-notebook");
    if (notebookContainer) {
      notebookContainer.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      if (notebookContainer) {
        notebookContainer.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [dispatch]);

  const handleEditorSelect = useCallback(() => {
    const selection = window.getSelection();
    if (selection && selection.toString().trim()) {
      const text = selection.toString();
      const fullText = editorRef.current?.getMarkdown() || "";
      const start = fullText.indexOf(text);
      const end = start + text.length;
      dispatch(
        setNotebookHighlightedText({
          text,
          fullText,
          start,
          end,
          type: "notebook",
        })
      );
    } else {
      dispatch(setNotebookHighlightedText(null));
    }
  }, [dispatch]);

  const handleImageUpload = async (file) => {
    try {
      const imageUrl = await dispatch(uploadNotebookImage(file)).unwrap();
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleEditorChange = (newValue) => {
    // Only check if editorRef.current exists
    if (!editorRef.current) {
      return;
    }

    // Skip if the value hasn't changed
    if (newValue === value) {
      return;
    }

    try {
      // Ensure newValue is a string and remove trailing whitespace from non-empty lines
      const processedValue =
        typeof newValue === "string"
          ? newValue
              .split("\n")
              .map((line) => (line.trim() ? line.trimEnd() : line))
              .join("\n")
          : "";

      // Sanitize tables in the processed value
      const sanitizedValue = sanitizeTableContent(processedValue || " ");

      // Convert empty string to single space before saving
      setValue(sanitizedValue || " ");
    } catch (error) {
      console.warn("Error processing editor value:", error);
    }
  };

  return (
    <div className="enb-wrapper" onClick={handleWrapperClick}>
      <div
        className="enb-editable-notebook"
        style={{ backgroundColor: "white" }}
        data-color-mode="light"
        onMouseUp={handleEditorSelect}
        onKeyUp={handleEditorSelect}
      >
        <div className="enb-save-status">
          {isMessageSending && !isMessageError
            ? "Locked"
            : isApplyingChanges
            ? "Applying Changes..."
            : saveStatus === "saving"
            ? "Saving..."
            : saveStatus}
        </div>
        {isMounted && (
          <MDXEditor
            key={editorKey}
            ref={editorRef}
            markdown={value || " "}
            onChange={handleEditorChange}
            className="enb-mdx-editor"
            suppressHtmlProcessing={true}
            onError={(error) => {
              console.warn("MDX Editor error:", error);
              // Optionally handle the error in your UI
            }}
            plugins={[
              headingsPlugin(),
              listsPlugin(),
              quotePlugin(),
              thematicBreakPlugin(),
              linkPlugin(),
              linkDialogPlugin(),
              tablePlugin(),
              codeBlockPlugin({
                codeBlockLanguages: {
                  js: "JavaScript",
                  jsx: "React JSX",
                  ts: "TypeScript",
                  tsx: "React TSX",
                  css: "CSS",
                  html: "HTML",
                  md: "Markdown",
                  markdown: "Markdown",
                  python: "Python",
                  "": "Plain text",
                },
              }),
              codeMirrorPlugin({
                codeBlockLanguages: {
                  js: "JavaScript",
                  jsx: "React JSX",
                  ts: "TypeScript",
                  tsx: "React TSX",
                  css: "CSS",
                  html: "HTML",
                  md: "Markdown",
                  markdown: "Markdown",
                  python: "Python",
                  "": "Plain text",
                },
                codeMirrorOptions: {
                  theme: "light",
                  lineNumbers: true,
                  lineWrapping: true,
                  mode: "javascript",
                  viewportMargin: Infinity,
                  onError: (error) => {
                    console.warn("CodeMirror error:", error);
                  },
                },
              }),
              imagePlugin({
                imageUploadHandler: handleImageUpload,
              }),
              diffSourcePlugin({
                diffMarkdown: artifact?.content_old || "",
                viewMode: artifact?.content_old ? "diff" : "rich-text",
                readOnlyDiff: false,
                initialViewMode: artifact?.content_old ? "diff" : "rich-text",
                onViewModeChange: (mode) => {
                  console.log("DiffSourcePlugin view mode changed to:", mode);
                },
              }),
              toolbarPlugin({
                toolbarContents: () => (
                  <DiffSourceToggleWrapper>
                    <UndoRedo />
                    <BoldItalicUnderlineToggles />
                    <BlockTypeSelect />
                    <ListsToggle />
                    <CreateLink />
                    <InsertImage />
                    <InsertTable />
                    <InsertCodeBlock />
                  </DiffSourceToggleWrapper>
                ),
              }),
              markdownShortcutPlugin(),
            ]}
            readOnly={
              (isMessageSending && !isMessageError) || isApplyingChanges
            }
          />
        )}
      </div>
    </div>
  );
}

export default EditableNotebook;
