import React, { useState, useEffect, useRef } from "react";
import {
  updateModal,
  closeModal,
  manageEditableNotebookAction,
} from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import { checkFilenameUnique } from "../api/files_api.js";
import "../style/Modal.css";
import { handleFileClick } from "../store/project_reducers/handleFileClick.js";

function EditableNotebookModal() {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.project);
  const currentProject = useSelector((state) => state.project.currentProject);
  const user = useSelector((state) => state.user.data);
  const token = useSelector((state) => state.user.access_token);

  // modal.data should contain { mode: 'create' | 'rename' | 'delete', notebookId?: string, notebookName?: string }
  const { mode, notebookId, notebookName } = modal.data || {};

  const titles = {
    create: "Create Notebook",
    rename: "Rename Notebook",
    delete: "Delete Notebook",
  };

  useEffect(() => {
    if (mode === "rename" && notebookName) {
      setInputValue(notebookName);
    } else {
      setInputValue("");
    }
  }, [mode, notebookName]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(updateModal({ name: "", data: null }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        dispatch(closeModal());
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch]);

  const handleSubmit = async () => {
    try {
      if (mode === "delete") {
        await dispatch(
          manageEditableNotebookAction({
            projectId: currentProject.project_id,
            mode: "delete",
            notebookId,
            notebookName,
          })
        ).unwrap();
        dispatch(closeModal());
        return;
      }

      // Check if file is in edit state for rename operation
      if (mode === "rename" && notebookId) {
        const file = currentProject.files?.[notebookId];
        if (file && file.s3_url && file.s3_url.includes("_edit.")) {
          setError("Cannot rename file when in edit state");
          return;
        }
      }

      if (!inputValue.trim()) {
        setError("Notebook name cannot be empty");
        return;
      }

      const notebookNameToUse = `${inputValue.split(".")[0].trim()}.md`;

      if (!notebookNameToUse) {
        setError("Notebook name cannot be empty");
        return;
      }

      // Check if filename is unique
      const isUnique = await checkFilenameUnique(
        user.user_id,
        notebookNameToUse,
        token
      );

      if (
        !isUnique &&
        (mode === "create" ||
          (mode === "rename" && notebookNameToUse !== notebookName))
      ) {
        setError("A notebook with this name already exists");
        return;
      }

      await dispatch(
        manageEditableNotebookAction({
          projectId: currentProject.project_id,
          mode,
          notebookName: notebookNameToUse,
          notebookId,
        })
      )
        .unwrap()
        .then((result) => {
          // Find the newly created file in the result
          if (mode === "create" && result.files) {
            const newFile = Object.entries(result.files).find(
              ([_, file]) =>
                file.file_name === notebookNameToUse &&
                file.file_type === "Notebook"
            );

            if (newFile) {
              const [fileId, fileData] = newFile;
              return dispatch(
                handleFileClick({
                  fileData: {
                    uuid: fileId,
                    name: fileData.file_name,
                    file_type: fileData.file_type,
                  },
                })
              );
            }
          }
        })
        .then(() => {
          dispatch(closeModal());
        });
    } catch (error) {
      console.error("Error managing notebook:", error);
      setError(
        error.message || "An error occurred while managing the notebook"
      );
    }
  };

  return (
    <div
      className="modal-overlay"
      onClick={(e) => e.stopPropagation()}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className="modal-content" ref={modalRef}>
        <h2 className="modal-title" id="modal-title">
          {titles[mode]}
        </h2>
        {error && (
          <div className="modal-error-message" role="alert" aria-live="polite">
            {error}
          </div>
        )}
        {mode === "delete" ? (
          <div className="modal-delete-confirmation">
            <p>
              Are you sure you want to delete the notebook "{notebookName}"?
            </p>
            <p>This action cannot be undone.</p>
          </div>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            aria-label="Notebook form"
          >
            <input
              id="notebook-name-input"
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={
                mode === "create" ? "Enter notebook name" : "Enter new name"
              }
              className="modal-input"
              aria-invalid={!!error}
              aria-describedby={error ? "name-error" : undefined}
            />
          </form>
        )}
        <div className="modal-buttons">
          <button
            type="button"
            onClick={() => dispatch(closeModal())}
            className="modal-cancel-button"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className={`modal-submit-button ${
              mode === "delete" ? "delete" : ""
            }`}
          >
            {mode === "delete" ? "Delete" : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditableNotebookModal;
