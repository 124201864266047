import React from "react";
import { deleteProjectFile, closeModal } from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";

function DeleteFileFromProjectModal() {
  const dispatch = useDispatch();
  const { modal, currentProjectId } = useSelector((state) => state.project);
  const user = useSelector((state) => state.user.data);

  // Delete file immediately when modal opens
  React.useEffect(() => {
    if (modal.data?.uuid) {
      dispatch(
        deleteProjectFile({
          projectId: currentProjectId,
          fileId: modal.data.uuid,
          userId: user.user_id,
        })
      );
      dispatch(closeModal());
    }
  }, [dispatch, currentProjectId, modal.data, user.user_id]);

  return null; // No UI needed since we're deleting immediately
}

export default DeleteFileFromProjectModal;
