import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import {
  addPopUpUserMessage,
  setCurrentPopupStreamedUpdate,
  setCurrentStreamedUpdate,
  setIsChatLoading,
  setStreamProgress,
  clearSendMessageError,
  finishPopUpMessages,
  updateProject,
  updatePopUpMessages,
  setIsMessageSending,
  setCurrentStreamedToolCalls,
  updateChat,
  setProjectFiles,
  updateProjectChatFiles,
} from "../project.js";

import { updateUserFile, updateMonthlyCreditsConsumed } from "../user.js";
import { fetchArtifactData } from "./fetchArtifactData.js";

export const sendChatMessage = createAsyncThunk(
  "projects/sendChatMessage",
  async (
    {
      message,
      chatId,
      projectId,
      currentProject,
      currentSequence,
      chatType,
      pillFileIds,
      selectedModel,
    },
    { dispatch, getState }
  ) => {
    try {
      dispatch(setStreamProgress("Processing..."));
      const state = getState();
      const token = state.user.access_token;
      const artifact = state.project.artifact;
      const sequenceViewerSelection = state.project.sequenceViewerSelection;
      const notebookHighlightedText = state.project.notebookHighlightedText;
      dispatch(setIsMessageSending(true));

      // Prepare command_k_input based on what's active
      const command_k_input =
        sequenceViewerSelection?.start !== undefined
          ? {
              type: artifact?.type || "sequence",
              start_index: sequenceViewerSelection.start,
              end_index: sequenceViewerSelection.end,
            }
          : notebookHighlightedText
          ? {
              type: notebookHighlightedText.type,
              text: notebookHighlightedText.text,
              fullText: notebookHighlightedText.fullText,
              start_index: notebookHighlightedText.start,
              end_index: notebookHighlightedText.end,
            }
          : null;

      if (chatType === "popup") {
        dispatch(addPopUpUserMessage(message));
      }

      const activeChat = chatType || state.project.activeChat || "agent";
      const activeFileId = state.project.activeFileId;
      const modelType = selectedModel || state.project.selectedModel || "L1";

      const activeChatId = chatId || projectId; // Use projectId as fallback

      dispatch(setIsChatLoading(chatType));
      dispatch(clearSendMessageError());

      try {
        const response = await sendMessage(
          message,
          chatId,
          projectId,
          currentProject,
          token,
          currentSequence,
          activeChat,
          pillFileIds,
          activeFileId,
          modelType,
          command_k_input
        );

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        let finalData = null;
        let buffer = "";
        let currentContent = "";
        let chunkCount = 0;
        let lastStreamedUpdate = null;
        let toolCallMap = {};
        let currentMessageId = null;
        let isFirstMessage = true;

        // Add the first message immediately
        if (activeChatId) {
          const currentContents =
            getState().project.chat[activeChatId]?.chat_contents || [];
          const newMessage = {
            role: "assistant",
            content: "",
            timestamp: new Date().toISOString(),
            id: Date.now().toString(),
            sender: "assistant",
            tool_calls: [],
            function_call: null,
          };

          currentMessageId = newMessage.id;
          currentContent = ""; // Reset content for new message

          // Update both chat contents and project chat files
          dispatch(
            updateChat({
              chat_id: activeChatId,
              project_id: projectId,
              chat_contents: [...currentContents, newMessage],
            })
          );
        }

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value, { stream: true });
          buffer += chunk;

          let boundary = buffer.indexOf("\n\n");
          while (boundary !== -1) {
            const line = buffer.slice(0, boundary);
            buffer = buffer.slice(boundary + 2);

            if (line.startsWith("data: ")) {
              try {
                const eventData = JSON.parse(line.slice(6));
                // console.log("Event Data:", eventData);
                // console.log("Event Data:", eventData.message);

                switch (eventData.status) {
                  case "update":
                    dispatch(setStreamProgress(eventData.message));
                    break;

                  case "file_changed":
                    console.log("File Changed Event:", eventData);
                    if (eventData.content && eventData.content.data.file_id) {
                      const currentState = getState();
                      const currentArtifactId =
                        currentState.project.artifact?.file_id;

                      // Check if the changed file is the current artifact
                      if (
                        currentArtifactId === eventData.content.data.file_id
                      ) {
                        console.log(
                          "Current artifact matched changed file. Updating artifact."
                        );
                        // Update the artifact with the data from the event
                        // Pass the entire content structure as artifactData
                        dispatch(
                          fetchArtifactData({
                            fileId: eventData.content.data.file_id,
                            skipApiCall: true,
                            artifactData: {
                              file_id: eventData.content.data.file_id,
                              file_name: eventData.content.data.file_name,
                              type: eventData.content.data.type || "notebook",
                              content: eventData.content.data.content,
                              content_old: eventData.content.data.content_old,
                              status: "success",
                            },
                          })
                        );
                      } else {
                        console.log(
                          "File changed but doesn't match current artifact. Current:",
                          currentArtifactId,
                          "Changed:",
                          eventData.content.data.file_id
                        );
                      }
                    }
                    break;

                  case "project_changed":
                    // Update only the project files
                    console.log("Project Changed:", eventData);
                    if (eventData.content && eventData.content.files) {
                      dispatch(
                        setProjectFiles({
                          projectId: eventData.content.project_id,
                          files: eventData.content.files,
                        })
                      );
                    }
                    break;

                  case "stopped":
                    // Reset streaming state and return early
                    dispatch(setIsChatLoading(false));
                    dispatch(setStreamProgress(null));
                    dispatch(setIsMessageSending(false));
                    dispatch(setCurrentStreamedUpdate(null));
                    dispatch(setCurrentPopupStreamedUpdate(null));
                    dispatch(setCurrentStreamedToolCalls(null));
                    return {
                      projectId,
                      response: null,
                      lastStreamedUpdate: null,
                    };

                  case "chunk":
                    chunkCount++;
                    currentContent = currentContent + eventData.message;

                    // Skip updating if message contains handoff/complete/begin/finish
                    if (
                      currentContent
                        .toLowerCase()
                        .includes("handoff/complete/begin/finish/use")
                    ) {
                      break;
                    }

                    if (activeChatId && currentMessageId) {
                      const currentContents =
                        getState().project.chat[activeChatId]?.chat_contents ||
                        [];
                      const updatedContents = currentContents.map((msg) =>
                        msg.id === currentMessageId
                          ? {
                              ...msg,
                              content: currentContent,
                              sender: eventData.sender || msg.sender,
                            }
                          : msg
                      );

                      // Update both chat contents and project chat files
                      dispatch(
                        updateChat({
                          chat_id: activeChatId,
                          project_id: projectId,
                          chat_contents: updatedContents,
                        })
                      );
                    }
                    break;

                  case "delim":
                    if (eventData.message === "start" && !isFirstMessage) {
                      // Only create new messages for subsequent delims
                      const currentContents =
                        getState().project.chat[activeChatId]?.chat_contents ||
                        [];
                      const newMessage = {
                        role: "assistant",
                        content: "",
                        timestamp: new Date().toISOString(),
                        id: Date.now().toString(),
                        sender: eventData.sender || "assistant",
                        tool_calls: [],
                        function_call: null,
                      };

                      // Skip adding message if previous message contains handoff/complete
                      const lastMessage =
                        currentContents[currentContents.length - 1];
                      if (
                        lastMessage?.content
                          ?.toLowerCase()
                          .includes("handoff/complete/begin/finish/use")
                      ) {
                        break;
                      }

                      currentMessageId = newMessage.id;
                      currentContent = ""; // Reset content for new message

                      // Update both chat contents and project chat files
                      dispatch(
                        updateChat({
                          chat_id: activeChatId,
                          project_id: projectId,
                          chat_contents: [...currentContents, newMessage],
                        })
                      );
                    }
                    isFirstMessage = false;
                    break;

                  case "tool_call":
                    if (activeChatId && currentMessageId) {
                      const currentContents =
                        getState().project.chat[activeChatId]?.chat_contents ||
                        [];
                      const updatedContents = currentContents.map((msg) => {
                        if (msg.id === currentMessageId) {
                          let updatedToolCalls = [...(msg.tool_calls || [])];

                          if (Array.isArray(eventData.message)) {
                            // Handle first tool call message
                            if (eventData.message[0].function.name) {
                              updatedToolCalls = eventData.message;
                            }
                            // Handle subsequent argument updates
                            else if (updatedToolCalls.length > 0) {
                              const lastToolCall =
                                updatedToolCalls[updatedToolCalls.length - 1];
                              const newArguments =
                                (lastToolCall.function.arguments || "") +
                                eventData.message[0].function.arguments;

                              // Try to parse as JSON if it looks complete
                              let parsedArgs = null;
                              if (
                                newArguments.trim().startsWith("{") &&
                                newArguments.trim().endsWith("}")
                              ) {
                                try {
                                  parsedArgs = JSON.parse(newArguments);
                                } catch (e) {
                                  // Not valid JSON yet, that's okay
                                }
                              }

                              updatedToolCalls[updatedToolCalls.length - 1] = {
                                ...lastToolCall,
                                function: {
                                  ...lastToolCall.function,
                                  arguments: newArguments,
                                  // Add a field to track if arguments are complete JSON
                                  argumentsComplete: parsedArgs !== null,
                                  // Store parsed args if available
                                  parsedArguments: parsedArgs,
                                },
                              };
                            }
                          }

                          return {
                            ...msg,
                            tool_calls: updatedToolCalls,
                          };
                        }
                        return msg;
                      });

                      dispatch(
                        updateChat({
                          chat_id: activeChatId,
                          project_id: projectId,
                          chat_contents: updatedContents,
                        })
                      );
                    }
                    break;

                  case "complete":
                    finalData = eventData.data;

                    // // Update chat one final time before handling other updates
                    if (activeChatId && currentMessageId) {
                      const currentContents =
                        getState().project.chat[activeChatId]?.chat_contents ||
                        [];
                      dispatch(
                        updateChat({
                          chat_id: activeChatId,
                          project_id: projectId,
                          chat_contents: currentContents,
                        })
                      );
                    }

                    if (finalData.final_project) {
                      dispatch(
                        updateProjectChatFiles({
                          projectId: finalData.final_project.project_id,
                          agent_chat_files:
                            finalData.final_project.agent_chat_files,
                          chat_chat_files:
                            finalData.final_project.chat_chat_files,
                          popup_chat_files:
                            finalData.final_project.popup_chat_files,
                        })
                      );
                    }

                    break;

                  case "error":
                    console.error("Error Event:", eventData);
                    throw new Error(
                      eventData.message || "An error occurred during streaming"
                    );
                }
              } catch (error) {
                throw error;
              }
            }
            boundary = buffer.indexOf("\n\n");
          }
        }

        if (buffer.length > 0) {
          throw new Error("Unprocessed data in buffer");
        }

        if (!finalData && chatType !== "popup") {
          throw new Error("Stream ended without complete data");
        }

        dispatch(updateMonthlyCreditsConsumed());

        return {
          projectId,
          response: finalData,
          lastStreamedUpdate,
        };
      } catch (error) {
        const errorMessage = `Oops! There was an error: ${error.message}. Could you please try again?`;

        if (chatType === "popup") {
          dispatch(updatePopUpMessages(errorMessage));
        } else {
          const currentState = getState().project;
          const updatedChatContents = [
            ...(currentState.chat[projectId]?.chat_contents || []),
            {
              sender: "ai",
              content: errorMessage,
              timestamp: new Date().toISOString(),
            },
          ];

          return {
            projectId,
            response: {
              chat_contents: updatedChatContents,
              chat_id: currentState.chat[projectId]?.chat_id || null,
              project_id: projectId,
            },
          };
        }
      } finally {
        dispatch(setIsChatLoading(false));
        dispatch(setStreamProgress(null));
      }
    } catch (error) {
      dispatch(setIsMessageSending(false));
      throw error;
    }
  }
);

export const sendChatMessageReducer = {
  [sendChatMessage.pending]: (state, action) => {
    state.isMessageSending = true;
    state.pendingChatType =
      action.meta.arg.chatType || state.activeChat || "agent";
  },
  [sendChatMessage.fulfilled]: (state, action) => {
    const { response } = action.payload;

    // Handle stopped case - just reset state
    if (!response) {
      state.isMessageSending = false;
      state.isLoading = false;
      state.pendingChatType = null;
      state.currentStreamedUpdate = null;
      state.currentPopupStreamedUpdate = null;
      state.currentStreamedToolCalls = null;
      state.streamProgress = null;
      return;
    }

    const chatId = response.chat_id;
    const chatType = state.pendingChatType;

    if (response.status === "error") {
      const errorMessage =
        response.message || "An error occurred during streaming";

      if (state.currentChatId && state.chat[state.currentChatId]) {
        const chatContents = state.chat[state.currentChatId].chat_contents;
        if (
          chatContents.length > 0 &&
          chatContents[chatContents.length - 1].role === "user"
        ) {
          chatContents.pop();
        }
      }

      state.sendMessageError = errorMessage;
      state.isMessageSending = false;
      state.pendingChatType = null; // Clear stored chat type
      return;
    }
    state.isMessageSending = false;
    state.pendingChatType = null; // Clear stored chat type

    if (chatType === "popup") {
      // Handle popup chat updates
      if (response.final_project?.popup_chat_files?.[chatId]) {
        state.popUpChatMessages =
          response.final_project.popup_chat_files[chatId].chat_contents || [];
        state.currentPopupStreamedUpdate = null;
      }
    } else {
      // Set the current chat ID from the response
      if (chatId) {
        state.currentChatId = chatId;
      }

      // Update chat contents from the project's chat files
      if (response.final_project) {
        const chatFileType =
          chatType === "agent" ? "agent_chat_files" : "chat_chat_files";
        const projectChatFiles = response.final_project[chatFileType];

        if (projectChatFiles && projectChatFiles[chatId]) {
          state.chat[chatId] = {
            chat_id: chatId,
            project_id: response.project_id,
            chat_contents: projectChatFiles[chatId].chat_contents || [],
          };
        }
      }

      // Clear streamed update after chat is updated
      state.currentStreamedUpdate = null;
    }

    // Handle project updates
    // if (response.updated_project) {
    //   const { files } = response.updated_project;
    //   state.projectList = state.projectList.map((p) => {
    //     if (p.project_id === response.final_project.project_id) {
    //       return { ...p, files };
    //     }
    //     return p;
    //   });
    //   state.currentProject = response.updated_project;
    // }

    // if (response.final_project) {
    //   state.projectList = state.projectList.map((project) =>
    //     project.project_id === response.final_project.project_id
    //       ? response.final_project
    //       : project
    //   );

    //   if (state.currentProjectId === response.final_project.project_id) {
    //     state.currentProject = response.final_project;
    //   }
    // }

    // if (response.final_design) {
    //   state.design = response.final_design;

    //   // Get the current file ID either from the response or current artifact
    //   const currentFileId =
    //     response.final_design.data.file_id || state.artifact?.file_id;

    //   // Preserve the file ID and merge the new data
    //   const newArtifact = {
    //     ...response.final_design.data,
    //     file_id: currentFileId,
    //     type: response.final_design.data.type || "sequence",
    //     content:
    //       response.final_design.data.content || response.final_design.data,
    //   };
    //   state.artifact = newArtifact;

    //   // Update the file in openFiles if it exists there
    //   if (currentFileId && state.openFiles[currentFileId]) {
    //     state.openFiles[currentFileId] = {
    //       ...state.openFiles[currentFileId],
    //       content: newArtifact,
    //     };
    //   } else {
    //     console.log("File not found in openFiles:", currentFileId);
    //   }

    //   // Update the file in the project's files if it exists
    //   if (currentFileId && state.currentProject?.files) {
    //     state.currentProject.files[currentFileId] = {
    //       ...state.currentProject.files[currentFileId],
    //       content: newArtifact,
    //     };
    //   } else {
    //     console.log("File not found in project files:", currentFileId);
    //   }
    // }

    // if (response.user_preferences) {
    //   state.userPreferences = response.user_preferences;
    // }

    // if (response.final_design) {
    //   state.editorNeedsRemount = true;
    // }
  },
  [sendChatMessage.rejected]: (state, action) => {
    state.isMessageSending = false;
    state.isLoading = false;
    state.pendingChatType = null; // Clear stored chat type

    // Make sure we capture and display the error message
    if (action.error) {
      state.sendMessageError =
        action.error.message ||
        "An unexpected error occurred while sending the message";

      // For popup chat errors, update the popup messages with the error
      if (action.meta?.arg?.chatType === "popup") {
        const errorMessage = `Oops! There was an error: ${state.sendMessageError}. Could you please try again?`;
        state.popUpChatMessages.push({
          role: "assistant",
          content: errorMessage,
          timestamp: new Date().toISOString(),
        });
      }
    } else {
      state.sendMessageError =
        "An unexpected error occurred while sending the message";
    }
  },
};

const validateMessage = (message) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = message;

  let cleanMessage = "";
  const walk = document.createTreeWalker(tempDiv, NodeFilter.SHOW_TEXT, {
    acceptNode: function (node) {
      if (
        node.parentElement &&
        node.parentElement.classList &&
        node.parentElement.classList.contains("file-tag")
      ) {
        return NodeFilter.FILTER_REJECT;
      }
      return NodeFilter.FILTER_ACCEPT;
    },
  });

  while (walk.nextNode()) {
    cleanMessage += walk.currentNode.textContent;
  }

  const dnaPattern = /(?:^|\s)([ATCGatcg]{5,})(?:\s|$)/g;
  const messageWithoutDNA = cleanMessage.replace(dnaPattern, " ");

  if (messageWithoutDNA.length > 1000000) {
    throw {
      type: "SendMessageError",
      message:
        "Message is too long. Please keep messages under 2000 characters. Your previous chat and this response will be deleted when you send your next chat",
    };
  }
};

export const sendMessage = async (
  message,
  chatId,
  projectId,
  currentProject,
  token,
  currentSequence,
  activeChat = "agent",
  pillFileIds = [],
  activeFileId = null,
  modelType = "L1",
  command_k_input = null
) => {
  validateMessage(message);

  const processedCommandKInput = command_k_input
    ? {
        ...command_k_input,
        type: command_k_input.type || "text",
      }
    : null;

  const containsNewFile = pillFileIds.some(
    (fileId) => !currentProject.files[fileId]
  );

  const response = await fetch(`${API_URL}/api/send-message`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "text/event-stream",
    },
    body: JSON.stringify({
      message,
      chat_id: chatId,
      project_id: projectId,
      current_sequence: currentSequence || "None",
      chat_type: activeChat,
      pill_file_ids: pillFileIds,
      active_file_id: activeFileId,
      model_type: modelType,
      command_k_input: processedCommandKInput,
    }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw {
      type: "SendMessageError",
      message: errorData.message || "Failed to send message",
      status: response.status,
    };
  }

  return response;
};
