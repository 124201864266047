import React, { useRef, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../style/ChatMessages.css";
import { components } from "./ChatMessages_helpers/components.js";
import { MessageHistory } from "./ChatMessages_helpers/MessageHistory.js";
import { StreamingResponse } from "./ChatMessages_helpers/StreamingResponse.js";
import { FaApple } from "react-icons/fa";
import { stopChatMessage } from "../store/project.js";

function ChatMessages({ externalMessages, chatType }) {
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [messages, setMessages] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const user_id = useSelector((state) => state.user.user_id);
  const dispatch = useDispatch();

  const {
    chat,
    currentProjectId,
    currentStreamedUpdate,
    currentProject,
    activeChat,
    currentChatId,
    currentStreamedToolCalls,
    streamProgress,
  } = useSelector((state) => state.project);

  // Add this selector
  const sendMessageError = useSelector(
    (state) => state.project.sendMessageError
  );

  // Update the initial state to track hidden tool calls instead of visible ones
  const [hiddenToolCalls, setHiddenToolCalls] = useState(new Set());

  const getLatestChatFile = () => {
    if (!currentProject?.[`${activeChat}_chat_files`]) return null;

    const chatFiles = Object.values(
      currentProject[`${activeChat}_chat_files`]
    ).sort((a, b) => {
      const dateA = new Date(b.last_opened_date);
      const dateB = new Date(a.last_opened_date);
      return dateA - dateB;
    });

    return chatFiles[0] || null;
  };

  const latestChat = getLatestChatFile();

  const isNearBottom = () => {
    const container = chatContainerRef.current;
    if (!container) return true;

    const threshold = 250; // pixels from bottom
    const position =
      container.scrollHeight - container.scrollTop - container.clientHeight;
    return position < threshold;
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  };

  useEffect(() => {
    if (isNearBottom()) {
      scrollToBottom();
    }
  }, [
    messages?.chat_contents,
    currentStreamedUpdate,
    currentStreamedToolCalls,
    streamProgress,
  ]);

  useEffect(() => {
    scrollToBottom();
  }, [messages?.chat_id, currentProjectId]);

  useEffect(() => {
    if (externalMessages) {
      setMessages({ chat_contents: externalMessages });
    } else if (currentChatId && chat[currentChatId]) {
      setMessages(chat[currentChatId]);
    } else if (latestChat && chat[latestChat.chat_id]) {
      setMessages(chat[latestChat.chat_id]);
    } else if (currentProjectId && chat[currentProjectId]) {
      setMessages(chat[currentProjectId]);
    } else {
      setMessages(null);
    }
  }, [
    chat,
    currentProjectId,
    latestChat,
    activeChat,
    currentChatId,
    externalMessages,
  ]);

  const handleStopMessage = useCallback(() => {
    if (streamProgress || currentStreamedUpdate) {
      dispatch(stopChatMessage({ projectId: currentProjectId }));
    }
  }, [dispatch, streamProgress, currentStreamedUpdate, currentProjectId]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check for Command + Delete (Mac) or Control + Delete (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === "Backspace") {
        e.preventDefault();
        handleStopMessage();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleStopMessage]);

  return (
    <div className="chat-messages-container">
      {currentProjectId && messages?.chat_contents?.length > 0 && (
        <div className="chat-messages-list" ref={chatContainerRef}>
          <ul className="chat-messages-list" aria-live="polite">
            {messages.chat_contents
              .filter((message) => {
                // Only show messages that have displayable content
                if (message.role === "tool") return false;
                if (message.role === "user") {
                  // Handle both string content and array of objects content format
                  if (Array.isArray(message.content)) {
                    return message.content.some(
                      (item) =>
                        item.type === "text" &&
                        item.text &&
                        item.text.trim() !== ""
                    );
                  }
                  return message.content && message.content.trim() !== "";
                }
                if (message.role === "assistant") {
                  // Handle both string content and array of objects content format
                  const hasContent = Array.isArray(message.content)
                    ? message.content.some(
                        (item) =>
                          item.type === "text" &&
                          item.text &&
                          item.text.trim() !== ""
                      )
                    : message.content && message.content.trim() !== "";

                  const hasToolCalls = message.tool_calls?.some(
                    (toolCall) =>
                      toolCall.function.name &&
                      !toolCall.function.name
                        .toLowerCase()
                        .includes("complete") &&
                      !toolCall.function.name
                        .toLowerCase()
                        .includes("handoff") &&
                      !toolCall.function.name.toLowerCase().includes("use") &&
                      !toolCall.function.name.toLowerCase().includes("begin") &&
                      !toolCall.function.name.toLowerCase().includes("finish")
                  );
                  return hasContent || hasToolCalls;
                }
                return false;
              })
              .map((message, idx, filteredMessages) => {
                const isFirstAssistantAfterUser =
                  message.role === "assistant" &&
                  (idx === 0
                    ? false
                    : filteredMessages[idx - 1].role === "user");

                return (
                  <li
                    key={idx}
                    className={`message ${
                      message.role === "user" ? "user-message" : "ai-message"
                    }`}
                    role="article"
                    aria-label={
                      message.role === "user" ? "Your message" : "AI response"
                    }
                  >
                    {message.role === "assistant" &&
                      isFirstAssistantAfterUser && (
                        <img
                          src="/images/K-mark.svg"
                          alt="LabKick AI Assistant"
                          className="ai-logo"
                        />
                      )}
                    <div className="message-content">
                      <MessageHistory
                        message={message}
                        messageIndex={idx}
                        hiddenToolCalls={hiddenToolCalls}
                        setHiddenToolCalls={setHiddenToolCalls}
                        messages={messages}
                      />
                    </div>
                  </li>
                );
              })}
            {sendMessageError && (
              <div className="error-message-container" role="alert">
                <div className="error-message-content">{sendMessageError}</div>
              </div>
            )}
            {streamProgress && !currentStreamedUpdate && (
              <div className="stream-progress" aria-label={streamProgress}>
                <div className="status-message">{streamProgress}</div>
              </div>
            )}
            {(streamProgress || currentStreamedUpdate) && (
              <div className="chat-messages-stop-button">
                <button
                  className="chat-messages-stop"
                  onClick={handleStopMessage}
                >
                  <span className="chat-messages-shortcut">⌘</span>
                  <img
                    src="/images/delete-right.svg"
                    alt="delete"
                    className="chat-messages-stop-icon"
                  />
                  Stop
                </button>
              </div>
            )}
            <div ref={messagesEndRef} style={{ height: 0 }} />
          </ul>
        </div>
      )}
    </div>
  );
}

export default ChatMessages;
